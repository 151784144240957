// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../../Colors'
import { RobotoExtraBold } from '../../Typography/Fonts'
import background from '../../icons/background.jpg'
import SellerAreaSvg from '../../icons/SellerAreaSvg'
import CusthomeSvg from '../../icons/CusthomeSvg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 360px;
  height: 484px;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 ${Colors.black50};
  overflow: auto;
  padding: 30px;
  z-index: 1;
  background-color: ${Colors.brown1};
`

const Title = styled.div`
  font-family: ${RobotoExtraBold.fontFamily};
  font-size: 30px;
  font-weight: ${RobotoExtraBold.fontWeight};
  line-height: 1;
  color: ${Colors.blue};
  text-transform: uppercase;
  margin-top: 4px;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  width: 100%;
  left: 0;
`

const Logo = styled.div`
  display: flex;
  position: absolute;
  bottom: 16px;
`

export default ({ children }: { children: React.Node }) => (
  <Wrapper>
    <Container>
      <IconContainer>
        <SellerAreaSvg />
        <Title>Manager</Title>
      </IconContainer>
      {children}
    </Container>
    <Logo>
      <CusthomeSvg />
    </Logo>
  </Wrapper>
)
