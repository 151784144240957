// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoMedium } from './../Typography/Fonts'

const Wrapper = styled.span`
  display: initial;
`

const TooltipContent = styled.div`
  position: fixed;
  box-sizing: content-box;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${Colors.black50};
  border: solid 1px ${Colors.black10};
  background-color: ${Colors.carbon};
  padding: 15px;
  height: min-content;
  width: max-content;
  z-index: 5;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  pointer-events: none;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.white30};
`

const SPACE = 2
const offsetWidth = -2

type TooltipProps = {|
  children: React.Node,
  content?: React.Node,
|}

type TooltipState = {
  isVisible: boolean,
  style: ?{
    top: number,
    left: number,
    bottom: number,
  },
}

export default class Tooltip extends React.PureComponent<
  TooltipProps,
  TooltipState,
> {
  _wrapperRef: any
  _contentRef: any

  constructor(props: TooltipProps) {
    super(props)

    this.state = {
      isVisible: false,
      style: null,
    }
  }

  showTooltip = () => {
    if (!this.props.content) {
      return
    }
    const wrapperDimensions = this._wrapperRef.getBoundingClientRect()
    const contentDimensions = this._contentRef.getBoundingClientRect()
    const style = {}
    style.left =
      wrapperDimensions.left +
      wrapperDimensions.width / 2 -
      contentDimensions.width / 2 -
      offsetWidth
    style.left = Math.max(SPACE, style.left)
    style.left = Math.min(
      style.left,
      document.body
        ? document.body.clientWidth - contentDimensions.width - SPACE
        : 0,
    )

    if (wrapperDimensions.top < window.innerHeight / 2) {
      style.top = wrapperDimensions.top + wrapperDimensions.height + SPACE
    } else {
      style.bottom = window.innerHeight - wrapperDimensions.top + SPACE
    }

    this.setState({
      isVisible: true,
      style,
    })
  }

  hideTooltip = () => {
    if (!this.props.content) {
      return
    }
    this.setState({ isVisible: false })
  }

  render() {
    const { isVisible, style } = this.state
    const { children, content } = this.props

    return (
      <Wrapper
        onMouseOver={this.showTooltip}
        onMouseOut={this.hideTooltip}
        ref={node => (this._wrapperRef = node)}
      >
        {children}

        {content && (
          <TooltipContent
            isVisible={isVisible}
            ref={node => (this._contentRef = node)}
            style={style}
          >
            {content}
          </TooltipContent>
        )}
      </Wrapper>
    )
  }
}
