// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#000"
      fillOpacity=".3"
      fillRule="nonzero"
      d="M2.834.486L8 5.652 13.166.486c.648-.648 1.7-.648 2.348 0 .648.649.648 1.7 0 2.348L10.348 8l5.166 5.166c.648.648.648 1.7 0 2.348-.649.648-1.7.648-2.348 0L8 10.348l-5.166 5.166c-.648.648-1.7.648-2.348 0-.648-.649-.648-1.7 0-2.348L5.652 8 .486 2.834c-.648-.648-.648-1.7 0-2.348.649-.648 1.7-.648 2.348 0z"
    />
  </svg>
)
