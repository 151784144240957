// @flow

import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../Colors'
import { RobotoBold, RobotoMedium } from '../Typography/Fonts'
import routes from '../../../Routes'
import KanbanSvg from '../icons/MenuKanbanSvg'
import PricesSvg from '../icons/MenuPricesSvg'
import ParkingsSvg from '../icons/MenuParkingsSvg'
import SettingsSvg from '../icons/MenuSettingsSvg'
import ProspectsSvg from '../icons/ProspectsSvg'
import GoToSvg from '../icons/GoToSvg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
`

const Title = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${Colors.white100};
  margin-bottom: 1px;
  display: flex;
  align-items: center;

  a {
    display: flex;
  }
  svg {
    margin-left: 4px;
  }
`

const SubTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  letter-spacing: normal;
  color: ${Colors.white30};
`

// const SalesAvancement = styled.div`
//   position: relative;
//   margin-left: 6px;
//   width: 32px;
//   height: 8px;
//   border-radius: 4px;
//   background-color: ${Colors.carbon};

//   &:after {
//     content: ' ';
//     position: absolute;
//     top: 2px;
//     left: 2px;
//     width: ${({ avancement }) => `${(avancement * 28) / 100}px`};
//     height: 4px;
//     border-radius: 3px;
//     background-color: ${Colors.green};
//   }
// `

const Icon = styled.div`
  cursor: ${({ isCurrent }) => (isCurrent ? 'default' : 'pointer')};
  pointer-events: ${({ isCurrent, disabled }) =>
    isCurrent || disabled ? 'none' : 'all'};
  position: relative;
  height: 40px;
  margin-left: 8px;
  path {
    fill-opacity: ${({ isCurrent, disabled }) =>
      disabled ? 0.1 : isCurrent ? 0.75 : 0.3};
    transition: fill 0.25s ease-in-out, fill-opacity 0.25s ease-in-out;
  }
  &:hover path {
    fill-opacity: 1;
    fill: ${Colors.blue};
  }
`

const LeftArea = styled.div``

const RightArea = styled.div`
  display: flex;
`

export const ProgramsHeader = ({
  programsActive,
  programsFinished,
  openProspects,
  newProspects,
}: {
  programsActive: number,
  programsFinished: number,
  openProspects: () => void,
  newProspects: boolean,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <LeftArea>
        <Title>{t('programs.program_plural')}</Title>
        <SubTitle>
          {programsActive} {t('programs.active')} | {programsFinished}{' '}
          {t('programs.finished')}
        </SubTitle>
      </LeftArea>
      <RightArea>
        <Icon onClick={() => openProspects()}>
          <ProspectsSvg newProspects={newProspects} />
        </Icon>
      </RightArea>
    </Wrapper>
  )
}

export const ProgramHeader = ({
  programLabel,
  programTotalNumberOfLots,
  programTotalNumberOfParkings,
  programSoldLot,
  goTo,
  currentPath,
  programDomain,
}: {|
  programLabel: string,
  programTotalNumberOfLots: number,
  programTotalNumberOfParkings: number,
  programSoldLot: number,
  goTo: (route: string) => Promise<void>,
  currentPath: string,
  programDomain: string,
|}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <LeftArea>
        <Title>
          {programLabel}
          {console.log(programDomain)}
          <a href={programDomain} target="_blank" rel="noreferrer">
            <GoToSvg />
          </a>
        </Title>
        <SubTitle>
          {programTotalNumberOfLots} {t('programs.estates')}
          {/*  | {programSoldLot}{' '}
          {t('programs.sold', { count: programSoldLot })}
          <SalesAvancement
            avancement={(programSoldLot * 100) / programTotalNumberOfLots}
  /> */}
        </SubTitle>
      </LeftArea>
      <RightArea>
        <Icon
          onClick={() => goTo(routes.lots_management_page)}
          isCurrent={currentPath === routes.lots_management_page.path}
        >
          <KanbanSvg />
        </Icon>
        <Icon
          onClick={() => goTo(routes.program_prices_page)}
          isCurrent={currentPath === routes.program_prices_page.path}
        >
          <PricesSvg />
        </Icon>
        <Icon
          disabled={programTotalNumberOfParkings === 0}
          onClick={() => goTo(routes.program_parkings_page)}
          isCurrent={currentPath === routes.program_parkings_page.path}
        >
          <ParkingsSvg />
        </Icon>
        <Icon
          disabled
          onClick={() => goTo(routes.program_settings_page)}
          isCurrent={currentPath === routes.program_settings_page.path}
        >
          <SettingsSvg />
        </Icon>
      </RightArea>
    </Wrapper>
  )
}
