// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <path
      fill="#d577ed"
      fillRule="evenodd"
      d="M9.5 19c5.245 0 9.5-4.255 9.5-9.5S14.745 0 9.5 0 0 4.255 0 9.5 4.255 19 9.5 19zm0-2.415c-3.84 0-7.085-3.245-7.085-7.085S5.66 2.415 9.5 2.415c3.86 0 7.085 3.245 7.085 7.085S13.36 16.585 9.5 16.585zm3.642-3.364c.435-.396.455-1.069.06-1.504l-2.455-2.415-.198-4.493c-.02-.534-.455-.99-1.01-1.009-.573-.02-1.068.416-1.088 1.01l-.218 5.026c-.02.317.12.654.376.891l3.127 2.494c.396.356 1.01.356 1.406 0z"
    />
  </svg>
)
