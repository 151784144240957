// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      fillRule="evenodd"
      d="M16 0c5.799 0 10.5 4.454 10.5 9.947 0 3.287-1.682 6.201-4.276 8.012C26.834 20.13 30 24.636 30 29.842c0 .916-.784 1.658-1.75 1.658s-1.75-.742-1.75-1.658c0-5.494-4.701-9.947-10.5-9.947S5.5 24.348 5.5 29.842c0 .916-.784 1.658-1.75 1.658S2 30.758 2 29.842c0-5.206 3.167-9.712 7.775-11.883C7.182 16.149 5.5 13.234 5.5 9.947 5.5 4.454 10.201 0 16 0zm0 3.316c-3.866 0-7 2.969-7 6.631 0 3.663 3.134 6.632 7 6.632s7-2.97 7-6.632c0-3.662-3.134-6.631-7-6.631z"
    />
  </svg>
)
