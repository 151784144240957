// @flow

import React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import ApplicationLayout from '../../ApplicationLayout'
import { ProgramsHeader } from '../Guidelines/components/PageHeader'
import Programs from '../Guidelines/components/Programs'
import routes from '../../Routes'
import DialogModal from '../Guidelines/components/DialogModal'
import ProgramsProspects from '../Guidelines/components/ProgramsProspects'
import ProgramsHistory from '../Guidelines/components/ProgramsHistory'

export default observer(() => {
  const { programsStore, router } = React.useContext(StoreContext)

  const [modalProspectIsOpen, setModalProspectIsOpen] = React.useState(false)
  const programsActive = programsStore.getProgramsByStatus('active')
  const programsFinished = programsStore.getProgramsByStatus('finished')
  const programHistory = programsStore.history
  const newProspects = programsStore.prospects.some(
    oneProgramProspect => oneProgramProspect.delta > 0,
  )

  const requestDownloadXls = async (apiUrl: string, programName: string) => {
    const data = await programsStore.getProspectXls(apiUrl, programName)

    const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
    const url = window.URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.style.display = 'none'

    document.body && document.body.appendChild(a)
    a.setAttribute('href', url)
    a.setAttribute('download', `${programName}-customers.xls`)
    a.click()

    document.body && document.body.removeChild(a)
  }

  return (
    <ApplicationLayout>
      <ProgramsHeader
        programsActive={programsActive.length}
        programsFinished={programsFinished.length}
        openProspects={() => setModalProspectIsOpen(true)}
        newProspects={newProspects}
      />

      <Programs
        programsActive={programsActive}
        programsFinished={programsFinished}
        programRoute={routes.lots_management_page}
        router={{
          goTo: (view, params, queryParams) =>
            router.goTo(view, params, queryParams),
        }}
        openProgramHistory={(programId, programName) =>
          programsStore.fetchHistory(programId, programName)
        }
      />

      {programHistory && (
        <DialogModal onClose={() => programsStore.closeHistory()}>
          <ProgramsHistory
            history={programHistory}
            markAsContacted={contactRequestId =>
              programsStore.contactRequest(
                programHistory.programId,
                contactRequestId,
              )
            }
          />
        </DialogModal>
      )}

      {modalProspectIsOpen && (
        <DialogModal onClose={() => setModalProspectIsOpen(false)}>
          <ProgramsProspects
            requestDownloadXls={requestDownloadXls}
            programsProspects={programsStore.prospects}
          />
        </DialogModal>
      )}
    </ApplicationLayout>
  )
})
