// @flow

import * as React from 'react'

export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    className={className}
  >
    <path
      fill="none"
      stroke="#FFF"
      strokeOpacity=".1"
      d="M3.857.766c.587.447.865 1.332 1.07 1.93 1.088.36 1.8.39 2.269.473-.182.694-.71 1.198-1.311 1.707.011 1.208.14 1.853.205 2.366-.879-.26-1.318-.475-1.824-.792-1.035.316-1.472.53-1.843.713-.44-.547-.304-1.14-.145-1.787C1.514 4.366 1 3.854.693 3.444c.523-.39 1.283-.388 1.926-.411.66-.938.953-1.777 1.238-2.267z"
    />
  </svg>
)
