// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M16 0c8.837 0 16 7.163 16 16 0 3.273-.983 6.316-2.669 8.851l.242-.374c-.102.162-.206.321-.312.48-.159.233-.322.462-.492.686l-.035.045c-.085.112-.17.222-.258.33l-.068.084C25.475 29.702 21.006 32 16 32c-5.01 0-9.481-2.302-12.415-5.906l.27.323c-.112-.131-.222-.264-.33-.398-.088-.11-.174-.22-.259-.33l-.037-.05c-.084-.11-.167-.223-.248-.336l-.075-.105c-.151-.216-.298-.436-.44-.66l-.038-.061C.889 22.019 0 19.113 0 16 0 7.163 7.163 0 16 0zm0 22.4c-3.942 0-7.507.995-10.192 2.605C8.3 27.823 11.943 29.6 16 29.6s7.7-1.777 10.192-4.595C23.507 23.395 19.942 22.4 16 22.4zm0-20C8.489 2.4 2.4 8.489 2.4 16c0 2.588.723 5.007 1.978 7.067C7.488 21.149 11.566 20 16 20s8.512 1.148 11.623 3.067C28.877 21.007 29.6 18.588 29.6 16c0-7.511-6.089-13.6-13.6-13.6zm0 4.178c3.387 0 6.133 2.746 6.133 6.133 0 3.388-2.746 6.133-6.133 6.133S9.867 16.1 9.867 12.711c0-3.387 2.746-6.133 6.133-6.133zm0 2.4c-2.062 0-3.733 1.671-3.733 3.733 0 2.062 1.671 3.733 3.733 3.733 2.062 0 3.733-1.671 3.733-3.733 0-2.062-1.671-3.733-3.733-3.733z"
    />
  </svg>
)
