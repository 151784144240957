// @flow

import * as React from 'react'
import styled from 'styled-components'
import CustomDragLayer from './CustomDragLayer'

export const KanbanLayout = styled.div`
  height: calc(100% - 82px);
  margin-right: -24px;
  margin-left: -24px;
`

const KanbanColumnsContainer = styled.div`
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding-bottom: 16px;
`

const KanbanColumnsLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  width: 1256px;
  height: 100%;
  align-content: flex-start;
  justify-content: space-between;
`

export const DragDropKanban = ({ children }: { children: React.Node }) => (
  <KanbanColumnsContainer>
    <KanbanColumnsLayout>
      {children}
      <CustomDragLayer />
    </KanbanColumnsLayout>
  </KanbanColumnsContainer>
)
