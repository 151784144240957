// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="rgba(255,255,255,0.5)"
      d="M13 0c1.657 0 3 1.343 3 3v10c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h10zm0 1H3c-1.054 0-1.918.816-1.995 1.85L1 3v10c0 1.054.816 1.918 1.85 1.995L3 15h10c1.054 0 1.918-.816 1.995-1.85L15 13V3c0-1.054-.816-1.918-1.85-1.995L13 1zM8.482 4c1.679 0 2.76 1.014 2.76 2.704 0 1.69-1.059 2.693-2.76 2.693H6.837v2.141c0 .35-.124.462-.462.462h-.913C5.124 12 5 11.887 5 11.538V4.462c0-.35.124-.462.462-.462zM8.29 5.668H6.837v2.05H8.29c.654 0 1.048-.372 1.048-1.014S8.955 5.668 8.29 5.668z"
    />
  </svg>
)
