// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill="#0CF"
      d="M6.766 12c.122 0 .244-.048.332-.132 2.51-1.731 4.69-4.388 4.778-4.508.165-.204.165-.517 0-.733-.089-.109-2.3-2.813-4.833-4.544-.166-.108-.365-.108-.531-.012-.166.096-.265.288-.265.493v2.223c-1.615.265-3.252.65-3.506.806-.442.264-.741.71-.741 1.406 0 .71.299 1.154.74 1.419.255.144 1.892.54 3.507.805v2.212c0 .313.232.565.52.565z"
    />
  </svg>
)
