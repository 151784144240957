// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * The node ICU data are different on macOs and on Bitbucket Pipelines.
 * We normalize the macOs one to match the basic HTML non-breaking space char (@nbsp;)
 */
const normalizeNonBreakingSpace = str => str.replace(' ', ' ')

const Taxes = ({
  amount,
  withoutLabel,
}: {|
  amount: number,
  withoutLabel?: boolean,
|}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {withoutLabel ? '' : `${t('vat')} `}
      {normalizeNonBreakingSpace(
        (amount / 100).toLocaleString(t('localFormat', 'en-GB'), {
          style: 'percent',
          useGrouping: true,
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        }),
      ).replace(' ', '')}
    </React.Fragment>
  )
}

export default Taxes
