// @flow

import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from './StoreContext'
import InfiniteLoader from './Ui/Guidelines/components/InfiniteLoader'

export default observer(() => {
  const { router } = React.useContext(StoreContext)

  const { currentRoute } = router
  const currentRouteComponent =
    currentRoute && currentRoute.component ? currentRoute.component : null

  if (!currentRouteComponent) {
    return <InfiniteLoader />
  }

  return false
})
