// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M21.213 0c.945 0 1.852.373 2.522 1.037l11.215 11.13c.672.666 1.05 1.572 1.05 2.518v19.704C36 37.49 33.471 40 30.357 40H9.643C6.529 40 4 37.49 4 34.389V5.61C4 2.51 6.529 0 9.643 0zm0 3H9.643C8.18 3 7 4.172 7 5.611V34.39C7 35.829 8.18 37 9.643 37h20.714C31.82 37 33 35.828 33 34.389V14.685c0-.145-.058-.285-.163-.389L21.62 3.166C21.514 3.06 21.367 3 21.213 3zM20 13c.814 0 1.482.62 1.558 1.414l.007.151v9.091l3.763-3.763c.611-.611 1.602-.611 2.214 0 .573.573.608 1.48.107 2.095l-.107.119-6.435 6.435-.069.064-.126.1-.106.071-.085.048-.14.064c-.18.071-.376.111-.581.111-.153 0-.3-.022-.44-.063l-.182-.065-.138-.068-.114-.07-.118-.089-.115-.103-6.435-6.435c-.61-.611-.61-1.603 0-2.214.573-.573 1.48-.609 2.095-.107l.119.107 3.762 3.762v-9.09c0-.864.702-1.565 1.566-1.565z"
    />
  </svg>
)
