// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <path
      fill="#0CF"
      fillRule="evenodd"
      d="M24.936 42.598c1.706-2.353 22.571-31.96 22.571-31.96.716-1.04.66-2.409-.22-3.12-1.927-1.697-4.019-3.065-6.221-4.27-.991-.546-2.312-.163-3.028.877L20.367 29.08l-10.35-14.449c-.716-1.04-2.037-1.423-3.028-.93-2.257 1.259-4.349 2.682-6.276 4.323-.88.767-.936 2.135-.22 3.175 0 0 13.653 19.1 15.304 21.398 2.423 3.284 6.827 3.12 9.139 0z"
    />
  </svg>
)
