// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M16 0c8.745 0 15.85 7.015 15.998 15.724L32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0zm-3.716 17.2l.01.33.03.653c.011.216.024.43.039.643l.048.631.057.62.066.607.074.594.04.291.085.573.093.557.05.273.102.533.054.26.112.508.058.247.12.48.127.46c.042.15.086.298.13.44l.135.42c.068.205.138.401.21.59l.144.364c.582 1.41 1.238 2.257 1.855 2.322L16 29.6c.63 0 1.304-.831 1.904-2.259l.143-.354.139-.376.135-.397.132-.419.127-.438.062-.226.12-.467.116-.485.055-.25.106-.51.1-.528c.017-.09.033-.179.048-.27l.09-.55.084-.565.076-.58.035-.294.064-.599.029-.304.051-.616.023-.312.038-.633.015-.32.024-.647h-7.432zm17.264 0h-7.43c-.122 4.918-.998 9.218-2.439 11.897 5.353-1.501 9.37-6.195 9.869-11.897zm-19.666 0h-7.43c.499 5.702 4.516 10.396 9.868 11.896-1.44-2.678-2.316-6.978-2.438-11.895zM12.32 2.904l-.055.016C6.94 4.436 2.949 9.118 2.452 14.801h7.43c.122-4.918.998-9.218 2.439-11.898zM16 2.4c-.58 0-1.197.704-1.759 1.928l-.145.33-.143.355-.139.376-.068.196-.134.408c-.044.14-.087.282-.13.428l-.124.449-.061.23-.118.477c-.038.162-.076.326-.112.494l-.106.51-.1.528-.048.27-.09.55-.084.566-.039.288-.072.586-.064.598-.03.304-.05.617-.023.312-.038.633-.015.32-.024.647h7.432l-.024-.647c-.004-.107-.01-.214-.015-.32l-.038-.633-.022-.312-.052-.617-.029-.304-.064-.598-.072-.586-.08-.572c-.013-.095-.028-.188-.042-.281l-.09-.551-.048-.27-.1-.527-.107-.511c-.036-.168-.074-.332-.112-.494l-.118-.476-.06-.231-.126-.449c-.042-.146-.085-.288-.13-.428l-.133-.408-.068-.196-.14-.376-.142-.354-.145-.331C17.197 3.104 16.579 2.4 16 2.4zm3.68.504l.04.074c1.416 2.685 2.277 6.95 2.398 11.822h7.43C29.05 9.099 25.032 4.406 19.68 2.905z"
    />
  </svg>
)
