// @flow

import * as React from 'react'

export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={className}
  >
    <path
      fill="none"
      stroke="#FFF"
      strokeOpacity=".1"
      d="M6.849.567c-.623.636-1.357 2.664-1.825 4.028l-.111.325-.344.012c-1.516.055-3.275.147-3.944.44l-.013.005-.008.003c-.045.017-.07.06-.084.1l-.004.01-.004.011c-.019.049-.018.106.01.152l.001.003.006.01c.433.797 1.547 1.979 2.931 3.152l.237.2-.075.3c-.385 1.572-.644 2.97-.716 3.956-.005.1.217.118.416.135.227-.124.52-.265 1.081-.537.662-.326 1.437-.707 2.332-1.268.09-.056.179-.113.265-.169l.266.17c.897.561 1.674.943 2.726 1.462.215.102.437.202 1.09.287.01-.028.013-.057.012-.088-.071-.977-.33-2.376-.716-3.947-.024-.101-.05-.202-.075-.301.08-.066.158-.132.237-.2 1.384-1.173 2.498-2.355 2.932-3.152l.004-.008.003-.005c.027-.046.028-.103.01-.152l-.005-.01-.003-.011c-.015-.045-.053-.086-.097-.105-.677-.296-2.437-.388-3.953-.443l-.343-.012-.111-.325c-.47-1.37-1.207-3.41-1.845-4.033-.039-.04-.084-.062-.283.005z"
    />
  </svg>
)
