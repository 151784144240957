// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M4.235 2.356c-.328.39-.307.958.03 1.322l.091.087 5.099 4.291-4.873 4.185c-.387.332-.458.896-.183 1.31l.075.1c.332.387.896.458 1.31.184l.1-.076 5.767-4.95c.437-.375.463-1.032.081-1.44l-.088-.085-6-5.05c-.423-.355-1.053-.3-1.41.122z"
    />
  </svg>
)
