// @flow

import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../Colors'
import SeeSvg from '../../icons/SeeSvg'
import HideSvg from '../../icons/HideSvg'

const See = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  transition: opacity 0.15s ease-in-out, fill 0.15s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};

  path {
    transition: fill 0.15s ease-in-out;
    fill: ${({ lotIsUnvailable, lotIsSold }) =>
      lotIsUnvailable
        ? Colors.white20
        : lotIsSold
        ? Colors.green75
        : Colors.white50};
  }
`

const Hide = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  transition: opacity 0.15s ease-in-out, fill 0.15s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};

  path {
    transition: fill 0.15s ease-in-out;
    fill: ${({ lotIsSold }) => (lotIsSold ? Colors.green75 : Colors.white10)};
  }
`

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export default ({
  onClick,
  value,
  lotIsUnvailable,
  lotIsSold,
}: {
  onClick: (value: boolean) => void,
  value: boolean,
  lotIsUnvailable: boolean,
  lotIsSold: boolean,
}) => (
  <Wrapper disabled={lotIsSold} onClick={() => onClick(value)}>
    <See
      isActive={value}
      lotIsSold={lotIsSold}
      lotIsUnvailable={lotIsUnvailable}
      className="trHoverPathWhite50"
    >
      <SeeSvg />
    </See>
    <Hide isActive={!value} lotIsSold={lotIsSold}>
      <HideSvg />
    </Hide>
  </Wrapper>
)
