// @flow

import styled, { css } from 'styled-components'
import { Colors } from '../Colors'
import { RobotoExtraBold } from '../Typography/Fonts'

export default styled.div`
  width: 72px;
  height: 100%;
  padding: 16px;
  display: flex;
  position: fixed;
  z-index: 5;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 1px 0 0 0 ${Colors.carbon};
  background-color: ${Colors.brown2};
`

export const Icon = styled.div`
  cursor: pointer;
  position: relative;
  height: 40px;
  margin-bottom: 8px;
  path {
    fill-opacity: ${({ isCurrent }) => (isCurrent ? 0.75 : 0.3)};
    transition: fill 0.25s ease-in-out, fill-opacity 0.25s ease-in-out;
  }
  &:hover path {
    fill-opacity: 1;
    fill: ${Colors.blue};
  }

  ${({ badges }) =>
    badges &&
    css`
      &:after {
        content: '${badges}';
        font-family: ${RobotoExtraBold.fontFamily};
        font-size: 12px;
        font-weight: ${RobotoExtraBold.fontWeight};
        line-height: 18px;
        padding: 0 8px;
        color: ${Colors.brown1};
        position: absolute;
        top: -6px;
        right: -6px;
        background-color: ${Colors.blue};
        border-radius: 11px;
        border: solid 2px ${Colors.brown2};
      }
    `}
`

export const NavigationTop = styled.div``

export const NavigationBottom = styled.div``

export const UserInitial = styled.div`
  cursor: pointer;
  font-family: ${RobotoExtraBold.fontFamily};
  font-size: 18px;
  font-weight: ${RobotoExtraBold.fontWeight};
  line-height: 40px;
  text-align: center;
  color: ${({ isCurrent }) => (isCurrent ? Colors.white75 : Colors.white30)};
  transition: color 0.25s ease-in-out;
  text-transform: uppercase;

  &:hover {
    color: ${Colors.blue};
  }
`
