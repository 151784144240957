// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoBold } from '../Typography/Fonts'
import TrashSvg from '../icons/TrashSvg'

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  flex-wrap: wrap;
`

export const FiltersLabel = styled.div`
  display: flex;
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  letter-spacing: normal;
  color: ${Colors.white50};
  span {
    color: ${Colors.white100};
    margin-left: 4px;
    margin-right: 6px;
  }
`

const ResetWrapper = styled.div`
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background-color: ${Colors.carbon};
  padding: 4px;
  margin-left: 2px;

  path {
    transition: fill 0.25s ease-in-out, fill-opacity 0.25s ease-in-out;
  }
  &:hover path {
    fill: ${Colors.blue};
    fill-opacity: 1;
  }
`

export const FiltersReset = ({
  onResetFilters,
}: {
  onResetFilters: () => void,
}) => (
  <ResetWrapper onClick={() => onResetFilters()}>
    <TrashSvg />
  </ResetWrapper>
)
