// @flow

import Floor from './Floor'
import { type LotObject } from './LotPrices'
import { type ParkingObject } from './Parking'

export default class Building {
  +id: string;
  +label: string;
  +floors: Floor[]

  constructor(id: string, label: string, floors: Floor[]) {
    this.id = id
    this.label = label
    this.floors = floors

    Object.freeze(this)
  }

  updateFloorsLot(lot: LotObject) {
    this.floors.forEach(oneFloor => {
      oneFloor.updateLots(lot)
    })
  }

  updateFloorsParking(parking: ParkingObject) {
    this.floors.forEach(oneFloor => {
      oneFloor.updateParkings(parking)
    })
  }

  updateFloorsParkingLotAssociation({
    lotId,
    parkingId,
    label,
    status,
  }: {
    lotId: string,
    parkingId: string,
    label?: string,
    status?: string,
  }) {
    this.floors.forEach(oneFloor =>
      oneFloor.updateParkingsLotAssociation({
        lotId,
        parkingId,
        label,
        status,
      }),
    )
  }

  is(lookedId: string): boolean {
    return this.id === lookedId
  }
}
