// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M3.094 4.36c.22-.22.463-.401.722-.544 1.443-.796 3.295-.373 4.223 1.035L6.916 5.973C6.594 5.24 5.794 4.821 4.998 5c-.3.067-.584.216-.816.449L2.03 7.6c-.654.653-.654 1.716 0 2.37.653.653 1.716.653 2.37 0l.663-.664c.604.24 1.251.335 1.89.288l-1.465 1.464c-1.255 1.256-3.29 1.256-4.546 0-1.256-1.255-1.256-3.29 0-4.546L3.094 4.36zM6.512.94L5.047 2.406c.639-.048 1.287.048 1.89.287L7.6 2.03c.654-.654 1.717-.654 2.37 0 .654.653.654 1.717 0 2.37L7.818 6.553c-.656.655-1.72.65-2.37 0-.152-.152-.283-.34-.364-.526L3.961 7.15c.118.179.24.333.398.49.406.407.923.71 1.518.853.771.185 1.597.083 2.307-.309.259-.143.502-.324.722-.543l2.152-2.153c1.256-1.255 1.256-3.291 0-4.547-1.255-1.255-3.29-1.255-4.546 0z"
    />
  </svg>
)
