// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FFF"
      d="M18.788 5.212c.235.234.235.614 0 .848l-2.161 2.16.002.002-8.24 8.239-.001-.001-2.328 2.328c-.234.235-.614.235-.848 0-.235-.234-.235-.614 0-.848l2.161-2.16 8.24-8.24 2.327-2.328c.234-.235.614-.235.848 0zM17.896 9.5c.575.735 1.022 1.58 1.304 2.5-.962 3.13-3.821 5.4-7.2 5.4-.616 0-1.214-.075-1.787-.218L17.896 9.5zM12 6.6c.616 0 1.215.075 1.788.218L6.104 14.5C5.53 13.765 5.083 12.92 4.8 12c.961-3.13 3.821-5.4 7.2-5.4z"
    />
  </svg>
)
