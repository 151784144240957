// @flow

import * as React from 'react'
import { DragSource, ConnectDragSource } from 'react-dnd'
import Card from './Card'
import ItemTypes from './ItemTypes'

const cardSource = {
  beginDrag(props, monitor, component) {
    return {
      lotId: props.lotId,
      status: props.status,
      toColumn: props.toColumn,
      label: props.label,
      pricesIncludingVAT: props.pricesIncludingVAT,
      priceDetails: props.priceDetails,
      typology: props.typology,
      area: props.area,
      balconyArea: props.balconyArea,
      gardenArea: props.gardenArea,
      orientation: props.orientation,
      floorLabel: props.floorLabel,
      buildingLabel: props.buildingLabel,
      displayedVat: component.state.currentVat,
      lotStatus: props.lotStatus,
      commercialOffer: props.commercialOffer,
      duplex: props.duplex,
      parkings: props.parkings,
    }
  },
}

const collect = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
  }
}

type Props = {|
  connectDragSource: typeof ConnectDragSource,
  lotId: string,
  status: string,
  toColumn: string[],
  label: string,
  typology: string,
  area: number,
  balconyArea: ?number,
  gardenArea: ?number,
  orientation: string,
  floorLabel: string,
  buildingLabel: string,
  onClickCard: (lotId: string, lotStatus: string) => void,
  pricesIncludingVAT: {
    amountIncludingVAT: number,
    vatValueInPercent: number,
  }[],
  displayedVat: number,
  commercialOffer: ?{
    firstName: ?string,
    lastName: ?string,
    funding: string,
    duration: ?number,
    price: number,
    priceDetails: ?({ label: string, amount: number }[]),
    vat: number,
  },
  duplex: { floor: number, area: number }[],
  parkings: { parkingId: string, label: string }[],
|}

type State = {|
  currentVat: number,
|}

class DraggableCard extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      currentVat:
        this.props.displayedVat ||
        this.props.pricesIncludingVAT[0].vatValueInPercent,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.displayedVat !== this.props.displayedVat) {
      this.props.displayedVat && this.setCurrentVat(this.props.displayedVat)
    }
  }

  setCurrentVat = (displayedVat: number) => {
    this.setState({ currentVat: displayedVat })
  }

  switchVat = () => {
    const { pricesIncludingVAT } = this.props
    const { currentVat } = this.state

    const index = this.props.pricesIncludingVAT.findIndex(
      price => price.vatValueInPercent === currentVat,
    )
    if (index + 1 < pricesIncludingVAT.length) {
      this.setCurrentVat(pricesIncludingVAT[index + 1].vatValueInPercent)
    } else {
      this.setCurrentVat(pricesIncludingVAT[0].vatValueInPercent)
    }
  }

  render() {
    const {
      connectDragSource,
      lotId,
      status,
      toColumn,
      label,
      typology,
      area,
      balconyArea,
      gardenArea,
      orientation,
      floorLabel,
      buildingLabel,
      onClickCard,
      pricesIncludingVAT,
      commercialOffer,
      duplex,
      parkings,
    } = this.props

    const { currentVat } = this.state
    const cursor = toColumn.length > 0 ? 'move' : 'auto'

    return connectDragSource(
      <div
        onClick={() => onClickCard(lotId, status)}
        style={{
          cursor,
        }}
        data-test-id={`draggable-card-lot-${lotId}`}
      >
        <Card
          lotId={lotId}
          status={status}
          label={label}
          pricesIncludingVAT={pricesIncludingVAT}
          typology={typology}
          area={area}
          balconyArea={balconyArea}
          gardenArea={gardenArea}
          orientation={orientation}
          floorLabel={floorLabel}
          buildingLabel={buildingLabel}
          displayedVat={currentVat}
          commercialOffer={commercialOffer}
          duplex={duplex}
          parkings={parkings}
          switchVat={this.switchVat}
        />
      </div>,
    )
  }
}

export default DragSource(
  ItemTypes.LOT_CARD,
  cardSource,
  collect,
)(DraggableCard)
