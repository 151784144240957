// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SearchField from '../Form/SearchField'
import ArrowRightSvg from '../../icons/ArrowRightSvg'
import { RightAction, StepHeader } from '../Helper'

const Suggestion = styled.div`
  div {
    height: 16px;
  }
`

type Customer = {
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: ?string,
  zipCode: ?string,
  street: ?string,
  city: ?string,
}

export default ({
  title,
  stepValue,
  color,
  onCustomerSelected,
  customers,
  onNewCustomer,
  onSkipCustomer,
}: {|
  title: string,
  stepValue: string,
  color: string,
  onCustomerSelected: (customerId: string) => void,
  customers: Customer[],
  onNewCustomer: () => void,
  onSkipCustomer?: () => void,
|}) => {
  const { t } = useTranslation()

  const [actionIsVisible, setActionVisibility] = React.useState(true)

  return (
    <React.Fragment>
      <StepHeader
        title={title}
        subLabel={t('process.step')}
        subValue={stepValue}
      />
      <SearchField
        valueId="customerId"
        color={color}
        values={customers}
        addNew={onNewCustomer}
        searchableFields={['firstName', 'lastName', 'email']}
        placeholder={t('process.search')}
        onSuggestionSelected={suggestion =>
          onCustomerSelected(suggestion.customerId)
        }
        renderSuggestion={suggestion => (
          <Suggestion>
            <div>
              {suggestion.firstName} {suggestion.lastName}
            </div>
            <div style={{ marginTop: '4px' }}>{suggestion.email}</div>
          </Suggestion>
        )}
        setActionVisibility={setActionVisibility}
      />
      {onSkipCustomer && (
        <RightAction
          color={color}
          isHidden={!actionIsVisible}
          onClick={onSkipCustomer}
        >
          {t('process.skip')} <ArrowRightSvg />
        </RightAction>
      )}
    </React.Fragment>
  )
}
