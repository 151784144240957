// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { VerticalDashedSeparator } from '../../DashedSeparator'
import { Colors } from '../../Colors'
import { emailMatch, getErrorByField } from '../Helper'
import { TextInputField } from '../Form/InputField'
import { RobotoBold } from '../../Typography/Fonts'
import ProfileSvg from '../../icons/ProfileSvg'
import PasswordSvg from '../../icons/PasswordSvg'
import { ButtonBlue } from '../Button'

const Wrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`

const Section = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    margin-bottom: 8px;
  }
`

const Separator = styled(VerticalDashedSeparator)`
  height: 252px;
`

const Title = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${Colors.white50};
  margin-bottom: 16px;
`

const SaveContainer = styled.div`
  position: absolute;
  bottom: 40px;
`

type Props = {|
  firstName: string,
  lastName: string,
  email: string,
  onProfileEdition: (
    user: ?{
      firstName: string,
      lastName: string,
      email: string,
    },
    password: ?{ oldPassword: string, newPassword: string },
  ) => Promise<{
    success: boolean,
    errors: { field: string, message: string }[],
  }>,
|}

const UserEdition = ({
  firstName,
  lastName,
  email,
  onProfileEdition,
}: Props) => {
  const { t } = useTranslation()

  const [emailValue, setEmail] = React.useState(email)
  const [firstNameValue, setFirstName] = React.useState(firstName)
  const [lastNameValue, setLastName] = React.useState(lastName)
  const [emailError, setEmailError] = React.useState(null)
  const [currentPasswordValue, setCurrentPassword] = React.useState('')
  const [newPasswordValue, setNewPassword] = React.useState('')
  const [confirmNewPasswordValue, setConfirmNewPassword] = React.useState('')
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false)
  const [errorsArray, setErrorsArray] = React.useState(null)

  const emailIsValide = (email: string) => {
    if (emailMatch(email)) {
      setEmailError(null)
    } else {
      setEmailError({ field: 'email', message: t('login.emailInvalid') })
    }
  }

  const modifiedAndNotEmpty =
    (emailValue !== email ||
      firstNameValue !== firstName ||
      lastNameValue !== lastName) &&
    emailValue !== '' &&
    firstNameValue !== '' &&
    lastNameValue !== ''

  const passwordChanged =
    currentPasswordValue !== '' &&
    newPasswordValue !== '' &&
    confirmNewPasswordValue !== ''

  const onSave = async () => {
    if (
      currentPasswordValue !== '' &&
      newPasswordValue !== confirmNewPasswordValue
    ) {
      setConfirmPasswordError(true)
      return
    } else {
      setConfirmPasswordError(false)
    }
    const { success, errors } = await onProfileEdition(
      modifiedAndNotEmpty
        ? {
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
          }
        : null,
      passwordChanged
        ? {
            oldPassword: currentPasswordValue,
            newPassword: newPasswordValue,
          }
        : null,
    )

    if (!success) {
      setErrorsArray(errors)
    } else {
      setErrorsArray(null)
      setCurrentPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
      setConfirmPasswordError(false)
    }
  }

  const disabled =
    (!modifiedAndNotEmpty && !passwordChanged) ||
    emailError ||
    confirmPasswordError

  const emailErrorBack = errorsArray && getErrorByField(errorsArray, 'email')
  const oldPasswordErrorBack =
    errorsArray && getErrorByField(errorsArray, 'oldPassword')
  const newPasswordErrorBack =
    errorsArray && getErrorByField(errorsArray, 'newPassword')

  return (
    <Wrapper>
      <Section>
        <ProfileSvg />
        <Title>{t('login.account')}</Title>
        <TextInputField
          style={{ width: '260px' }}
          label={t('login.email')}
          value={emailValue}
          onChange={e => {
            setEmail(e.target.value)
          }}
          onBlur={e => emailIsValide(e.target.value)}
          errorMessage={
            (emailError && emailError.message) ||
            (emailErrorBack && emailErrorBack.message)
          }
          autoComplete="email"
        />
        <TextInputField
          style={{ width: '260px' }}
          label={t('login.firstName')}
          value={firstNameValue}
          onChange={e => setFirstName(e.target.value)}
        />

        <TextInputField
          style={{ width: '260px' }}
          label={t('login.lastName')}
          value={lastNameValue}
          onChange={e => setLastName(e.target.value)}
        />
      </Section>
      <Separator />
      <Section>
        <PasswordSvg />
        <Title>{t('login.password')}</Title>

        <TextInputField
          style={{ width: '260px' }}
          label={t('login.currentPassword')}
          type="password"
          value={currentPasswordValue}
          onChange={e => setCurrentPassword(e.target.value)}
          errorMessage={oldPasswordErrorBack && oldPasswordErrorBack.message}
          autoComplete="new-password"
        />
        <TextInputField
          style={{ width: '260px' }}
          label={t('login.newPassword')}
          type="password"
          value={newPasswordValue}
          onChange={e => setNewPassword(e.target.value)}
          errorMessage={newPasswordErrorBack && newPasswordErrorBack.message}
          autoComplete="new-password"
        />
        <TextInputField
          style={{ width: '260px' }}
          label={t('login.newPasswordConfirmation')}
          type="password"
          value={confirmNewPasswordValue}
          onChange={e => setConfirmNewPassword(e.target.value)}
          onBlur={e =>
            setConfirmPasswordError(e.target.value !== newPasswordValue)
          }
          errorMessage={confirmPasswordError ? t('login.passwordError') : null}
          autoComplete="new-password"
        />
      </Section>

      <SaveContainer>
        <ButtonBlue
          disabled={disabled}
          onClick={e => {
            e.preventDefault()
            onSave()
          }}
        >
          {t('login.save')}
        </ButtonBlue>
      </SaveContainer>
    </Wrapper>
  )
}

export default React.memo<Props>(UserEdition)
