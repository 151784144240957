// @flow
import * as React from 'react'
import styled from 'styled-components'
import { nanoid } from 'nanoid'
import { Input } from './index'
import { Colors } from '../../Colors'
import { RobotoMedium } from '../../Typography/Fonts'

const LabelWrapper = styled.div`
  position: relative;
  background: transparent;

  & ${Input} {
    margin-top: 28px;
    margin-bottom: 12px;
  }

  & input::placeholder {
    color: ${({ linkedInputIsFocused, linkedInputIsEmpty }) =>
      !linkedInputIsFocused || (linkedInputIsFocused && !linkedInputIsEmpty)
        ? 'transparent'
        : Colors.white30};

    transition: color ease-in-out 0.15s;
  }
`

const LabelInput = styled.label`
  position: absolute;
  top: ${({ floating }) => (floating ? '13px' : '28px')};
  left: 0;
  padding: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: ${({ floating }) => (floating ? '12px' : '14px')};
  color: ${({ disabled }) => (disabled ? Colors.white10 : Colors.white30)};
  /* z-index: 1; */
  transition: ${({ animateTransition }) =>
    animateTransition
      ? 'top ease-in-out 0.15s, font-size ease-in-out 0.15s'
      : null};

  pointer-events: none;

  &:after {
    opacity: ${({ floating }) => (floating ? 1 : 0)};
    content: '${({ message }) => message}';
    font-family: ${RobotoMedium.fontFamily};
    font-weight: ${RobotoMedium.fontWeight};
    font-size: 12px;
    color: ${Colors.blue};
    transition: opacity ease-in-out 0.15s;
  }
`

const FloatingErrorMessage = styled.div`
  position: absolute;
  opacity: ${({ floating }) => (floating ? 0 : 1)};
  top: 13px;
  left: 0px;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 12px;
  color: ${Colors.blue};
  z-index: 1;
  transition: ${({ animateTransition }) =>
    animateTransition ? 'opacity ease-in-out 0.15s' : null};
`

const ErrorMessageSeparator = styled.span`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  margin: 0 3px;
  color: ${Colors.white30};
  transition: ${({ animateTransition }) =>
    animateTransition ? 'opacity ease-in-out 0.15s' : null};
`

const FloatingLabel = ({
  label,
  errorMessage,
  children,
  ...props
}: {
  label: React.Node,
  errorMessage: ?string,
  children: React.Node,
}) => {
  const originalInput = React.Children.only(children)

  const {
    id,
    placeholder,
    value,
    defaultValue,
    errored = false,
    onChange,
    onFocus,
    onBlur,
    disabled,
  } = originalInput.props

  const [inputId] = React.useState(id || `input-${nanoid()}`)
  const [isMounted, setIsMounted] = React.useState(false)
  const [inputIsFocused, setInputIsFocused] = React.useState(false)
  const [inputIsEmpty, setInputIsEmpty] = React.useState(
    !value && !defaultValue,
  )

  React.useEffect(() => {
    if (isMounted) {
      return
    }

    setIsMounted(true)
  }, [isMounted])

  React.useEffect(() => {
    setInputIsEmpty(!value && !defaultValue)
  }, [value, defaultValue])

  const inputElement = React.cloneElement(originalInput, {
    id: inputId,

    ...(placeholder ? { placeholder: ' ' } : null),

    onChange: (e, ...args) => {
      setInputIsEmpty(!e.target.value)

      if (onChange) {
        onChange(e, ...args)
      }
    },

    onFocus: (e, ...args) => {
      setInputIsFocused(true)

      if (onFocus) {
        onFocus(e, ...args)
      }
    },

    onBlur: (e, ...args) => {
      setInputIsFocused(false)

      if (onBlur) {
        onBlur(e, ...args)
      }
    },
  })

  const isFloating = () => inputIsFocused || !inputIsEmpty
  const hasErrorMessage = () => !!errorMessage
  const isAnimationActivated = () => isMounted

  return (
    <LabelWrapper
      linkedInputIsEmpty={inputIsEmpty}
      linkedInputIsFocused={inputIsFocused}
    >
      <LabelInput
        htmlFor={inputId}
        floating={isFloating()}
        animateTransition={isAnimationActivated()}
        linkedInputIsFocused={inputIsFocused}
        linkedInputIsErrored={errored}
        message={errorMessage}
        disabled={disabled}
      >
        {label}

        <ErrorMessageSeparator
          isVisible={isFloating() && hasErrorMessage()}
          animateTransition={isAnimationActivated()}
        >
          |
        </ErrorMessageSeparator>
      </LabelInput>

      <FloatingErrorMessage
        floating={isFloating()}
        animateTransition={isAnimationActivated()}
      >
        {errorMessage}
      </FloatingErrorMessage>

      {inputElement}
    </LabelWrapper>
  )
}

export default FloatingLabel
