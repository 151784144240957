// @flow

import * as React from 'react'
import {
  KanbanLayout,
  DragDropKanban,
} from '../Guidelines/components/Kanban/DragDropKanban'
import DragDropContext from '../Guidelines/components/Kanban/DragDropContext'
import DroppableColumn from '../Guidelines/components/Kanban/DroppableColumn'
import DraggableCard from '../Guidelines/components/Kanban/DraggableCard'
import {
  FiltersContainer,
  FiltersLabel,
  FiltersReset,
} from '../Guidelines/components/Filters'
import SelectFilter from '../Guidelines/components/SelectFilter'
import { ProgramHeader } from '../Guidelines/components/PageHeader'
import DialogModal from '../Guidelines/components/DialogModal'
import LotInformations from '../Guidelines/components/LotInformations'
import CustomerSelection from '../Guidelines/components/Process/CustomerSelection'
import CustomerForm from '../Guidelines/components/Process/CustomerForm'
import CustomerFunding from '../Guidelines/components/Process/CustomerFunding'
import OptionDuration from '../Guidelines/components/Process/OptionDuration'
import ParkingSelection from '../Guidelines/components/Process/ParkingSelection'
import CommercialOffer from '../Guidelines/components/Process/CommercialOffer'
import ResumeInformations from '../Guidelines/components/Process/ResumeInformations'

import ApplicationLayout from '../../ApplicationLayout'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import { type LotStatus } from '../../Domain/Lot'
import { getStatusColor } from '../Guidelines/components/Helper'
import { useTranslation } from 'react-i18next'

export default observer(() => {
  const { lotsStore, programsStore, router } = React.useContext(StoreContext)

  const onDrop = (
    lotId: string,
    columnStatus: LotStatus,
    lotStatus: LotStatus,
  ) => {
    if (columnStatus !== 'available' && columnStatus !== 'unavailable') {
      lotsStore.openLotModal(lotId, columnStatus, true)
    } else {
      lotsStore.updateLotStatus(lotId, columnStatus)
    }
  }

  const program = lotsStore.programId
    ? programsStore.getProgram(lotsStore.programId)
    : null

  const onClickCard = (lotId: string, status: LotStatus) => {
    lotsStore.openLotModal(lotId, status, false)
  }

  if (!program) {
    return <ApplicationLayout />
  }

  const { t } = useTranslation()

  const [collapsed, setCollapsed] = React.useState('unavailable')
  const selectedLot = lotsStore.selectedLot
  const currentStep = lotsStore.currentStep
  const customerAssociation = lotsStore.customerAssociation
  const statusColor = getStatusColor(lotsStore.transitionStatus)
  const statusColorHover = getStatusColor(lotsStore.transitionStatus, true)
  const displayedVat = lotsStore.displayedVat
  const commercialOffer = lotsStore.commercialOffer

  return (
    <ApplicationLayout>
      <ProgramHeader
        programLabel={program.label}
        programTotalNumberOfLots={program.totalNumberOfLots}
        programTotalNumberOfParkings={program.totalNumberOfParkings}
        programSoldLot={program.lotsSalesStatus.sold}
        goTo={route =>
          router.goTo(route, {
            programId: program.id,
          })
        }
        currentPath={router.currentRoute.originalPath}
        programDomain={program.programPreviewUrl}
      />

      <FiltersContainer>
        <FiltersLabel>
          {t('filters')} <span>{lotsStore.countActiveFilters}</span>
        </FiltersLabel>
        {lotsStore.filters.map(oneFilter => (
          <SelectFilter
            key={oneFilter.id}
            id={oneFilter.id}
            label={oneFilter.label}
            choices={oneFilter.choices.map(oneChoice => ({
              label: oneChoice.label,
              value: oneChoice.value,
            }))}
            onChoiceClick={(id, value) => lotsStore.updateFilters(id, value)}
            comparator={(a: string, b: string) => a === b}
            values={oneFilter.values}
          />
        ))}
        {lotsStore.atLeastOneFilterIsActive ? (
          <FiltersReset onResetFilters={() => lotsStore.resetFilterSet()} />
        ) : null}
      </FiltersContainer>

      <DragDropContext>
        <KanbanLayout>
          <DragDropKanban>
            {lotsStore.columns.map((column, index) => {
              const lotsForCurrentStatus =
                lotsStore.lotsByStatus[column.status] || []

              const lotsByVat =
                column.status === 'available' || column.status === 'unavailable'
                  ? lotsStore.lotsByVat(lotsForCurrentStatus)
                  : lotsForCurrentStatus

              return (
                <DroppableColumn
                  key={column.status}
                  columnStatus={column.status}
                  collapsable={column.collapsable}
                  isCollapsed={collapsed === column.status}
                  setCollapsed={() => setCollapsed(column.collapseColumn || '')}
                  count={lotsForCurrentStatus.length}
                  subCount={lotsByVat.length}
                  onDrop={onDrop}
                  displayedVat={displayedVat}
                  setDisplayedVat={vat => lotsStore.setDisplayedVat(vat)}
                  vatList={program.vatAvailable}
                >
                  {lotsByVat.map(lot => (
                    <DraggableCard
                      key={lot.lotId}
                      toColumn={column.to}
                      lotId={lot.lotId}
                      status={lot.status}
                      label={lot.label}
                      typology={lot.typology}
                      area={lot.area}
                      orientation={lot.orientation}
                      floorLabel={lot.floorLabel}
                      buildingLabel={lot.buildingLabel}
                      onClickCard={onClickCard}
                      pricesIncludingVAT={lot.pricesIncludingVAT}
                      displayedVat={displayedVat}
                      commercialOffer={
                        lot.commercialOffer &&
                        lot.commercialOffer.cardInformation
                      }
                      lotStatus={lot.status}
                      balconyArea={lot.balconyArea}
                      gardenArea={lot.gardenArea}
                      duplex={lot.duplex}
                      parkings={lot.parkings}
                    />
                  ))}
                </DroppableColumn>
              )
            })}
          </DragDropKanban>
        </KanbanLayout>
      </DragDropContext>

      {selectedLot && commercialOffer && (
        <DialogModal
          onClose={() => lotsStore.closeLotModal()}
          rightContent={
            currentStep && currentStep.type === 'customer_selection' ? (
              <CustomerSelection
                title={currentStep.label}
                stepValue={currentStep.value}
                color={statusColor}
                customers={lotsStore.customerSummaries || []}
                onCustomerSelected={customerId =>
                  lotsStore.selectCustomerAssociation(customerId)
                }
                onNewCustomer={() => lotsStore.goToStep('customer_creation')}
                onSkipCustomer={() => lotsStore.goToStep('customer_funding')}
              />
            ) : currentStep && currentStep.type === 'customer_creation' ? (
              <CustomerForm
                title={currentStep.label}
                stepValue={currentStep.value}
                color={statusColor}
                customers={lotsStore.customerSummaries || []}
                onSubmit={customer =>
                  lotsStore.creationCustomerAssociation(customer)
                }
                errors={[]}
                email={customerAssociation ? customerAssociation.email : ''}
                firstName={
                  customerAssociation ? customerAssociation.firstName : ''
                }
                lastName={
                  customerAssociation
                    ? (customerAssociation.lastName &&
                        customerAssociation.lastName) ||
                      ''
                    : ''
                }
                phone={
                  customerAssociation
                    ? (customerAssociation.phone &&
                        customerAssociation.phone) ||
                      ''
                    : ''
                }
                address={
                  customerAssociation
                    ? (customerAssociation.street &&
                        customerAssociation.street) ||
                      ''
                    : ''
                }
                zipCode={
                  customerAssociation
                    ? (customerAssociation.zipCode &&
                        customerAssociation.zipCode) ||
                      ''
                    : ''
                }
                city={
                  customerAssociation
                    ? (customerAssociation.city && customerAssociation.city) ||
                      ''
                    : ''
                }
                customerId={
                  customerAssociation ? customerAssociation.customerId : null
                }
                fetchLoadedCustomer={customerId =>
                  lotsStore.selectCustomerAssociation(customerId)
                }
                goBackSearch={() => lotsStore.goToStep('customer_selection')}
              />
            ) : currentStep && currentStep.type === 'customer_funding' ? (
              <CustomerFunding
                title={currentStep.label}
                stepValue={currentStep.value}
                color={statusColor}
                username={
                  customerAssociation
                    ? `${customerAssociation.firstName} ${
                        customerAssociation.lastName || ''
                      }`
                    : ''
                }
                funding={commercialOffer.loan}
                onFundingSelected={loan => lotsStore.fundingSelection(loan)}
                goBackClient={() => lotsStore.goToStep('customer_creation')}
                rightAction={{
                  label:
                    lotsStore.transitionStatus === 'optioned'
                      ? t('process.duration')
                      : t('process.parking'),
                  method: () =>
                    lotsStore.goToStep(
                      lotsStore.transitionStatus === 'optioned'
                        ? 'option_duration'
                        : 'parking_selection',
                    ),
                }}
              />
            ) : currentStep && currentStep.type === 'option_duration' ? (
              <OptionDuration
                goBackFunding={() => lotsStore.goToStep('customer_funding')}
                currentVat={commercialOffer.vat}
                currentDuration={
                  commercialOffer.duration
                    ? Math.round(commercialOffer.duration / 24)
                    : undefined
                }
                vatList={selectedLot.vatAvailable}
                onOption={(vat, duration) =>
                  lotsStore.onOptioning(vat, duration)
                }
              />
            ) : currentStep && currentStep.type === 'parking_selection' ? (
              <ParkingSelection
                title={currentStep.label}
                color={statusColor}
                goBackFunding={() => lotsStore.goToStep('customer_funding')}
                goToOffer={() => lotsStore.goToStep('commercial_offer')}
                parkingsLinked={commercialOffer.parkings}
                removeParking={parkingId =>
                  lotsStore.onParkingRemoved(parkingId)
                }
                parkingsAvailable={lotsStore.parkingsAvailable}
                onParkingSelected={parking =>
                  lotsStore.onParkingSelected(parking)
                }
              />
            ) : currentStep && currentStep.type === 'commercial_offer' ? (
              <CommercialOffer
                title={currentStep.label}
                color={statusColor}
                colorHover={statusColorHover}
                goBackParking={() => lotsStore.goToStep('parking_selection')}
                currentVat={commercialOffer.vat}
                currentDiscount={commercialOffer.discount || 0}
                currentNotaryFeeIncluded={commercialOffer.notaryFeeIncluded}
                vatList={selectedLot.vatAvailable}
                onAction={(
                  vat,
                  discount,
                  notaryFeeIncluded,
                  totalPriceIncludingVAT,
                ) =>
                  lotsStore.applyCommercialOffer({
                    vat,
                    discount,
                    notaryFeeIncluded,
                    totalPriceIncludingVAT,
                  })
                }
                pricesIncludingVAT={selectedLot.pricesIncludingVAT}
                parkingsPriceExcludingVAT={lotsStore.parkingsPriceExcludingVAT}
              />
            ) : (
              <ResumeInformations
                currentStatus={lotsStore.transitionStatus}
                color={statusColor}
                handleDurationEdition={() =>
                  lotsStore.goToStep('option_duration')
                }
                handleCustomerEdition={() =>
                  lotsStore.goToStep('customer_creation')
                }
                handleFundingEdition={() =>
                  lotsStore.goToStep('customer_funding')
                }
                handleAddCustomer={() =>
                  lotsStore.goToStep('customer_selection')
                }
                handleCommercialOfferEdition={() =>
                  lotsStore.goToStep('commercial_offer')
                }
                customer={lotsStore.customerAssociationPayload}
                commercialOffer={commercialOffer}
              />
            )
          }
          rightContentIsOpen={
            lotsStore.transitionStatus !== 'available' &&
            lotsStore.transitionStatus !== 'unavailable'
          }
        >
          <LotInformations
            label={selectedLot.label}
            pricesIncludingVAT={selectedLot.pricesIncludingVAT}
            typology={selectedLot.typology}
            area={selectedLot.area}
            balconyArea={selectedLot.balconyArea}
            gardenArea={selectedLot.gardenArea}
            orientation={selectedLot.orientation}
            floorLabel={selectedLot.floorLabel}
            buildingLabel={selectedLot.buildingLabel}
            displayedVat={displayedVat}
            duplex={selectedLot.duplex}
            parkings={selectedLot.parkings}
            status={selectedLot.status}
            statusAllow={['unavailable', 'available']}
            requestStatusChange={status =>
              lotsStore.onStatusChange(selectedLot.lotId, status)
            }
            inTransition={!!currentStep}
            fetchHistory={() => lotsStore.fetchHistory(selectedLot.lotId)}
            events={lotsStore.lotEvents}
            links={selectedLot.links}
            markAsContacted={contactRequestId =>
              programsStore.contactRequest(program.id, contactRequestId)
            }
          />
        </DialogModal>
      )}
    </ApplicationLayout>
  )
})
