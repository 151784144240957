// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 17"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#393939"
        d="M8,1 C12.418278,1 16,4.581722 16,9 C16,13.418278 12.418278,17 8,17 C3.581722,17 0,13.418278 0,9 C0,4.581722 3.581722,1 8,1 Z M8,3 C4.6862915,3 2,5.6862915 2,9 C2,12.3137085 4.6862915,15 8,15 C11.3137085,15 14,12.3137085 14,9 C14,5.6862915 11.3137085,3 8,3 Z M10.4603345,6.79745407 C10.5132218,6.92085772 10.5132218,7.06054465 10.4603345,7.18394829 L9.17738093,10.1775067 L6.1838225,11.4604603 C5.93479182,11.5671878 5.64639289,11.4518282 5.53966546,11.2027975 C5.48677818,11.0793939 5.48677818,10.9397069 5.53966546,10.8163033 L6.82261907,7.82274487 L9.8161775,6.53979125 C10.0652082,6.43306382 10.3536071,6.54842339 10.4603345,6.79745407 Z"
      />
      <path
        fill="#1E1E1E"
        d="M8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 Z M8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 Z M10.4603345,5.79745407 C10.5132218,5.92085772 10.5132218,6.06054465 10.4603345,6.18394829 L9.17738093,9.17750672 L6.1838225,10.4604603 C5.93479182,10.5671878 5.64639289,10.4518282 5.53966546,10.2027975 C5.48677818,10.0793939 5.48677818,9.93970694 5.53966546,9.81630329 L6.82261907,6.82274487 L9.8161775,5.53979125 C10.0652082,5.43306382 10.3536071,5.54842339 10.4603345,5.79745407 Z"
      />
    </g>
  </svg>
)
