// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#fff"
      fillOpacity="0.3"
      fillRule="evenodd"
      d="M12.5 5v7c0 1.105-.895 2-2 2h-5c-1.105 0-2-.895-2-2V5h9zm-3 1.5c-.414 0-.75.336-.75.75v4.5c0 .414.336.75.75.75s.75-.336.75-.75v-4.5c0-.414-.336-.75-.75-.75zm-3 0c-.414 0-.75.336-.75.75v4.5c0 .414.336.75.75.75s.75-.336.75-.75v-4.5c0-.414-.336-.75-.75-.75zm3-4.5v.75H14v1.5H2v-1.5h4.5V2h3z"
    />
  </svg>
)
