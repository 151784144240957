// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g fill="none">
      <path
        fill="#323232"
        d="M32 1c17.667 0 32 14.11 32 31.5S49.667 64 32 64 0 49.89 0 32.5 14.333 1 32 1zm19.74 18.205L19.199 52.272c3.705 2.28 8.095 3.599 12.802 3.599 13.255 0 24-10.464 24-23.371 0-4.94-1.574-9.52-4.26-13.295zM32 9.129C18.745 9.129 8 19.593 8 32.5c0 5.451 1.917 10.467 5.13 14.442l32.886-33.416C42.072 10.76 37.232 9.13 32 9.13z"
      />
      <path
        fill="#181818"
        d="M32 0c17.667 0 32 14.11 32 31.5S49.667 63 32 63 0 48.89 0 31.5 14.333 0 32 0zm19.74 18.205L19.199 51.272c3.705 2.28 8.095 3.599 12.802 3.599 13.255 0 24-10.464 24-23.371 0-4.94-1.574-9.52-4.26-13.295zM32 8.129C18.745 8.129 8 18.593 8 31.5c0 5.451 1.917 10.467 5.13 14.442l32.886-33.416C42.072 9.76 37.232 8.13 32 8.13z"
      />
    </g>
  </svg>
)
