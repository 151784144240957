// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      fillRule="evenodd"
      d="M2.356 11.765c.39.328.958.307 1.322-.03l.087-.091 4.291-5.099 4.185 4.873c.332.387.896.458 1.31.183l.1-.075c.387-.332.458-.896.184-1.31l-.076-.1-4.95-5.767c-.375-.437-1.032-.463-1.44-.081l-.085.088-5.05 6c-.355.423-.3 1.053.122 1.41z"
    />
  </svg>
)
