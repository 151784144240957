// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      fillRule="evenodd"
      d="M20 7c7.18 0 13 5.82 13 13s-5.82 13-13 13S7 27.18 7 20 12.82 7 20 7zm0 3.25c-5.385 0-9.75 4.365-9.75 9.75 0 .554.046 1.097.135 1.626h4.808c.387 2.305 2.392 4.061 4.807 4.061 2.415 0 4.42-1.756 4.807-4.061h4.808c.089-.529.135-1.072.135-1.626 0-5.385-4.365-9.75-9.75-9.75zm3.45 3.86c-.102 4.707-.359 7.501-.77 8.382-.616 1.322-2.27 1.856-3.693 1.192-1.424-.664-2.078-2.274-1.462-3.595.411-.881 2.386-2.874 5.926-5.978z"
    />
  </svg>
)
