// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill="#0CF"
      d="M5.081 12c.938.012 1.139-.98.168-1.378-.916-.374-1.295-1.05-.927-1.775.324-.63 1.697-.969 3.003-.992v2.043c0 .199.1.385.268.479.078.047.156.07.246.07.1 0 .2-.035.29-.093 2.054-1.448 3.684-3.702 3.762-3.795.145-.199.145-.479 0-.678-.078-.093-1.708-2.346-3.762-3.794-.168-.105-.368-.117-.536-.024-.167.094-.268.28-.268.48v2.02C4.68 4.69 2 5.66 2 8.332 2 10.61 4.088 11.978 5.081 12z"
    />
  </svg>
)
