// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFD050"
      d="M24.597 4.636l14.718 25.656C41.293 33.74 38.752 38 34.717 38H5.282c-4.034 0-6.575-4.26-4.597-7.708L15.403 4.636c2.016-3.515 7.178-3.515 9.194 0zM20 27.656c-1.457 0-2.638 1.158-2.638 2.586s1.181 2.586 2.638 2.586c1.456 0 2.637-1.158 2.637-2.586s-1.18-2.586-2.637-2.586zm0-18.103c-1.457 0-2.638 1.158-2.638 2.586v7.759l.006.163c.086 1.352 1.231 2.423 2.632 2.423 1.456 0 2.637-1.158 2.637-2.586v-7.759l-.005-.163C22.546 10.624 21.4 9.553 20 9.553z"
    />
  </svg>
)
