// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Colors'
import { StatusDot } from './Dot'
import Price from '../../Typography/Price'
import LinkActionSvg from '../../icons/LinkActionSvg'
import Tooltip from '../Tooltip'
import { RobotoBold } from '../../Typography/Fonts'
import Taxes from '../../Typography/Taxes'
import {
  BackgroundContainer,
  ColumnsWrapper,
  TableWrapper,
  Container,
  Table,
  TableForThead,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
  TdInput,
  PriceInput,
  ColumnLeft,
  ColumnRight,
  BuildingLabel,
  SaveContainer,
  Legend,
  Modified,
  Feedback,
} from './Table'
import { SubTitle, TextBold } from '../Helper'
import DashedSeparator from '../../DashedSeparator'
import { ButtonBlue } from '../Button'
import SelectStatus from '../SelectStatus'
import DialogModal from '../DialogModal'
import SearchField from '../Form/SearchField'

const LinkEstateWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 24px;
  height: 24px;
  padding-right: 8px;
  pointer-events: ${({ isSold }) => (isSold ? 'none' : 'all')};

  path {
    transition: fill 0.15s ease-in-out;
    fill: ${({ isUnvailable, isSold }) =>
      isUnvailable
        ? Colors.white20
        : isSold
        ? Colors.green75
        : Colors.white100};
  }

  &:hover path {
    fill: ${Colors.blue};
  }
`

const EstateLabel = styled.div`
  cursor: pointer;
  pointer-events: ${({ isSold }) => (isSold ? 'none' : 'all')};
  color: ${({ isUnvailable, isSold }) =>
    isUnvailable ? Colors.white20 : isSold ? Colors.green75 : Colors.white100};
  transition: color 0.15s ease-in-out;
  width: min-content;
  margin: auto;
  padding-right: 8px;

  &:hover {
    color: ${Colors.blue};
  }
`

const Separator = styled(DashedSeparator)`
  margin-top: 20px;
`

const LotLabel = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 33px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 38px;
  color: ${Colors.white100};
  margin-bottom: 38px;
`

const LinkedWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonContainer = styled.div`
  position: absolute;
  bottom: 30px;
`

type Parking = {|
  id: string,
  label: string,
  status: string,
  priceExcludingVAT: number,
  estateLinked: ?{ lotId: string, label: string, status: string },
|}

type Floor = {|
  id: string,
  label: string,
  order: number,
  parkings: Parking[],
|}

type Building = {|
  id: string,
  label: string,
  floors: Floor[],
|}

const isValidPrice = (price: ?number): boolean => {
  return price !== undefined && price !== null && price > 0
}

type Props = {|
  buildings: { id: string, label: string }[],
  vatList: number[],
  saveParkings: () => Promise<void>,
  currentBuilding: Building,
  onSelectBuilding: (buildingId: string) => void,
  parkingsUpdates: Parking[],
  onParkingPriceEdition: (parking: Parking, value: number) => void,
  onParkingStatusEdition: (parking: Parking, status: string) => void,
  onAddLot: (
    lotId: string,
    parkingId: string,
    lotLabel: string,
    lotStatus: string,
    parking: Parking,
  ) => Promise<void>,
  onRemoveLot: (lotId: string, parkingId: string) => Promise<void>,
  lotsForAssociation: { lotId: string, label: string, status: string }[],
  onImportParkings: (file: string) => Promise<void>,
  onExportParkings: () => Promise<void>,
  closeFeedback: () => void,
  importSucceed: ?boolean,
  importError: ?string,
|}

const ParkingsEdition = ({
  buildings,
  vatList,
  saveParkings,
  currentBuilding,
  onSelectBuilding,
  parkingsUpdates,
  onParkingPriceEdition,
  onParkingStatusEdition,
  onAddLot,
  onRemoveLot,
  lotsForAssociation,
  onImportParkings,
  onExportParkings,
  closeFeedback,
  importSucceed,
  importError,
}: Props) => {
  const { t } = useTranslation()

  const [parkingAssociation, setParkingAssociation] = React.useState(null)

  const applyVatToAmount = (amount: number, vat: number): number => {
    if (!amount) {
      return 0
    }
    return amount * (1 + vat / 100)
  }

  const linkedWith = t('programEdition.linkedWith')

  return (
    <BackgroundContainer>
      {typeof importSucceed === 'boolean' && (
        <Feedback
          close={closeFeedback}
          succeed={importSucceed}
          message={importError}
        />
      )}
      <ColumnsWrapper>
        <ColumnLeft>
          {buildings.map(oneBuilding => (
            <BuildingLabel
              key={oneBuilding.id}
              onClick={() => onSelectBuilding(oneBuilding.id)}
              isActive={oneBuilding.id === currentBuilding.id}
            >
              {oneBuilding.label}
            </BuildingLabel>
          ))}
        </ColumnLeft>

        <TableWrapper>
          <TableForThead>
            <Thead>
              <Tr>
                <Th width="88px">{t('programEdition.floor')}</Th>
                <Th width="72px">
                  <span style={{ marginLeft: '8px' }}>
                    {t('programEdition.parking')}
                  </span>
                </Th>
                <Th width="64px">{t('programEdition.status')}</Th>
                <Th width="108px">{t('programEdition.exclusiveTax')}</Th>
                {vatList.map((oneVat, index) => (
                  <Th key={index} width="80px">
                    <Taxes amount={oneVat} />
                  </Th>
                ))}
                <Th width="72px">
                  <span style={{ marginRight: '8px' }}>
                    {t('programEdition.estate')}
                  </span>
                </Th>
              </Tr>
            </Thead>
          </TableForThead>
          <Container>
            <Table>
              <Thead>
                <Tr>
                  <Th width="88px" />
                  <Th width="72px" />
                  <Th width="64px" />
                  <Th width="108px" />
                  {vatList.map((oneVat, index) => (
                    <Th key={index} width="80px" />
                  ))}
                  <Th width="72px" />
                </Tr>
              </Thead>

              {currentBuilding.floors.map(oneFloor => {
                return (
                  <Tbody key={oneFloor.id}>
                    {oneFloor.parkings.map((oneParking, parkingIndex) => {
                      const parkingIsSold = oneParking.status === 'sold'
                      const parkingIsUnvailable =
                        oneParking.status === 'unavailable'
                      const parkingUpdate = parkingsUpdates.find(
                        oneParkingChanged =>
                          oneParkingChanged.id === oneParking.id,
                      )
                      const lot = oneParking.estateLinked

                      const isFirstParking = parkingIndex === 0
                      const isUpdated = !!parkingUpdate

                      const isUnvailable = parkingUpdate
                        ? parkingUpdate.status === 'unavailable'
                        : lot
                        ? lot.status === 'unavailable'
                        : parkingIsUnvailable

                      const isSold = parkingUpdate
                        ? parkingUpdate.status === 'sold'
                        : lot
                        ? lot.status === 'sold'
                        : parkingIsSold

                      return (
                        <Tr key={oneParking.id} isUnvailable={isUnvailable}>
                          {isFirstParking && (
                            <Td rowSpan={oneFloor.parkings.length}>
                              <span>{oneFloor.label}</span>
                            </Td>
                          )}

                          <Td isUnvailable={isUnvailable} isSold={isSold}>
                            <Tooltip
                              content={
                                isUpdated
                                  ? t('programEdition.hasModified')
                                  : undefined
                              }
                            >
                              <Modified
                                isUpdated={isUpdated}
                                isFirst={isFirstParking}
                              />
                            </Tooltip>
                            <span style={{ marginLeft: '8px' }}>
                              {oneParking.label}
                            </span>
                          </Td>

                          <Td>
                            {oneParking.estateLinked ? (
                              <StatusDot
                                value={oneParking.estateLinked.status}
                              />
                            ) : (
                              <SelectStatus
                                currentStatus={
                                  parkingUpdate
                                    ? parkingUpdate.status
                                    : oneParking.status
                                }
                                onStatusChanged={status =>
                                  onParkingStatusEdition(oneParking, status)
                                }
                              />
                            )}
                          </Td>

                          <TdInput>
                            <PriceInput
                              defaultValue={oneParking.priceExcludingVAT}
                              onValueChange={({ floatValue }) => {
                                onParkingPriceEdition(oneParking, floatValue)
                              }}
                              isValidValue={
                                parkingUpdate
                                  ? isValidPrice(
                                      parkingUpdate.priceExcludingVAT,
                                    )
                                  : true
                              }
                              disabled={
                                (lot && lot.status === 'sold') ||
                                (!parkingUpdate && parkingIsSold)
                              }
                              isUnvailable={isUnvailable}
                              isSold={isSold}
                            />
                          </TdInput>

                          {vatList.map((oneVat, index) => (
                            <Td
                              key={index}
                              isUnvailable={isUnvailable}
                              isSold={isSold}
                            >
                              <Price
                                amount={applyVatToAmount(
                                  parkingUpdate
                                    ? parkingUpdate.priceExcludingVAT
                                    : oneParking.priceExcludingVAT,
                                  oneVat,
                                )}
                              />
                            </Td>
                          ))}

                          <Td>
                            {lot ? (
                              <EstateLabel
                                onClick={() =>
                                  setParkingAssociation(oneParking)
                                }
                                className="trHoverWhite100"
                                isUnvailable={lot.status === 'unavailable'}
                                isSold={lot.status === 'sold'}
                              >
                                {lot.label}
                              </EstateLabel>
                            ) : (
                              !isSold && (
                                <LinkEstateWrapper
                                  onClick={() =>
                                    setParkingAssociation(oneParking)
                                  }
                                  className="trHoverPathWhite100"
                                  isUnvailable={isUnvailable}
                                  isSold={isSold}
                                >
                                  <LinkActionSvg />
                                </LinkEstateWrapper>
                              )
                            )}
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                )
              })}
            </Table>
          </Container>
        </TableWrapper>
        <ColumnRight>
          <Legend onImport={onImportParkings} onExport={onExportParkings} />
          <SaveContainer>
            <div>
              {parkingsUpdates.length} {t('programEdition.modifications')}
            </div>
            <ButtonBlue
              onClick={saveParkings}
              disabled={
                parkingsUpdates.length === 0 ||
                parkingsUpdates.some(
                  parking => !isValidPrice(parking.priceExcludingVAT),
                )
              }
            >
              {t('programEdition.apply')}
            </ButtonBlue>
          </SaveContainer>
        </ColumnRight>
      </ColumnsWrapper>

      {parkingAssociation && (
        <DialogModal onClose={() => setParkingAssociation(null)}>
          <SubTitle>{t('programEdition.parking')}</SubTitle>
          <TextBold>{parkingAssociation.label}</TextBold>
          <Separator />
          {parkingAssociation.estateLinked ? (
            <LinkedWrapper>
              <SubTitle>{linkedWith}</SubTitle>
              <LotLabel>{parkingAssociation.estateLinked.label}</LotLabel>

              <ButtonContainer>
                <ButtonBlue
                  onClick={() => {
                    parkingAssociation.estateLinked &&
                      onRemoveLot(
                        parkingAssociation.estateLinked.lotId,
                        parkingAssociation.id,
                      )
                    setParkingAssociation(null)
                  }}
                >
                  {t('programEdition.unlink')}
                </ButtonBlue>
              </ButtonContainer>
            </LinkedWrapper>
          ) : (
            <SearchField
              color={Colors.blue}
              valueId="lotId"
              values={lotsForAssociation}
              searchableFields={['label']}
              placeholder={t('process.search')}
              onSuggestionSelected={suggestion => {
                onAddLot(
                  suggestion.lotId,
                  parkingAssociation.id,
                  suggestion.label,
                  suggestion.status,
                  parkingAssociation,
                )
                setParkingAssociation(null)
              }}
              renderSuggestion={suggestion => suggestion.label}
              displaySuggestionsInRow
            />
          )}
        </DialogModal>
      )}
    </BackgroundContainer>
  )
}

export default React.memo<Props>(ParkingsEdition)
