// @flow

export const RobotoNormal = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 400,
}

export const RobotoMedium = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 500,
}

export const RobotoBold = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 700,
}

export const RobotoExtraBold = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  fontWeight: 900,
}
