// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill="#0CF"
      d="M3.293 8.55c.14 0 .27-.055.366-.152.184-.183.205-.474.044-.678-.022-.033-.291-.41-.291-.884 0-.377.15-.722.42-.96.302-.28.743-.43 1.271-.43h2.425l-.01 1.077c0 .119.064.227.182.28.033.011.076.022.119.022.075 0 .15-.021.205-.075.937-.84 1.789-2.026 1.875-2.199.043-.086.043-.183 0-.269-.086-.183-.927-1.368-1.875-2.209-.086-.075-.216-.097-.324-.043-.118.043-.183.15-.183.27v1.077h-.69c-.775 0-1.465.065-2.058.183-.592.13-1.099.323-1.508.571-.42.27-.744.614-.949 1.013C2.108 5.532 2 5.984 2 6.48c0 .496.172.992.485 1.434.226.323.463.506.485.528.097.075.215.107.323.107zM6.3 11.975c.118-.054.183-.162.183-.28v-1.078h.69c.775 0 1.465-.064 2.058-.183.592-.13 1.099-.323 1.508-.571.42-.27.744-.614.948-1.013.205-.388.313-.84.313-1.336 0-.496-.172-.992-.485-1.433-.237-.324-.463-.507-.496-.528-.086-.076-.204-.108-.312-.108-.14 0-.27.054-.366.15-.184.184-.205.475-.044.68.022.032.291.41.291.883 0 .377-.15.722-.42.96-.302.28-.743.43-1.271.43H6.483V7.472c0-.119-.065-.227-.183-.27-.033-.021-.076-.032-.119-.032-.075 0-.15.032-.205.075-.937.841-1.788 2.026-1.875 2.199-.043.086-.043.183 0 .27.087.182.927 1.368 1.875 2.208.087.076.216.097.324.054z"
    />
  </svg>
)
