// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#2F2F2F"
      fillRule="evenodd"
      d="M11.066 13.112c.964-.603 1.798-1.473 2.319-2.539.508-1.038.742-2.314.547-3.576-.091-.617-.287-1.235-.56-1.796-.274-.56-.625-1.051-1.03-1.486-.807-.87-2.044-1.487-3.255-1.655-.274-.042-.56-.07-.834-.056-.95 0-1.888.266-2.683.771-.56.365-1.055.814-1.458 1.318L2.874 2.551c-.117-.14-.3-.197-.468-.14-.157.055-.287.21-.313.392-.313 2.749.26 5.793.287 5.933.039.21.208.378.416.378h.26c.86 0 3.087-.042 5.106-.659.17-.042.3-.196.326-.378.026-.183-.052-.38-.196-.477l-1.64-1.164c.195-.225.39-.421.625-.575.43-.267.911-.393 1.393-.351.482.056.95.28 1.368.673.442.407.742.884.885 1.43.287 1.067-.117 2.595-1.107 3.409-.495.407-1.133.673-1.81.757-.677.07-1.355-.014-2.019-.364-.39-.197-.846-.028-1.042.392-.156.365-.052.8.235 1.038h.013c.781.645 1.823 1.108 2.852 1.15 1.016.042 2.07-.294 3.021-.883z"
    />
  </svg>
)
