// @flow

import * as React from 'react'
import styled from 'styled-components'
import FloatingLabel from './FloatingLabel'
import { Input } from './index'
import { Colors } from '../../Colors'
import DashedBorder from '../../icons/DashedBorder.svg'

const TextInputFieldContainer = styled.div`
  position: relative;
  margin-bottom: 1px;
  border-bottom: 1px solid
    ${({ linkedInputIsFocus }) =>
      linkedInputIsFocus ? Colors.blue : 'transparent'};
  transition: border-color 0.05s ease-in-out;

  &:after {
    opacity: ${({ linkedInputIsFocus }) => (linkedInputIsFocus ? 0 : 1)};
    position: absolute;
    content: '';
    min-height: 2px;
    bottom: -2px;
    width: 100%;

    background-image: url(${DashedBorder});
    background-repeat: repeat-x;
    background-size: 6px 2px;
    background-position: top center;
    transition: opacity ease-in-out 0.05s;
  }
`

type TextInputFieldProps = {
  label: React.Node,
  errorMessage?: ?string,
  onFocus?: Function,
  onBlur?: Function,
  labelOnFocus?: string,
  disabled?: boolean,
}

export const TextInputField = React.forwardRef<
  TextInputFieldProps,
  typeof Input,
>(
  (
    { label, errorMessage, onFocus, onBlur, labelOnFocus, disabled, ...props },
    ref,
  ) => {
    const [linkedInputIsFocus, setLinkedInputIsFocus] = React.useState(false)

    return (
      <TextInputFieldContainer linkedInputIsFocus={linkedInputIsFocus}>
        <FloatingLabel
          label={
            labelOnFocus ? (linkedInputIsFocus ? labelOnFocus : label) : label
          }
          disabled={disabled}
          errorMessage={errorMessage}
        >
          <Input
            {...props}
            ref={ref}
            disabled={disabled}
            onFocus={(...args) => {
              setLinkedInputIsFocus(true)

              if (onFocus) {
                onFocus(...args)
              }
            }}
            onBlur={(...args) => {
              setLinkedInputIsFocus(false)

              if (onBlur) {
                onBlur(...args)
              }
            }}
          />
        </FloatingLabel>
      </TextInputFieldContainer>
    )
  },
)
