// @flow

import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BlockLink } from '../Typography/Link'
import { RobotoBold, RobotoMedium } from '../Typography/Fonts'
import { Route } from 'mobx-router'
import { Colors } from '../Colors'
import { ProgramsHistoryButton } from './Helper'
import BackwardSvg from '../icons/BackwardSvg'
import DonutChart from './DonutChart'

const ProgramCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
`

const Section = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  color: ${Colors.white50};
  margin-bottom: 20px;

  span {
    color: ${Colors.white100};
  }
`

const ProgramCardContainer = styled.div`
  position: relative;
  width: 280px;
  height: 280px;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 ${Colors.black50}, inset 0 -2px 0 0 ${Colors.black20};
  background-color: ${Colors.dark2};
  margin: 0 8px 8px 0;
  transition: box-shadow 0.25s ease-in-out;
  margin-bottom: 20px;

  &:hover {
    box-shadow: 0 0 8px 0 ${Colors.black50}, inset 0 -2px 0 0 ${Colors.black20};
  }
`

const Label = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  color: ${({ isActive }) => (isActive ? Colors.white100 : Colors.white50)};
  text-align: center;
  padding: 16px 16px 0 16px;
`

const SubLabel = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.white50};
  text-align: center;
`

type Program = {
  id: string,
  label: string,
  totalNumberOfLots: number,
  lotsSalesStatus: {
    unavailable: number,
    available: number,
    optioned: number,
    booked: number,
    sold: number,
  },
  status: string,
}

const ProgramCard = ({
  oneProgram,
  programRoute,
  router,
  openProgramHistory,
}: {
  oneProgram: Program,
  programRoute: typeof Route,
  router: { goTo: () => Promise<void> },
  openProgramHistory: (programId: string, programName: string) => Promise<void>,
}) => {
  const { t } = useTranslation()

  return (
    <ProgramCardContainer key={oneProgram.id}>
      <ProgramsHistoryButton
        onClick={() => openProgramHistory(oneProgram.id, oneProgram.label)}
      >
        <BackwardSvg />
      </ProgramsHistoryButton>
      <BlockLink
        route={programRoute}
        params={{ programId: oneProgram.id }}
        router={router}
      >
        <Label isActive={oneProgram.status === 'active'}>
          {oneProgram.label}
        </Label>
        <SubLabel>
          {oneProgram.totalNumberOfLots} {t('programs.estates')}
          {/*  |{' '}
          {oneProgram.lotsSalesStatus.sold}{' '}
          {t('programs.sold', { count: oneProgram.lotsSalesStatus.sold })} */}
        </SubLabel>
        <DonutChart
          estate={oneProgram.totalNumberOfLots}
          unavailable={oneProgram.lotsSalesStatus.unavailable}
          available={oneProgram.lotsSalesStatus.available}
          optioned={oneProgram.lotsSalesStatus.optioned}
          booked={oneProgram.lotsSalesStatus.booked}
          sold={oneProgram.lotsSalesStatus.sold}
        />
      </BlockLink>
    </ProgramCardContainer>
  )
}

export default ({
  programsActive,
  programsFinished,
  programRoute,
  router,
  openProgramHistory,
}: {
  programsActive: Program[],
  programsFinished: Program[],
  programRoute: typeof Route,
  router: { goTo: () => Promise<void> },
  openProgramHistory: (programId: string, programName: string) => Promise<void>,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Section>
        {t('programs.active')} <span>{programsActive.length}</span>
      </Section>
      <ProgramCardList>
        {programsActive.map(oneProgram => (
          <ProgramCard
            key={oneProgram.id}
            oneProgram={oneProgram}
            programRoute={programRoute}
            router={router}
            openProgramHistory={openProgramHistory}
          />
        ))}
      </ProgramCardList>
      <Section>
        {t('programs.finished')} <span>{programsFinished.length}</span>
      </Section>
      <ProgramCardList>
        {programsFinished.map(oneProgram => (
          <ProgramCard
            key={oneProgram.id}
            oneProgram={oneProgram}
            programRoute={programRoute}
            router={router}
            openProgramHistory={openProgramHistory}
          />
        ))}
      </ProgramCardList>
    </React.Fragment>
  )
}
