// @flow

import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { TouchBackend } from 'react-dnd-touch-backend'

export default ({ children }: { children: React.Node }) => (
  <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
    {children}
  </DndProvider>
)
