// @flow

import { action, observable, runInAction, computed } from 'mobx'
import { RootStore } from '../RootStore'
import Program from '../Domain/Program'
import Building from '../Domain/Building'
import Floor from '../Domain/Floor'
import LotPrices, { type LotObject } from '../Domain/LotPrices'

export default class ProgramPricesStore {
  +rootStore: RootStore

  @observable programId: ?string = null
  @observable program: Program
  @observable updatedlots: LotObject[] = []
  @observable currentBuilding: Building

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action async fetchLotsPriceByProgramId(programId: string) {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots_price`,
    )

    if (this.program) {
      runInAction(() => {
        // $FlowFixMe
        this.program = null
      })
    }

    runInAction(() => {
      this.programId = programId
      this.program = new Program(
        response.data.programId,
        response.data.label,
        response.data.location,
        response.data.totalNumberOfLots,
        response.data.totalNumberOfParkings,
        response.data.pack,
        response.data.domain,
        response.data.buildings.map(
          oneBuildingPayload =>
            new Building(
              oneBuildingPayload.buildingId,
              oneBuildingPayload.label,
              oneBuildingPayload.floors.map(
                oneFloorPayload =>
                  new Floor(
                    oneFloorPayload.floorId,
                    oneFloorPayload.label,
                    oneFloorPayload.order,
                    oneFloorPayload.lots.map(
                      oneLotPayload =>
                        new LotPrices(
                          oneLotPayload.lotId,
                          oneLotPayload.status,
                          oneLotPayload.label,
                          oneLotPayload.pricesIncludingVAT,
                          oneLotPayload.priceExcludingVAT,
                          oneLotPayload.vatAvailable,
                          oneLotPayload.hidePrice,
                          oneLotPayload.parkings,
                        ),
                    ),
                    null,
                  ),
              ),
            ),
        ),
        response.data.baseVAT,
        response.data.status,
        response.data.lotsSalesStatus,
        response.data.vatAvailable,
      )

      this.currentBuilding = this.program.getFirstBuilding()
      this.updatedlots = []
    })
  }

  @action async saveNewLotPrices() {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${programId}/settings/lots`,
      {
        lots: this.updatedlots.map(oneLot => ({
          lotId: oneLot.id,
          vatAvailable: oneLot.vatAvailable,
          priceExcludingVAT: oneLot.priceExcludingVAT,
          hidePrice: oneLot.hidePrice,
        })),
      },
    )

    runInAction(() => {
      this.updatedlots.forEach(oneUpdatedLot => {
        this.program.updateBuildingsLot(oneUpdatedLot)
      })
      this.updatedlots = []
    })
  }

  @action onLotPriceEdition(lot: LotObject, value: number) {
    const lotEditedIndex = this.updatedlots.findIndex(
      oneLot => oneLot.id === lot.id,
    )
    if (lotEditedIndex > -1) {
      const updateLotArray = [...this.updatedlots]
      if (
        lot.priceExcludingVAT === value &&
        [...lot.vatAvailable].sort().toString() ===
          [...updateLotArray[lotEditedIndex].vatAvailable].sort().toString() &&
        lot.hidePrice === updateLotArray[lotEditedIndex].hidePrice
      ) {
        updateLotArray.splice(lotEditedIndex, 1)
      } else {
        updateLotArray[lotEditedIndex].priceExcludingVAT = value
      }
      this.updatedlots = updateLotArray
    } else {
      this.updatedlots = [
        ...this.updatedlots,
        {
          ...lot,
          vatAvailable: [...lot.vatAvailable],
          priceExcludingVAT: value,
        },
      ]
    }
  }

  @action onLotVatEdition(lot: LotObject, value: number) {
    const lotEditedIndex = this.updatedlots.findIndex(
      oneLot => oneLot.id === lot.id,
    )
    if (lotEditedIndex > -1) {
      const updateLotArray = [...this.updatedlots]
      const updateLotTaxes = updateLotArray[lotEditedIndex].vatAvailable
      if (updateLotTaxes.includes(value)) {
        updateLotTaxes.splice(updateLotTaxes.indexOf(value), 1)
      } else {
        updateLotTaxes.push(value)
      }
      if (
        lot.priceExcludingVAT ===
          updateLotArray[lotEditedIndex].priceExcludingVAT &&
        [...lot.vatAvailable].sort().toString() ===
          [...updateLotTaxes].sort().toString() &&
        lot.hidePrice === updateLotArray[lotEditedIndex].hidePrice
      ) {
        updateLotArray.splice(lotEditedIndex, 1)
      }
      this.updatedlots = updateLotArray
    } else {
      if (lot.vatAvailable.includes(value)) {
        const currentAvailableTaxes = [...lot.vatAvailable]
        currentAvailableTaxes.splice(currentAvailableTaxes.indexOf(value), 1)
        this.updatedlots = [
          ...this.updatedlots,
          { ...lot, vatAvailable: currentAvailableTaxes },
        ]
      } else {
        this.updatedlots = [
          ...this.updatedlots,
          { ...lot, vatAvailable: [...lot.vatAvailable, value] },
        ]
      }
    }
  }

  @action onLotPriceHideEdition(lot: LotObject, value: boolean) {
    const lotEditedIndex = this.updatedlots.findIndex(
      oneLot => oneLot.id === lot.id,
    )
    if (lotEditedIndex > -1) {
      const updateLotArray = [...this.updatedlots]
      if (
        lot.priceExcludingVAT ===
          updateLotArray[lotEditedIndex].priceExcludingVAT &&
        [...lot.vatAvailable].sort().toString() ===
          [...updateLotArray[lotEditedIndex].vatAvailable].sort().toString() &&
        lot.hidePrice === value
      ) {
        updateLotArray.splice(lotEditedIndex, 1)
      } else {
        updateLotArray[lotEditedIndex].hidePrice = value
      }
      this.updatedlots = updateLotArray
    } else {
      this.updatedlots = [
        ...this.updatedlots,
        {
          ...lot,
          vatAvailable: [...lot.vatAvailable],
          hidePrice: value,
        },
      ]
    }
  }

  @action setCurrentBuilding(buildingId: string) {
    this.currentBuilding = this.program.getBuildingById(buildingId)
  }

  @action async importLots(xls: string) {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    const response = { success: true, error: null }

    try {
      await await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${programId}/xls/lots`,
        {
          body: xls,
        },
      )
    } catch (err) {
      if (err.response && err.response.status === 400) {
        response.success = false
        response.error = err.response.data
      }
    }

    if (response.success) {
      await this.fetchLotsPriceByProgramId(programId)
    }

    return response
  }

  @action async exportLots() {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots.xls`,
      { responseType: 'blob' },
    )

    return response.data
  }

  @computed get currentBuildingOrdered(): Object {
    return {
      ...this.currentBuilding,
      floors: this.currentBuilding.floors
        .map(oneFloor => {
          return {
            ...oneFloor,
            lots:
              oneFloor.lots &&
              oneFloor.lots.map(oneLot => {
                return {
                  ...oneLot,
                }
              }),
          }
        })
        .sort((a, b) => a.order - b.order),
    }
  }

  @computed get buildingsWithoutFloors(): Object {
    return this.program.buildings.map(oneBuilding => {
      return {
        id: oneBuilding.id,
        label: oneBuilding.label,
      }
    })
  }

  @computed get someLotHaveChanged(): boolean {
    return this.updatedlots.length > 0
  }
}
