// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M20.933 0C27.045 0 32 4.955 32 11.067s-4.955 11.066-11.067 11.066c-.733 0-1.458-.071-2.166-.212l-.34-.074-3.223 3.224c-.197.197-.455.318-.73.345l-.118.006h-2.09v2.09c0 .623-.475 1.135-1.084 1.194l-.115.005h-2.09V30.8c0 .624-.475 1.136-1.084 1.195L7.778 32H2.844C1.274 32 0 30.727 0 29.156v-4.253c0-.754.3-1.478.833-2.011l9.319-9.32-.073-.34c-.117-.59-.187-1.19-.206-1.8l-.006-.365C9.867 4.955 14.82 0 20.933 0zm0 2.4c-4.786 0-8.666 3.88-8.666 8.667 0 .863.126 1.709.37 2.518.13.423.014.883-.3 1.196L2.53 24.59c-.083.083-.13.196-.13.314v4.253c0 .245.199.444.444.444h3.734v-2.089c0-.624.476-1.136 1.084-1.194l.116-.006h2.088v-2.089c0-.624.477-1.136 1.085-1.194l.116-.006h2.791l3.36-3.36c.285-.285.691-.406 1.081-.329l.116.03c.81.244 1.655.37 2.518.37 4.787 0 8.667-3.88 8.667-8.666 0-4.787-3.88-8.667-8.667-8.667zm0 4.178c2.48 0 4.49 2.01 4.49 4.489 0 .662-.538 1.2-1.2 1.2-.625 0-1.137-.476-1.195-1.085l-.006-.115c0-1.11-.864-2.017-1.957-2.085l-.132-.004c-.662 0-1.2-.537-1.2-1.2 0-.663.538-1.2 1.2-1.2z"
    />
  </svg>
)
