// @flow

export default class Customer {
  +customerId: ?string;
  +firstName: string;
  +lastName: string;
  +email: string;
  +phone: ?string;
  +zipCode: ?string;
  +street: ?string;
  +city: ?string

  constructor(
    customerId: ?string,
    firstName: string,
    lastName: string,
    email: string,
    phone: ?string,
    zipCode: ?string,
    street: ?string,
    city: ?string,
  ) {
    this.customerId = customerId
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.phone = phone || null
    this.zipCode = zipCode || null
    this.street = street || null
    this.city = city || null

    Object.freeze(this)
  }
}
