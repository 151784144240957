// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M20 7c7.18 0 13 5.82 13 13s-5.82 13-13 13S7 27.18 7 20 12.82 7 20 7zm0 3c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm.423 5C22.586 15 24 16.282 24 18.521 24 20.746 22.6 22 20.45 22h-1.705v2.408c0 .437-.138.592-.582.592h-1.567c-.443 0-.596-.155-.596-.592v-8.816c0-.437.153-.592.596-.592zm-.305 2.465h-1.373v2.07h1.373c.651 0 1.04-.408 1.04-1.028 0-.62-.389-1.042-1.04-1.042z"
    />
  </svg>
)
