// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#0CF"
        strokeDasharray=".25"
        strokeLinecap="round"
        strokeWidth="4"
        d="M47.943 57.693c-1.048.322-2.129.553-3.23.683l-.424.044c-3.87.398-7.935.58-12.344.58-4.357 0-8.417-.182-12.277-.578l-.435-.046C9.79 57.265 2 48.7 2 39.775c0-4.547 2.089-9.229 5.514-12.8l.111-.115.002-.296c.12-7.421 6.14-13.395 13.614-13.541C25.002 8.203 31.07 5 37.184 5c10.706 0 19.08 9.067 19.08 19.935 0 .533-.02 1.058-.064 1.579l-.024.26.112.112C59.828 30.471 62 35.172 62 39.775c0 1.768-.308 3.523-.876 5.208"
      />
      <path
        fill="#0CF"
        d="M32 40c2.946 0 5.333-2.449 5.333-5.47 0-2.014-1.777-5.524-5.333-10.53-3.556 5.006-5.333 8.516-5.333 10.53C26.667 37.55 29.054 40 32 40z"
      />
    </g>
  </svg>
)
