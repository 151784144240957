// @flow

import * as React from 'react'

export default ({
  color,
  className,
}: {
  color: string,
  className?: string,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="128"
    height="128"
    viewBox="0 0 128 128"
    className={className}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <path
        fill={color}
        d="M64,128 C28.653776,128 2.4158453e-13,99.346224 2.4158453e-13,64 C2.4158453e-13,28.653776 28.653776,0 64,0 C99.346224,0 128,28.653776 128,64 C128,99.346224 99.346224,128 64,128 Z M67.3763842,36.2908424 C65.3392902,34.9030525 62.6607098,34.9030525 60.6236158,36.2908424 L37.3717826,52.1313772 C35.1328674,53.6566597 34.1941132,56.4936752 35.0812131,59.0537231 L43.8002483,84.2156554 C44.6370622,86.6305851 46.9118181,88.25 49.4672019,88.25 L78.5327981,88.25 C81.0881819,88.25 83.3629378,86.6305851 84.1997517,84.2156554 L92.9187869,59.0537231 C93.8058868,56.4936752 92.8671326,53.6566597 90.6282174,52.1313772 L67.3763842,36.2908424 Z M64,82 C67.6818983,82 70.6666667,78.9386991 70.6666667,75.1623932 C70.6666667,72.6448558 68.4444444,68.2573915 64,62 C59.5555556,68.2573915 57.3333333,72.6448558 57.3333333,75.1623932 C57.3333333,78.9386991 60.3181017,82 64,82 Z"
      />
    </g>
  </svg>
)
