// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="#FFF"
      fillRule="evenodd"
      d="M4.8 12c.961-3.13 3.821-5.4 7.2-5.4s6.238 2.27 7.2 5.4c-.962 3.13-3.821 5.4-7.2 5.4S5.761 15.13 4.8 12zM12 9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0 1.5c-.828 0-1.5.672-1.5 1.5s.672 1.5 1.5 1.5 1.5-.672 1.5-1.5-.672-1.5-1.5-1.5z"
    />
  </svg>
)
