// @flow

import React from 'react'
import { MobxRouter, startRouter } from 'mobx-router'
import { StoreProvider } from './StoreContext'
import { configure } from 'mobx'
import store from './RootStore'
import Loader from './Loader'
import routes from './Routes'

configure({
  enforceActions: 'always',
  isolateGlobalState: false,
})

store.authenticationStore.loadTokenFromStorage()

startRouter(routes, store, {
  html5history: true,
  notfound: () => store.router.goTo(routes.page_not_found),
})

export default () => (
  <StoreProvider value={store}>
    <Loader />
    <MobxRouter store={store} />
  </StoreProvider>
)
