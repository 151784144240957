// @flow

import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import ApplicationLayout from '../../ApplicationLayout'
import Edition from '../Guidelines/components/User/Edition'

const UserEditionPage = observer(() => {
  const { authenticationStore } = React.useContext(StoreContext)

  const me = authenticationStore.me
  if (!me) {
    return <ApplicationLayout />
  }

  return (
    <ApplicationLayout>
      <Edition
        firstName={me.firstName}
        lastName={me.lastName}
        email={me.email}
        onProfileEdition={(user, password) =>
          authenticationStore.onProfileEdition(user, password)
        }
      />
    </ApplicationLayout>
  )
})

export default UserEditionPage
