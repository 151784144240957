// @flow

import * as React from 'react'

export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className={className}
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M3.428 13.923c.248-.145.525-.277.802-.408.758-.379 1.677-.802 2.77-1.487 1.094.685 2.012 1.108 2.77 1.487.277.131.555.263.802.408.102.044.205.073.321.073.131 0 .263-.043.38-.116.218-.146.335-.38.32-.642-.073-1.006-.335-2.435-.729-4.038 1.443-1.225 2.595-2.464 3.047-3.295.102-.175.117-.394.044-.583-.058-.175-.204-.336-.38-.409-.699-.306-2.536-.423-4.125-.48C8.954 2.988 8.152.86 7.48.203 7.351.073 7.175 0 7.001 0c-.176 0-.35.073-.496.204C5.848.86 5.047 2.99 4.55 4.432c-1.59.058-3.427.175-4.126.481-.19.073-.321.234-.38.409-.072.19-.058.408.044.583.452.83 1.604 2.07 3.048 3.295-.394 1.603-.657 3.032-.73 4.038-.014.263.103.496.321.642.204.146.481.16.7.043z"
    />
  </svg>
)
