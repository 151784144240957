// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M4.9 0c.624 0 1.136.476 1.195 1.084L6.1 1.2v17.448c2.126.535 3.7 2.46 3.7 4.752s-1.574 4.217-3.7 4.752V30.8c0 .663-.537 1.2-1.2 1.2-.624 0-1.136-.476-1.195-1.084L3.7 30.8v-2.648C1.574 27.617 0 25.692 0 23.4s1.574-4.217 3.7-4.752V1.2C3.7.537 4.237 0 4.9 0zM16 0c.624 0 1.136.476 1.195 1.084l.005.116v2.648c2.126.535 3.7 2.46 3.7 4.752s-1.574 4.217-3.7 4.752V30.8c0 .663-.537 1.2-1.2 1.2-.624 0-1.136-.476-1.195-1.084L14.8 30.8V13.352c-2.126-.535-3.7-2.46-3.7-4.752s1.574-4.217 3.7-4.752V1.2c0-.663.537-1.2 1.2-1.2zm11.1 0c.624 0 1.136.476 1.195 1.084l.005.116v17.448c2.126.535 3.7 2.46 3.7 4.752s-1.574 4.217-3.7 4.752V30.8c0 .663-.537 1.2-1.2 1.2-.624 0-1.136-.476-1.195-1.084L25.9 30.8v-2.648c-2.126-.535-3.7-2.46-3.7-4.752s1.574-4.217 3.7-4.752V1.2c0-.663.537-1.2 1.2-1.2zM4.9 20.9c-1.38 0-2.5 1.12-2.5 2.5 0 1.333 1.043 2.422 2.358 2.496l.142.004c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5zm22.2 0c-1.38 0-2.5 1.12-2.5 2.5 0 1.333 1.043 2.423 2.358 2.496l.142.004c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5zM15.99 6.1l-.132.004C14.543 6.178 13.5 7.267 13.5 8.6s1.043 2.422 2.358 2.496L16 11.1c1.38 0 2.5-1.12 2.5-2.5 0-1.33-1.038-2.417-2.348-2.495l-.162-.006z"
    />
  </svg>
)
