// @flow

import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import LoginForm from '../Guidelines/components/Login/LoginForm'
import LoginScreen from '../Guidelines/components/Login/LoginScreen'

export default observer(() => {
  const { authenticationStore } = React.useContext(StoreContext)

  return (
    <LoginScreen>
      <LoginForm
        onLogin={(email, password, rememberMe) =>
          authenticationStore.performLogin(email, password, rememberMe)
        }
        onRequestNewPassword={email =>
          authenticationStore.requestNewPassword(email)
        }
      />
    </LoginScreen>
  )
})
