// @flow

import styled from 'styled-components'
import DashedBorder from './icons/DashedBorder.svg'
import VerticalDashedBorder from './icons/VerticalDashedBorder.svg'

const DashedSeparator = styled.div`
  min-height: 2px;
  width: 100%;

  background-image: url(${DashedBorder});
  background-repeat: repeat-x;
  background-size: 6px 2px;
  background-position: top center;
`

export const VerticalDashedSeparator = styled.div`
  height: 100%;
  min-width: 2px;

  background-image: url(${VerticalDashedBorder});
  background-repeat: repeat-y;
  background-size: 2px 6px;
  background-position: top center;
`

export default DashedSeparator
