// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M21.213 0c.945 0 1.852.373 2.522 1.037l11.215 11.13c.672.666 1.05 1.572 1.05 2.518v19.704C36 37.49 33.471 40 30.357 40H9.643C6.529 40 4 37.49 4 34.389V5.61C4 2.51 6.529 0 9.643 0zm0 3H9.643C8.18 3 7 4.172 7 5.611V34.39C7 35.829 8.18 37 9.643 37h20.714C31.82 37 33 35.828 33 34.389V14.685c0-.145-.058-.285-.163-.389L21.62 3.166C21.514 3.06 21.367 3 21.213 3zM20 13c.205 0 .402.04.58.111l.14.064.086.048.106.07.126.1.069.065 6.435 6.435.107.12c.501.614.466 1.52-.107 2.094-.612.611-1.603.611-2.214 0l-3.764-3.763.001 9.09-.007.152C21.482 28.379 20.814 29 20 29c-.864 0-1.565-.7-1.565-1.565l-.001-9.09-3.762 3.762-.119.107c-.615.502-1.522.466-2.095-.107-.61-.611-.61-1.603 0-2.214l6.435-6.435.115-.103.118-.089.114-.07.138-.068.183-.065c.139-.041.286-.063.439-.063z"
    />
  </svg>
)
