// @flow

import { action, observable, runInAction } from 'mobx'
import { RootStore } from '../RootStore'
import Program from '../Domain/Program'

export default class ProgramSettingsStore {
  +rootStore: RootStore

  @observable programId: ?string = null
  @observable program: Program

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action async fetchProgramByProgramId(programId: string) {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}`,
    )

    runInAction(() => {
      this.programId = programId
      this.program = new Program(
        response.data.programId,
        response.data.label,
        response.data.location,
        response.data.totalNumberOfLots,
        response.data.totalNumberOfParkings,
        response.data.pack,
        response.data.domain,
        [],
        response.data.baseVAT,
        response.data.status,
        response.data.lotsSalesStatus,
        response.data.vatAvailable,
      )
    })
  }

  @action async putOptionsModification() {
    // TODO: need back request
  }
}
