// @flow

import * as React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { Link as MobxLink } from 'mobx-router'
import { StoreContext } from './StoreContext'
import {
  ApplicationContainer,
  ApplicationMainView,
} from './Ui/Guidelines/components/ApplicationLayout'
import NavigationBar, {
  NavigationTop,
  NavigationBottom,
  Icon,
  UserInitial,
} from './Ui/Guidelines/components/NavigationBar'
import DashboardSvg from './Ui/Guidelines/icons/DashboardSvg'
/* import ProgramSvg from './Ui/Guidelines/icons/ProgramSvg'
import ClientsSvg from './Ui/Guidelines/icons/ClientsSvg'
import MetricSvg from './Ui/Guidelines/icons/MetricSvg'
import AlertSvg from './Ui/Guidelines/icons/AlertSvg' */
import DisconnectSvg from './Ui/Guidelines/icons/DisconnectSvg'
import routes from './Routes'

const Link = styled(MobxLink)`
  text-decoration: none;
`

export default observer(({ children }: { children: React.Node }) => {
  const { authenticationStore, router } = React.useContext(StoreContext)

  return (
    <ApplicationContainer>
      <NavigationBar>
        <NavigationTop>
          {/*  <Icon onClick={() => alert('TODO: not implemented yet')}>
              <DashboardSvg />
            </Icon> */}
          <Link route={routes.program_selection_page} router={router}>
            <Icon
              isCurrent={
                routes.program_selection_page.path ===
                router.currentRoute.originalPath
              }
            >
              <DashboardSvg />
            </Icon>
          </Link>
          {/* <Icon onClick={() => alert('TODO: not implemented yet')}>
              <ClientsSvg />
            </Icon>
            <Icon onClick={() => alert('TODO: not implemented yet')}>
              <MetricSvg />
            </Icon> */}
        </NavigationTop>
        <NavigationBottom>
          {/* <Icon onClick={() => alert('TODO: not implemented yet')}>
              <AlertSvg />
            </Icon> */}
          <Link route={routes.user_edition_page} router={router}>
            <UserInitial
              isCurrent={
                routes.user_edition_page.path ===
                router.currentRoute.originalPath
              }
            >
              {authenticationStore.userInitial}
            </UserInitial>
          </Link>
          <Icon onClick={() => authenticationStore.performLogout()}>
            <DisconnectSvg />
          </Icon>
        </NavigationBottom>
      </NavigationBar>

      <ApplicationMainView>{children}</ApplicationMainView>
    </ApplicationContainer>
  )
})
