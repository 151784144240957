// @flow

import React from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import ArrowRightSvg from '../../icons/ArrowRightSvg'
import ArrowLeftSvg from '../../icons/ArrowLeftSvg'
import StarEmptySvg from '../../icons/StarEmptySvg'
import StarFilledSvg from '../../icons/StarFilledSvg'
import { Colors } from '../../Colors'
import { RobotoBold, RobotoMedium } from '../../Typography/Fonts'
import { RightAction, StepHeader, LeftAction } from '../Helper'
import CustomerSvg from '../../icons/CustomerSmallWhiteSvg'
import DashedSeparator from '../../DashedSeparator'
import { type LotLoan } from '../../../../Domain/CommercialOffer'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`

const IconWrapper = styled.div`
  height: 32px;
  margin-bottom: 4px;
`

const Username = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  color: ${Colors.white50};
`

const SubTitle = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.white50};
  margin-bottom: 16px;
`

const FundingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
`

const StarFilled = styled(StarFilledSvg)`
  margin: 0 1px;
  path {
    transition: fill 0.15s ease-in-out, fill-opacity 0.15s ease-in-out;
  }
`

const StarEmpty = styled(StarEmptySvg)`
  margin: 0 1px;
  path {
    transition: stroke 0.15s ease-in-out, stroke-opacity 0.15s ease-in-out;
  }
`

const FundingItem = styled.div`
  cursor: pointer;
  height: 48px;
  width: 72px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 10px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 12px;
  text-align: center;
  color: ${({ isActive, color }) => (isActive ? color : Colors.white30)};
  transition: color 0.15s ease-in-out;

  ${({ isActive }) =>
    isActive &&
    css`
      ${StarFilled} path {
        fill: ${({ color }) => color};
        fill-opacity: 1;
      }
      ${StarEmpty} path {
        stroke: ${({ color }) => color};
        stroke-opacity: 1;
      }
    `}

  &:hover {
    color: ${({ color }) => color};
  }
`

const StarContainer = styled.div`
  margin-bottom: 6px;
`

export default ({
  title,
  stepValue,
  color,
  username,
  onFundingSelected,
  funding,
  goBackClient,
  rightAction,
}: {
  title: string,
  stepValue: string,
  color: string,
  username: string,
  onFundingSelected: (funding: LotLoan) => void,
  funding: LotLoan,
  goBackClient: () => void,
  rightAction: { label: string, method: () => void },
}) => {
  const { t } = useTranslation()

  const [fundingSelected, setFunding] = React.useState(funding)

  const onFundingClick = (funding: LotLoan) => {
    setFunding(funding)
    onFundingSelected(funding)
  }

  return (
    <React.Fragment>
      <StepHeader
        title={title}
        subLabel={t('process.step')}
        subValue={stepValue}
      />
      <Container>
        <IconWrapper>
          <CustomerSvg />
        </IconWrapper>
        <Username>{username}</Username>
        <SubTitle>{t('process.funding')}</SubTitle>
        <FundingWrapper>
          <FundingItem
            color={color}
            onClick={() => onFundingClick('no_informations')}
            isActive={fundingSelected === 'no_informations'}
          >
            <StarContainer>
              <StarEmpty />
              <StarEmpty />
              <StarEmpty />
            </StarContainer>
            <Trans i18nKey="process.noInformations">
              No
              <br />
              informations
            </Trans>
          </FundingItem>
          <FundingItem
            color={color}
            onClick={() => onFundingClick('loan_agreed')}
            isActive={fundingSelected === 'loan_agreed'}
          >
            <StarContainer>
              <StarFilled />
              <StarEmpty />
              <StarEmpty />
            </StarContainer>
            <Trans i18nKey="process.loanAgreed">
              Loan
              <br />
              agreed
            </Trans>
          </FundingItem>
          <FundingItem
            color={color}
            onClick={() => onFundingClick('loan_granted')}
            isActive={fundingSelected === 'loan_granted'}
          >
            <StarContainer>
              <StarFilled />
              <StarFilled />
              <StarEmpty />
            </StarContainer>
            <Trans i18nKey="process.loanGranted">
              Loan
              <br />
              granted
            </Trans>
          </FundingItem>
          <FundingItem
            color={color}
            onClick={() => onFundingClick('cash_purchase')}
            isActive={fundingSelected === 'cash_purchase'}
          >
            <StarContainer>
              <StarFilled />
              <StarFilled />
              <StarFilled />
            </StarContainer>
            <Trans i18nKey="process.cashPurchase">
              Cash
              <br />
              purchase
            </Trans>
          </FundingItem>
        </FundingWrapper>
        <DashedSeparator />
      </Container>
      <LeftAction color={color} onClick={goBackClient}>
        <ArrowLeftSvg /> {t('process.client')}
      </LeftAction>
      <RightAction color={color} onClick={rightAction.method}>
        {rightAction.label} <ArrowRightSvg />
      </RightAction>
    </React.Fragment>
  )
}
