// @flow

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ArrowRightSvg from '../../icons/ArrowRightSvg'
import ArrowLeftSvg from '../../icons/ArrowLeftSvg'
import { TextInputField } from '../Form/InputField'
import { RobotoMedium } from '../../Typography/Fonts'
import {
  RightAction,
  StepHeader,
  LeftAction,
  emailMatch,
  getErrorByField,
} from '../Helper'
import { Colors } from '../../Colors'

type Customer = {
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: ?string,
  zipCode: ?string,
  street: ?string,
  city: ?string,
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Group = styled.div`
  display: flex;
  flex-direction: row;

  div:not(:first-of-type) {
    margin-left: 20px;
  }
`

const ExistingEmailError = styled.div`
  position: absolute;
  top: 13px;
  left: 35px;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.blue};
`

const ErrorClick = styled.span`
  cursor: pointer;
  text-decoration: underline;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 0.75;
  }
`

export default ({
  title,
  stepValue,
  color,
  customers,
  onSubmit,
  errors,
  email,
  firstName,
  lastName,
  phone,
  address,
  zipCode,
  city,
  customerId,
  fetchLoadedCustomer,
  goBackSearch,
}: {|
  title: string,
  stepValue: string,
  color: string,
  customers: Customer[],
  onSubmit: ({
    emailValue: string,
    firstNameValue: string,
    lastNameValue: string,
    phoneValue: string,
    addressValue: string,
    zipCodeValue: string,
    cityValue: string,
  }) => void,
  errors: { field: string, message: string }[],
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  zipCode: string,
  city: string,
  customerId: ?string,
  fetchLoadedCustomer: ?(customerId: string) => void,
  goBackSearch: () => void,
|}) => {
  const loadCustomer = (email: string) => {
    return customers.find(
      customer =>
        customer.email === email && customer.customerId !== customerId,
    )
  }

  const { t } = useTranslation()

  const [emailValue, setEmail] = useState(email)
  const [firstNameValue, setFirstName] = useState(firstName)
  const [lastNameValue, setLastName] = useState(lastName)
  const [phoneValue, setPhone] = useState(phone)
  const [addressValue, setAddress] = useState(address)
  const [zipCodeValue, setZipCode] = useState(zipCode)
  const [cityValue, setCity] = useState(city)
  const [loadedCustomer, setLoadedCustomer] = useState(null)
  const [emailError, setEmailError] = useState(getErrorByField(errors, 'email'))

  useEffect(() => {
    setEmail(email)
  }, [email])
  useEffect(() => {
    setFirstName(firstName)
  }, [firstName])
  useEffect(() => {
    setLastName(lastName)
  }, [lastName])
  useEffect(() => {
    setPhone(phone)
  }, [phone])
  useEffect(() => {
    setAddress(address)
  }, [address])
  useEffect(() => {
    setZipCode(zipCode)
  }, [zipCode])
  useEffect(() => {
    setCity(city)
  }, [city])

  const firstNameError = getErrorByField(errors, 'firstName')
  const lastNameError = getErrorByField(errors, 'lastName')
  const phoneError = getErrorByField(errors, 'phone')
  const addressError = getErrorByField(errors, 'address')
  const zipCodeError = getErrorByField(errors, 'zipCode')
  const cityError = getErrorByField(errors, 'city')

  const emailIsValide = (email: string) => {
    if (emailMatch(email)) {
      setEmailError(null)
    } else {
      setEmailError({ field: 'email', message: t('process.emailInvalid') })
    }
  }

  const disabled =
    emailValue === '' ||
    firstNameValue === '' ||
    lastNameValue === '' ||
    loadedCustomer ||
    emailError

  return (
    <React.Fragment>
      <StepHeader
        title={title}
        subLabel={t('process.step')}
        subValue={stepValue}
      />
      <form
        onSubmit={e => {
          e.preventDefault()
          onSubmit({
            emailValue,
            firstNameValue,
            lastNameValue,
            phoneValue,
            addressValue,
            zipCodeValue,
            cityValue,
          })
        }}
      >
        <Container>
          <TextInputField
            label={t('process.email')}
            value={emailValue}
            onChange={e => {
              setEmail(e.target.value)
              setLoadedCustomer(loadCustomer(e.target.value))
            }}
            onBlur={e => emailIsValide(e.target.value)}
            errorMessage={emailError && emailError.message}
            autoComplete="new-password"
          />

          {loadedCustomer && fetchLoadedCustomer ? (
            <ExistingEmailError>
              | {t('process.existingClient')}{' '}
              <ErrorClick
                onClick={() => {
                  fetchLoadedCustomer(loadedCustomer.customerId)
                  setLoadedCustomer(null)
                }}
              >
                Fill informations ?
              </ErrorClick>
            </ExistingEmailError>
          ) : null}

          <Group>
            <TextInputField
              label={t('process.firstName')}
              value={firstNameValue}
              onChange={e => {
                setFirstName(e.target.value)
              }}
              errorMessage={firstNameError && firstNameError.message}
              autoComplete="new-password"
            />

            <TextInputField
              label={t('process.lastName')}
              value={lastNameValue}
              onChange={e => {
                setLastName(e.target.value)
              }}
              errorMessage={lastNameError && lastNameError.message}
              autoComplete="new-password"
            />
          </Group>

          <TextInputField
            label={t('process.phone')}
            value={phoneValue}
            onChange={e => {
              setPhone(e.target.value)
            }}
            errorMessage={phoneError && phoneError.message}
            autoComplete="new-password"
          />

          <TextInputField
            label={t('process.address')}
            value={addressValue}
            onChange={e => {
              setAddress(e.target.value)
            }}
            errorMessage={addressError && addressError.message}
            autoComplete="new-password"
          />

          <Group>
            <TextInputField
              label={t('process.zipCode')}
              value={zipCodeValue}
              onChange={e => {
                setZipCode(e.target.value)
              }}
              errorMessage={zipCodeError && zipCodeError.message}
              autoComplete="new-password"
            />

            <TextInputField
              label={t('process.city')}
              value={cityValue}
              onChange={e => {
                setCity(e.target.value)
              }}
              errorMessage={cityError && cityError.message}
              autoComplete="new-password"
            />
          </Group>
        </Container>
        <LeftAction color={color} onClick={goBackSearch}>
          <ArrowLeftSvg /> {t('process.search')}
        </LeftAction>
        <RightAction
          isHidden={disabled}
          color={color}
          onClick={() =>
            onSubmit({
              emailValue,
              firstNameValue,
              lastNameValue,
              phoneValue,
              addressValue,
              zipCodeValue,
              cityValue,
            })
          }
        >
          {t('process.funding')} <ArrowRightSvg />
        </RightAction>
      </form>
    </React.Fragment>
  )
}
