// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#FFF"
      fillOpacity=".1"
      d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm3.757 4.243c-.324-.324-.85-.324-1.174 0L8 6.826 5.417 4.243c-.324-.324-.85-.324-1.174 0-.324.324-.324.85 0 1.174L6.826 8l-2.583 2.583c-.324.324-.324.85 0 1.174.324.324.85.324 1.174 0L8 9.174l2.583 2.583c.324.324.85.324 1.174 0 .324-.324.324-.85 0-1.174L9.174 8l2.583-2.583c.324-.324.324-.85 0-1.174z"
    />
  </svg>
)
