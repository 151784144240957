// @flow

import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../Colors'
import CheckSvg from '../../icons/CheckSvg'

const VatEmpty = styled.div`
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 4px;

  opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  background-color: ${({ isInvalid, lotIsSold }) =>
    lotIsSold ? Colors.green75 : isInvalid ? Colors.red : Colors.white10};
  transition: background-color 0.15s ease-in-out, opacity 0.15s ease-in-out;
`

const VatChecked = styled.div`
  position: absolute;
  transition: opacity 0.15s ease-in-out, fill 0.15s ease-in-out;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};

  svg {
    width: 14px;
    height: 14px;
  }

  path {
    transition: fill 0.15s ease-in-out;
    fill: ${({ lotIsUnvailable, lotIsSold }) =>
      lotIsUnvailable
        ? Colors.white20
        : lotIsSold
        ? Colors.green75
        : Colors.white50};
  }
`

const VatWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 24px;
  width: 24px;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export default ({
  onClick,
  isActive,
  vatAvailable,
  lotIsUnvailable,
  lotIsSold,
}: {
  onClick: Function,
  isActive: boolean,
  vatAvailable: number[],
  lotIsUnvailable: boolean,
  lotIsSold: boolean,
}) => (
  <VatWrapper disabled={lotIsSold} onClick={onClick}>
    <VatEmpty
      isActive={isActive}
      isInvalid={vatAvailable.length === 0}
      lotIsSold={lotIsSold}
    />
    <VatChecked
      isActive={isActive}
      lotIsSold={lotIsSold}
      lotIsUnvailable={lotIsUnvailable}
      className="trHoverPathWhite50"
    >
      <CheckSvg />
    </VatChecked>
  </VatWrapper>
)
