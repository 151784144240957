// @flow

import * as React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'
import ArrowDownSvg from '../icons/ArrowDownSmallSvg'
import Taxes from '../Typography/Taxes'

const Wrapper = styled.div`
  position: relative;
  width: max-content;
`

const Label = styled.div`
  width: max-content;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.white30};
  transition: color 0.25s ease-in-out;
`

const SelectContainer = styled.div`
  cursor: pointer;
  pointer-events: ${({ isOpen }) => (isOpen ? 'none' : 'all')};
  border-radius: 4px;
  padding: 1px 4px;
  display: flex;
  align-items: center;
  width: min-content;
  background-color: ${Colors.carbon};
  border: solid 1px ${Colors.white10};

  path {
    transition: fill-opacity 0.25s ease-in-out;
    fill-opacity: ${({ isOpen }) => (isOpen ? 1 : 0.3)};
  }
  ${Label} {
    ${({ isOpen }) => isOpen && { color: Colors.white100 }};
  }
  &:hover {
    path {
      fill-opacity: 0.5;
    }
    ${Label} {
      color: ${Colors.white50};
    }
  }
`

const ChoicesContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${Colors.black50};
  border: solid 1px ${Colors.black10};
  background-color: ${Colors.carbon};
  padding: 10px 15px;
  min-width: 100%;
  position: absolute;
  z-index: 1;
  top: 22px;
  right: 0px;
`

const ChoiceRadio = styled.div`
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? Colors.blue : Colors.white10};
  transition: background-color 0.25s ease-in-out, width 0.25s ease-in-out,
    height 0.25s ease-in-out, margin 0.25s ease-in-out;
`

const ChoiceLabel = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  font-size: 12px;
  margin-left: 4px;
  color: ${({ isActive }) => (isActive ? Colors.white50 : Colors.white30)};
  transition: color 0.25s ease-in-out;
`

const Choice = styled.div`
  cursor: pointer;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 5px 0;
  &:hover {
    ${ChoiceRadio} {
      margin: 1px;
      width: 12px;
      height: 12px;
    }
    ${ChoiceLabel} {
      color: ${Colors.white50};
    }
  }
`

type Props = {|
  vatList: number[],
  onVatClick: (value: ?number) => void,
  value: number,
  t: string => string,
|}

type State = {
  isOpen: boolean,
}

class SelectVat extends React.Component<Props, State> {
  _wrapperRef: any

  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      this.toggleDropdown()
    }
  }

  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { vatList, value, onVatClick, t } = this.props
    const { isOpen } = this.state

    return (
      <Wrapper>
        <SelectContainer isOpen={isOpen} onClick={() => this.toggleDropdown()}>
          <Label>{value ? <Taxes amount={value} /> : t('allVat')}</Label>
          <ArrowDownSvg />
        </SelectContainer>

        {isOpen ? (
          <ChoicesContainer ref={node => (this._wrapperRef = node)}>
            <Choice
              onClick={() => {
                onVatClick(null)
                this.toggleDropdown()
              }}
            >
              <ChoiceRadio isActive={!value} />
              <ChoiceLabel isActive={!value}>{t('allVat')}</ChoiceLabel>
            </Choice>
            {vatList.map(oneVat => {
              const isActive = oneVat === value

              return (
                <Choice
                  onClick={() => {
                    onVatClick(oneVat)
                    this.toggleDropdown()
                  }}
                  key={oneVat}
                >
                  <ChoiceRadio isActive={isActive} />
                  <ChoiceLabel isActive={isActive}>
                    <Taxes amount={oneVat} />
                  </ChoiceLabel>
                </Choice>
              )
            })}
          </ChoicesContainer>
        ) : null}
      </Wrapper>
    )
  }
}

export default withTranslation()(SelectVat)
