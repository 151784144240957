// @flow

import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../Colors'
import DashedSeparator from '../DashedSeparator'
import { TextNormal, ModalHeader } from './Helper'
import { RobotoMedium } from '../Typography/Fonts'
import ExportSvg from '../icons/ExportSvg'

type ProgramProspects = {
  programName: string,
  status: string,
  href: string,
  total: number,
  delta: number,
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`

const ProgramLabel = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${({ isActive }) => (isActive ? Colors.white100 : Colors.white50)};
  margin-bottom: 2px;
`

const ExportIcon = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  svg {
    width: 100%;
    height: 100%;
  }
  path {
    transition: fill-opacity 0.25s ease-in-out;
  }
  &:hover path {
    fill-opacity: 1;
  }
`

export default ({
  programsProspects,
  requestDownloadXls,
}: {|
  programsProspects: ProgramProspects[],
  requestDownloadXls: (apiUrl: string, programName: string) => Promise<void>,
|}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <ModalHeader
        subLabel={t('programs.program_plural')}
        title={t('programs.prospects')}
      />
      {programsProspects.map((oneProgramProspects, index) => (
        <React.Fragment key={index}>
          <Container>
            <div>
              <ProgramLabel isActive={oneProgramProspects.status === 'active'}>
                {oneProgramProspects.programName}
              </ProgramLabel>
              <TextNormal style={{ textTransform: 'lowercase' }}>
                {oneProgramProspects.total} {t('programs.prospects')}{' '}
                {oneProgramProspects.delta > 1 && (
                  <span style={{ color: Colors.blue }}>
                    (+{oneProgramProspects.delta})
                  </span>
                )}
              </TextNormal>
            </div>
            <ExportIcon
              onClick={() =>
                requestDownloadXls(
                  oneProgramProspects.href,
                  oneProgramProspects.programName,
                )
              }
            >
              <ExportSvg />
            </ExportIcon>
          </Container>
          {index !== programsProspects.length - 1 && <DashedSeparator />}
        </React.Fragment>
      ))}
    </React.Fragment>
  )
}
