// @flow

import styled from 'styled-components'
import { Link as MobxRouterLink } from 'mobx-router'
import { Colors } from '../Colors'

const linkStyles = ({ accented }) => `
  &,
  &:focus {
    text-decoration: underline;
    color: ${accented ? Colors.blue : Colors.white30};
    transition: color ease-in-out 0.15s;
    cursor: pointer;
  }

  &:hover {
    color: ${Colors.blue};
    text-decoration: ${accented ? 'none' : 'underline'};
  }
`

export const Link = styled(MobxRouterLink)(props => linkStyles(props))

export const FakeLink = styled.span(props => linkStyles(props))

export const NativeLink = styled.a(props => linkStyles(props))

export const BlockLink = styled(Link)`
  &,
  &:focus,
  &:hover {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
    transition: none;
    cursor: pointer;
  }
`
