// @flow

import { observable } from 'mobx'

export type LotStatus =
  | 'unavailable'
  | 'available'
  | 'optioned'
  | 'booked'
  | 'sold'

export type LotObject = {|
  id: string,
  label: string,
  status: string,
  priceExcludingVAT: number,
  vatAvailable: number[],
  hidePrice: boolean,
  parkings: ?({ id: string, label: string }[]),
|}

export default class LotPrice {
  +id: string;
  +status: LotStatus;
  +label: string;
  +parkings: ?Object;
  +pricesIncludingVAT: {
    amountIncludingVAT: number,
    vatValueInPercent: number,
  }[]

  @observable hidePrice: boolean = false
  @observable priceExcludingVAT: number = 0
  @observable vatAvailable: number[] = []

  constructor(
    id: string,
    status: LotStatus,
    label: string,
    pricesIncludingVAT: {
      amountIncludingVAT: number,
      vatValueInPercent: number,
    }[],
    priceExcludingVAT: number,
    vatAvailable: number[],
    hidePrice: boolean,
    parkings: ?Object,
  ) {
    this.id = id
    this.status = status
    this.label = label
    this.pricesIncludingVAT = pricesIncludingVAT
    this.priceExcludingVAT = priceExcludingVAT
    this.vatAvailable = vatAvailable
    this.hidePrice = hidePrice
    this.parkings = parkings
  }

  updateLot(lot: LotObject) {
    if (this.id === lot.id) {
      this.priceExcludingVAT = lot.priceExcludingVAT
      this.vatAvailable = lot.vatAvailable
      this.hidePrice = lot.hidePrice
    }
  }

  is(lookupId: string): boolean {
    return this.id === lookupId
  }
}
