// @flow

import styled from 'styled-components'
import { RobotoBold, RobotoExtraBold } from './../Typography/Fonts'
import { Colors } from '../Colors'

export const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: max-content;
  margin: auto;
  height: 38px;
  border-radius: 24px;
  padding: 10px 20px;
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  transition: box-shadow 0.15s ease-in-out;
  color: ${Colors.white50};
  background-color: ${Colors.dark1};

  &:hover {
    box-shadow: 0 0 12px 0 ${Colors.black50};
  }

  &:disabled {
    pointer-events: none;
    background-color: ${Colors.brown2};
    color: ${Colors.white10};
  }
`

export const ButtonBlue = styled(Button)`
  color: ${Colors.brown1};
  background-color: ${Colors.blue};
`

export const ButtonValideStatus = styled(Button)`
  color: ${Colors.brown1};
  background-color: ${({ color }) => color};
  transition: background-color 0.15s ease-in-out;

  path {
    fill: ${Colors.brown1};
    fill-opacity: 1;
  }

  &:hover {
    background-color: ${({ colorHover }) => colorHover};
  }
`

export const SmallButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: max-content;
  margin: auto;
  height: 16px;
  border-radius: 3px;
  padding: 2px 4px;
  font-family: ${RobotoExtraBold.fontFamily};
  font-size: 9px;
  font-weight: ${RobotoExtraBold.fontWeight};
  line-height: 12px;
  color: ${Colors.brown1};
  background-color: ${Colors.white30};
  text-transform: uppercase;

  &:disabled {
    pointer-events: none;
    background-color: ${Colors.white5};
    color: ${Colors.white5};
  }
`

export const SmallButtonBlue = styled(SmallButton)`
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${Colors.blue};
  }
`

export const SmallButtonYellow = styled(SmallButton)`
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: ${Colors.yellow};
  }
`
