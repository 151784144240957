// @flow
import * as React from 'react'
import { createGlobalStyle } from 'styled-components'
import { DropTarget, ConnectDropTarget } from 'react-dnd'
import ColumnCard from './ColumnCard'
import ItemTypes from './ItemTypes'

const CursorGrabbing = createGlobalStyle`
  html, body {
    cursor: move; 
    user-select: none;
  }
`

const cardTarget = {
  canDrop(props, monitor) {
    const { toColumn } = monitor.getItem()
    return toColumn.includes(props.columnStatus)
  },

  drop(props, monitor, component) {
    const { lotId, lotStatus } = monitor.getItem()
    const { onDrop, columnStatus } = props
    onDrop(lotId, columnStatus, lotStatus)
  },
}

const collect = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType(),
    item: monitor.getItem(),
  }
}

type Props = {|
  isOver: boolean,
  canDrop: boolean,
  connectDropTarget: typeof ConnectDropTarget,
  children: React.Node,
  columnStatus: string,
  collapsable: boolean,
  isCollapsed: boolean,
  count: number,
  subCount: number,
  item: Object,
  setCollapsed: () => void,
  setDisplayedVat: () => void,
  displayedVat: number,
  vatList: number[],
|}

const DroppableColumn = ({
  isOver,
  canDrop,
  connectDropTarget,
  children,
  columnStatus,
  collapsable,
  isCollapsed,
  setCollapsed,
  count,
  subCount,
  item,
  displayedVat,
  setDisplayedVat,
  vatList,
}: Props) => {
  const isDragging = !!item
  return connectDropTarget(
    <div
      style={{
        position: 'relative',
        height: collapsable
          ? isDragging
            ? 'calc(50% - 4px)'
            : isCollapsed
            ? '40px'
            : 'calc(100% - 48px)'
          : '100%',
        transition: 'height 0.25s ease-in-out',
      }}
      data-test-id={`droppable-column-${columnStatus}`}
    >
      {isDragging ? <CursorGrabbing /> : null}
      <ColumnCard
        collapsable={collapsable}
        columnStatus={columnStatus}
        count={count}
        subCount={subCount}
        collapsed={isCollapsed}
        setCollapse={setCollapsed}
        canDrop={canDrop}
        isOver={isOver}
        isDragging={isDragging}
        displayedVat={displayedVat}
        setDisplayedVat={setDisplayedVat}
        vatList={vatList}
      >
        {children}
      </ColumnCard>
    </div>,
  )
}

export default DropTarget(
  ItemTypes.LOT_CARD,
  cardTarget,
  collect,
)(DroppableColumn)
