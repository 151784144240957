// @flow

import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../Colors'
import CheckSvg from '../icons/CheckSvg'
import { RobotoBold, RobotoMedium } from '../Typography/Fonts'
import { getStatusColor } from './Helper'

const rotateAnimation = to => keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(${to});
  }
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const Center = styled.div`
  background: ${Colors.dark2};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 80px;
  height: 80px;
  margin: auto;
  border-radius: 50%;
  box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.25);
  color: ${({ color, isInvisible }) => (isInvisible ? 'transparent' : color)};
  transition: color 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;

  span {
    font-family: ${RobotoMedium.fontFamily};
    font-size: 10px;
    font-weight: ${RobotoMedium.fontWeight};
    line-height: 12px;
  }

  svg {
    width: 32px;
    height: 32px;
    path {
      fill: ${Colors.green};
    }
  }
`

const Svg = styled.svg`
  position: absolute;
  top: -26px;
  left: -26px;
`

const Donut = styled.div`
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
`

const Circle = styled.div`
  border-radius: 50%;
  clip: rect(0px, 80px, 160px, 0px);
  height: 100%;
  position: absolute;
  width: 100%;
  pointer-events: auto;
`

const Portion = styled.div`
  border-radius: 50%;
  clip: rect(0px, 160px, 160px, 80px);
  height: 100%;
  position: absolute;
  width: 100%;
  pointer-events: none;

  transform: rotate(${props => props.from});
  ${Circle} {
    background-color: ${({ color }) => color};
    animation: ${({ to }) => rotateAnimation(to)} linear
      ${({ duration }) => duration} 1 forwards ${({ delay }) => delay};
  }
`

const Transparency = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 110px;
  height: 110px;
  margin: auto;
  border-radius: 50%;
  background-color: ${Colors.black10};
  border: solid 1px ${Colors.white20};
`

export default ({
  estate,
  unavailable,
  available,
  optioned,
  booked,
  sold,
}: {|
  estate: number,
  unavailable: number,
  available: number,
  optioned: number,
  booked: number,
  sold: number,
|}) => {
  let soldPart2, bookedPart2, optionedPart2, availablePart2, unavailablePart2
  const soldDeg = (sold * 360) / estate
  const bookedDeg = (booked * 360) / estate
  const optionedDeg = (optioned * 360) / estate
  const availableDeg = (available * 360) / estate
  const unavailableDeg = (unavailable * 360) / estate

  if (soldDeg > 180) {
    soldPart2 = soldDeg - 180
  }

  if (bookedDeg > 180) {
    bookedPart2 = bookedDeg - 180
  }

  if (optionedDeg > 180) {
    optionedPart2 = optionedDeg - 180
  }

  if (availableDeg > 180) {
    availablePart2 = availableDeg - 180
  }

  if (unavailableDeg > 180) {
    unavailablePart2 = unavailableDeg - 180
  }

  const soldDuration = sold / estate
  const bookedDuration = booked / estate
  const optionedDuration = optioned / estate
  const availableDuration = available / estate
  const unavailableDuration = unavailable / estate

  const [statusHovered, setStatusHovered] = React.useState(null)
  const [percentHovered, setPercentHovered] = React.useState(null)

  const handleMouseEnter = (status: string, lotNumber: number) => {
    setStatusHovered(status)
    setPercentHovered(Math.round((lotNumber * 100) / estate))
  }

  const handleMouseOut = () => {
    setStatusHovered(null)
    setPercentHovered(null)
  }

  const { t } = useTranslation()

  return (
    <Wrapper>
      <Donut>
        <Svg width="212" height="212" viewBox="0 0 160 160">
          <defs>
            <path
              id="criclePath"
              d=" M 80, 80 m -64, 0 a 64,64 0 0,1 128,0 a 64,64 0 0,1 -128,0 "
            />
          </defs>
        </Svg>
        <Portion
          from="0deg"
          to={`${soldPart2 ? 180 : soldDeg}deg`}
          duration={`${soldPart2 ? 0.5 : soldDuration}s`}
          color={Colors.green}
          delay="0s"
        >
          <Circle
            onMouseEnter={() => handleMouseEnter('sold', sold)}
            onMouseOut={() => handleMouseOut()}
          />
        </Portion>
        {soldPart2 && (
          <Portion
            className="soldPortion"
            from="180deg"
            to={`${soldPart2}deg`}
            duration={`${soldDuration - 0.5}s`}
            color={Colors.green}
            delay="0.5s"
          >
            <Circle
              onMouseEnter={() => handleMouseEnter('sold', sold)}
              onMouseOut={() => handleMouseOut()}
            />
          </Portion>
        )}

        <Portion
          from={`${soldDeg}deg`}
          to={`${bookedPart2 ? 180 : bookedDeg}deg`}
          duration={`${bookedPart2 ? 0.5 : bookedDuration}s`}
          color={Colors.yellow}
          delay={`${soldDuration}s`}
        >
          <Circle
            onMouseEnter={() => handleMouseEnter('booked', booked)}
            onMouseOut={() => handleMouseOut()}
          />
        </Portion>
        {bookedPart2 && (
          <Portion
            from={`${soldDeg + (bookedPart2 === 180 ? 180 : 179)}deg`}
            to={`${bookedPart2 + (bookedPart2 === 180 ? 0 : 1)}deg`}
            duration={`${bookedDuration - 0.5}s`}
            color={Colors.yellow}
            delay={`${soldDuration + 0.5}s`}
          >
            <Circle
              onMouseEnter={() => handleMouseEnter('booked', booked)}
              onMouseOut={() => handleMouseOut()}
            />
          </Portion>
        )}

        <Portion
          from={`${soldDeg + bookedDeg}deg`}
          to={`${optionedPart2 ? 180 : optionedDeg}deg`}
          duration={`${optionedPart2 ? 0.5 : optionedDuration}s`}
          color={Colors.pink}
          delay={`${soldDuration + bookedDuration}s`}
        >
          <Circle
            onMouseEnter={() => handleMouseEnter('optioned', optioned)}
            onMouseOut={() => handleMouseOut()}
          />
        </Portion>
        {optionedPart2 && (
          <Portion
            from={`${
              soldDeg + bookedDeg + (optionedPart2 === 180 ? 180 : 179)
            }deg`}
            to={`${optionedPart2 + (optionedPart2 === 180 ? 0 : 1)}deg`}
            duration={`${optionedDuration - 0.5}s`}
            color={Colors.pink}
            delay={`${soldDuration + bookedDuration + 0.5}s`}
          >
            <Circle
              onMouseEnter={() => handleMouseEnter('optioned', optioned)}
              onMouseOut={() => handleMouseOut()}
            />
          </Portion>
        )}

        <Portion
          from={`${soldDeg + bookedDeg + optionedDeg}deg`}
          to={`${availablePart2 ? 180 : availableDeg}deg`}
          duration={`${availablePart2 ? 0.5 : availableDuration}s`}
          color={Colors.blue}
          delay={`${soldDuration + bookedDuration + optionedDuration}s`}
        >
          <Circle
            onMouseEnter={() => handleMouseEnter('available', available)}
            onMouseOut={() => handleMouseOut()}
          />
        </Portion>
        {availablePart2 && (
          <Portion
            from={`${
              soldDeg +
              bookedDeg +
              optionedDeg +
              (availablePart2 === 180 ? 180 : 179)
            }deg`}
            to={`${availablePart2 + (availablePart2 === 180 ? 0 : 1)}deg`}
            duration={`${availableDuration - 0.5}s`}
            color={Colors.blue}
            delay={`${soldDuration + bookedDuration + optionedDuration + 0.5}s`}
          >
            <Circle
              onMouseEnter={() => handleMouseEnter('available', available)}
              onMouseOut={() => handleMouseOut()}
            />
          </Portion>
        )}

        <Portion
          from={`${soldDeg + bookedDeg + optionedDeg + availableDeg}deg`}
          to={`${unavailablePart2 ? 180 : unavailableDeg}deg`}
          duration={`${unavailablePart2 ? 0.5 : unavailableDuration}s`}
          color={Colors.grey}
          delay={`${
            soldDuration + bookedDuration + optionedDuration + availableDuration
          }s`}
        >
          <Circle
            onMouseEnter={() => handleMouseEnter('unavailable', unavailable)}
            onMouseOut={() => handleMouseOut()}
          />
        </Portion>
        {unavailablePart2 && (
          <Portion
            from={`${
              soldDeg +
              bookedDeg +
              optionedDeg +
              availableDeg +
              (unavailablePart2 === 180 ? 180 : 179)
            }deg`}
            to={`${unavailablePart2 + (unavailablePart2 === 180 ? 0 : 1)}deg`}
            duration={`${unavailableDuration - 0.5}s`}
            color={Colors.grey}
            delay={`${
              soldDuration +
              bookedDuration +
              optionedDuration +
              availableDuration +
              0.5
            }s`}
          >
            <Circle
              onMouseEnter={() => handleMouseEnter('unavailable', unavailable)}
              onMouseOut={() => handleMouseOut()}
            />
          </Portion>
        )}

        <Transparency />
        {sold === estate ? (
          <Center>
            <CheckSvg />
          </Center>
        ) : (
          <Center
            color={getStatusColor(statusHovered)}
            isInvisible={!percentHovered || !statusHovered}
          >
            {percentHovered && `${percentHovered}%`}
            {statusHovered && <span>{t(`status.${statusHovered}`)}</span>}
          </Center>
        )}
      </Donut>
    </Wrapper>
  )
}
