// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 64 64"
  >
    <g fill="none">
      <path
        fill="#323232"
        d="M32 1c11.598 0 21 8.907 21 19.895 0 6.573-3.365 12.4-8.55 16.023C53.665 41.26 60 50.271 60 60.684 60 62.515 58.433 64 56.5 64S53 62.515 53 60.684c0-10.987-9.402-19.895-21-19.895l-.358.003C20.209 40.973 11 49.81 11 60.684 11 62.515 9.433 64 7.5 64S4 62.515 4 60.684c0-10.413 6.334-19.425 15.55-23.766-5.185-3.622-8.55-9.45-8.55-16.023C11 9.907 20.402 1 32 1zm0 6.632c-7.732 0-14 5.938-14 13.263s6.268 13.263 14 13.263 14-5.938 14-13.263S39.732 7.632 32 7.632z"
      />
      <path
        fill="#181818"
        d="M32 0c11.598 0 21 8.907 21 19.895 0 6.573-3.365 12.4-8.55 16.023C53.665 40.26 60 49.271 60 59.684 60 61.515 58.433 63 56.5 63S53 61.515 53 59.684c0-10.987-9.402-19.895-21-19.895l-.358.003C20.209 39.973 11 48.81 11 59.684 11 61.515 9.433 63 7.5 63S4 61.515 4 59.684c0-10.413 6.334-19.425 15.55-23.766-5.185-3.622-8.55-9.45-8.55-16.023C11 8.907 20.402 0 32 0zm0 6.632c-7.732 0-14 5.938-14 13.263s6.268 13.263 14 13.263 14-5.938 14-13.263S39.732 6.632 32 6.632z"
      />
    </g>
  </svg>
)
