// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './Guidelines/Colors'
import { NativeLink } from './Guidelines/Typography/Link'
import { TextBold } from './Guidelines/components/Helper'
import { useTranslation } from 'react-i18next'
import CusthomePictoSvg from './Guidelines/icons/CusthomePictoSvg'

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  svg {
    width: 64px;
  }
`

export default () => {
  const { t } = useTranslation()

  return (
    <LoaderContainer>
      <CusthomePictoSvg color={Colors.blue} />
      <TextBold>{t('pageNotFound')}</TextBold>
      <br />
      <NativeLink href="/">{t('backToHome')}</NativeLink>
    </LoaderContainer>
  )
}
