// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill="#fff"
      fillOpacity=".3"
      d="M7 0c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zm0 1.05C3.714 1.05 1.05 3.714 1.05 7S3.714 12.95 7 12.95s5.95-2.664 5.95-5.95S10.286 1.05 7 1.05zm1.072 3.268l.039.036 2.238 2.275.02.022.029.035.027.041.02.036c.035.071.055.152.055.237 0 .068-.013.133-.036.193l-.023.051-.016.029-.027.04-.021.027-.028.031L8.11 9.646c-.202.205-.529.205-.73 0-.19-.192-.201-.496-.036-.702l.036-.04 1.356-1.379h-4.72C3.73 7.525 3.5 7.29 3.5 7c0-.273.205-.497.467-.523l.05-.002h4.72L7.381 5.096c-.202-.205-.202-.537 0-.742.189-.192.488-.204.69-.036z"
    />
  </svg>
)
