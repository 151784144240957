// @flow
import * as React from 'react'
import styled, { css } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Colors } from '../../Colors'
import SearchSvg from '../../icons/SearchSvg'
import { RobotoMedium } from '../../Typography/Fonts'
import RemoveSearchSvg from '../../icons/RemoveSvg'
import AddNewSvg from '../../icons/AddNewSvg'
import DashedSeparator from '../../DashedSeparator'
import NoResultSvg from '../../icons/NoResultSvg'
import CustomerSvg from '../../icons/CustomerSvg'
import ParkingLinkSvg from '../../icons/ParkingLinkSvg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
  width: 100%;
  margin-top: 20px;
`

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`

const TextStyle = css`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${Colors.white100};
`

const SearchIcon = styled.div`
  height: 16px;
  margin-right: 4px;

  path {
    fill: ${({ color }) => color};
  }
`

const RemoveSearchIcon = styled.div`
  height: 16px;
  margin-right: 4px;
  cursor: pointer;

  path {
    transition: fill-opacity 0.15s ease-in-out;
  }

  &:hover path {
    fill-opacity: 1;
  }
`

const SearchInput = styled.input`
  background-color: transparent;
  width: 100%;
  padding: 0;
  ${TextStyle};

  &::placeholder {
    color: ${Colors.white30};
  }
`

const SuggestionsContainer = styled.div`
  height: 100%;
  overflow: auto;
`

const EmptySearchIcon = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;

  svg {
    height: 64px;
    width: 64px;
    margin-bottom: 40px;
  }
`

const NoResultWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  color: ${Colors.white30};

  svg {
    margin-bottom: 40px;
  }
`

const Suggestion = styled.div`
  cursor: pointer;
  ${TextStyle};
  padding-top: 10px;
  color: ${Colors.white50};
  transition: color 0.15s ease-in-out;

  &:hover {
    color: ${({ color }) => color};
  }
`

const Separator = styled(DashedSeparator)`
  margin-top: 10px;
`

const AddNewWrapper = styled.div`
  cursor: pointer;
  ${TextStyle};
  color: ${Colors.white30};
  margin-left: 4px;
  display: flex;
  align-items: center;
  transition: color 0.15s ease-in-out;
  svg {
    margin-left: 4px;
  }
  path {
    fill: ${({ color }) => color};
  }
  &:hover {
    color: ${({ color }) => color};
  }
`

const ResultsInRow = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  margin: 10px 0 0 -18px;
  width: calc(100% + 18px);
  overflow: auto;

  ${Suggestion} {
    padding-top: 0;
    margin: 10px 0 10px 18px;
  }
`

type Props = {|
  valueId: string,
  values: Array<Object>,
  placeholder: string,
  searchableFields: string[],
  renderSuggestion: (suggestion: Object) => React.Node,
  onSuggestionSelected: (suggestion: Object) => void,
  addNew?: () => void,
  setActionVisibility?: boolean => void,
  displaySuggestionsInRow?: boolean,
  color: string,
  t: string => string,
|}

type State = {|
  value: string,
  suggestions: Array<Object>,
|}

class AutocompleteField extends React.Component<Props, State> {
  constructor() {
    super()

    this.state = {
      value: '',
      suggestions: [],
    }
  }

  getSuggestions = (value: string): Array<Object> => {
    const { values, searchableFields, valueId } = this.props
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    const splitedValue = inputValue.split(' ')

    return inputLength === 0
      ? []
      : values.reduce((acc, elem) => {
          searchableFields.forEach(field => {
            splitedValue.forEach(split => {
              if (
                elem[field] &&
                elem[field].toLowerCase().includes(split) &&
                !acc.some(item => item[valueId] === elem[valueId])
              ) {
                acc.push(elem)
              }
            })
          })
          return acc
        }, [])
  }

  onChange = (newValue: string) => {
    const { setActionVisibility } = this.props
    if (newValue.length === 0) {
      setActionVisibility && setActionVisibility(true)
    } else {
      setActionVisibility && setActionVisibility(false)
    }

    this.setState({
      value: newValue,
      suggestions: this.getSuggestions(newValue),
    })
  }

  onSuggestionsClearRequested = () => {
    const { setActionVisibility } = this.props
    const inputSearch = document.getElementById('inputSearch')
    if (inputSearch) {
      inputSearch.focus()
    }
    setActionVisibility && setActionVisibility(true)
    this.setState({
      suggestions: [],
      value: '',
    })
  }

  render() {
    const { value, suggestions } = this.state
    const {
      placeholder,
      renderSuggestion,
      onSuggestionSelected,
      addNew,
      displaySuggestionsInRow,
      color,
      t,
    } = this.props

    return (
      <Wrapper>
        <InputContainer>
          {value ? (
            <RemoveSearchIcon
              onClick={() => this.onSuggestionsClearRequested()}
            >
              <RemoveSearchSvg />
            </RemoveSearchIcon>
          ) : (
            <SearchIcon color={color}>
              <SearchSvg />
            </SearchIcon>
          )}
          <SearchInput
            id="inputSearch"
            type="text"
            autoFocus
            placeholder={placeholder}
            value={value}
            onChange={e => this.onChange(e.target.value)}
            autoComplete="off"
          />
          {addNew && (
            <AddNewWrapper color={color} onClick={() => addNew()}>
              {t('new')}
              <AddNewSvg />
            </AddNewWrapper>
          )}
        </InputContainer>
        <Separator />
        <SuggestionsContainer>
          {suggestions.length > 0 ? (
            displaySuggestionsInRow ? (
              <ResultsInRow>
                {suggestions.map((suggestion, index) => (
                  <Suggestion
                    key={index}
                    onClick={() => onSuggestionSelected(suggestion)}
                    color={color}
                  >
                    {renderSuggestion(suggestion)}
                  </Suggestion>
                ))}
              </ResultsInRow>
            ) : (
              suggestions.map((suggestion, index) => (
                <Suggestion
                  key={index}
                  onClick={() => onSuggestionSelected(suggestion)}
                  color={color}
                >
                  {renderSuggestion(suggestion)}
                  {index !== suggestions.length - 1 && <Separator />}
                </Suggestion>
              ))
            )
          ) : value ? (
            <NoResultWrapper>
              <NoResultSvg />
            </NoResultWrapper>
          ) : (
            <EmptySearchIcon>
              {displaySuggestionsInRow ? <ParkingLinkSvg /> : <CustomerSvg />}
            </EmptySearchIcon>
          )}
        </SuggestionsContainer>
      </Wrapper>
    )
  }
}

export default withTranslation()(AutocompleteField)
