// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14"
    height="14"
    viewBox="0 0 14 14"
  >
    <path
      fill="#FFD050"
      fillRule="evenodd"
      d="M7.5 0c.552 0 1 .448 1 1v4.5H13c.552 0 1 .448 1 1v1c0 .552-.448 1-1 1H8.5V13c0 .552-.448 1-1 1h-1c-.552 0-1-.448-1-1V8.5H1c-.552 0-1-.448-1-1v-1c0-.552.448-1 1-1h4.5V1c0-.552.448-1 1-1h1z"
    />
  </svg>
)
