// @flow

import * as React from 'react'

export default ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="8"
    height="8"
    viewBox="0 0 8 8"
    className={className}
  >
    <path
      fill="#2F2F2F"
      fillRule="evenodd"
      d="M1.959 7.957c.142-.084.3-.159.458-.234.433-.216.958-.458 1.583-.85.625.392 1.15.634 1.583.85.159.075.317.15.459.234.058.025.116.041.183.041.075 0 .15-.025.216-.066.125-.084.192-.217.184-.367-.042-.575-.192-1.391-.417-2.308.825-.7 1.483-1.408 1.741-1.883.059-.1.067-.225.025-.333-.033-.1-.116-.192-.216-.233-.4-.175-1.45-.242-2.358-.275C5.117 1.708 4.658.492 4.275.117 4.2.042 4.1 0 4 0c-.1 0-.2.042-.283.117-.375.375-.833 1.591-1.116 2.416-.909.033-1.958.1-2.358.275-.109.041-.184.133-.217.233-.042.108-.033.233.025.333.258.475.917 1.183 1.741 1.883-.225.917-.375 1.733-.416 2.308-.009.15.058.283.183.367.117.083.275.091.4.025z"
    />
  </svg>
)
