// @flow

import * as React from 'react'

export default ({ newProspects = false }: { newProspects: boolean }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        fillOpacity=".3"
        d="M29.449 26.845c2.761-.073 3.912-.243 3.958-.267.184-.025.368-.146.483-.316.092-.17.138-.364.092-.582-.322-1.602-1.312-2.937-2.715-3.835-.345-.219-.76-.389-1.22-.583-.782-.34-1.68-.704-1.84-1.262-.139-.461.206-1.117.529-1.602 1.495-2.451 1.748-4.563.759-6.286C28.735 10.777 27.4 10 25.882 10c-1.357 0-2.094.631-2.876 1.699 1.104.534 1.795 1.286 2.439 2.427.943 1.626 1.335 4.466-.943 8.277.138.048.276.121.368.17.506.194 1.012.412 1.495.704 1.381.898 2.44 2.136 3.084 3.568zm-18.898 0c-2.761-.073-3.912-.243-3.958-.267-.184-.025-.368-.146-.483-.316-.092-.17-.138-.364-.092-.582.322-1.602 1.312-2.937 2.715-3.835.345-.219.76-.389 1.22-.583.782-.34 1.68-.704 1.84-1.262.139-.461-.206-1.117-.529-1.602-1.495-2.451-1.748-4.563-.759-6.286C11.265 10.777 12.6 10 14.118 10c1.357 0 2.094.631 2.876 1.699-1.104.534-1.795 1.286-2.439 2.427-.943 1.626-1.335 4.466.943 8.277-.138.048-.276.121-.368.17-.506.194-1.012.412-1.495.704-1.381.898-2.44 2.136-3.084 3.568zm16.988 2.84c.207-.025.368-.146.483-.316.115-.17.161-.364.115-.583-.345-1.602-1.334-2.936-2.715-3.835-.368-.218-.782-.388-1.22-.582-.782-.34-1.68-.704-1.84-1.262-.139-.461.207-1.117.506-1.602 1.495-2.452 1.772-4.563.782-6.287-.76-1.335-2.117-2.111-3.636-2.111-1.495 0-2.853.776-3.612 2.111-.99 1.724-.714 3.835.782 6.287.3.485.644 1.14.506 1.602-.16.558-1.058.922-1.84 1.262-.438.194-.852.364-1.22.582-1.38.899-2.37 2.233-2.692 3.835-.046.219-.023.413.092.583.115.17.276.291.483.315.067.024 1.989.3 7.082.315l.42.001c5.407 0 7.455-.291 7.524-.316z"
      />
      {newProspects && (
        <g
          fill="#0CF"
          stroke="#2F2F2F"
          strokeWidth="2"
          transform="translate(28 4)"
        >
          <rect width="10" height="10" x="-1" y="-1" rx="5" />
        </g>
      )}
    </g>
  </svg>
)
