// @flow

import Building from './Building'
import { type LotObject } from './LotPrices'
import { type ParkingObject } from './Parking'

const PACK = {
  STANDARD: 'maquette3d',
  PREMIUM: 'maquette3d+visite_virtuelle',
  BUSINESS: 'maquette3d+configurateur',
}

type SalesStatus = {
  unavailable: number,
  available: number,
  optioned: number,
  booked: number,
  sold: number,
}

export default class Program {
  +id: string;
  +label: string;
  +location: string;
  +totalNumberOfLots: number;
  +totalNumberOfParkings: number;
  +pack: string;
  +programPreviewUrl: string;
  +buildings: Building[];
  +baseVAT: number;
  +status: string;
  +lotsSalesStatus: SalesStatus;
  +vatAvailable: number[]

  constructor(
    id: string,
    label: string,
    location: string,
    totalNumberOfLots: number,
    totalNumberOfParkings: number,
    pack: string,
    programPreviewUrl: string,
    buildings: Building[],
    baseVAT: number,
    status: string,
    lotsSalesStatus: SalesStatus,
    vatAvailable: number[],
  ) {
    this.id = id
    this.label = label
    this.location = location
    this.totalNumberOfLots = totalNumberOfLots
    this.totalNumberOfParkings = totalNumberOfParkings
    this.pack = pack

    if (
      programPreviewUrl &&
      !programPreviewUrl.includes('https://') &&
      !programPreviewUrl.includes('http://')
    ) {
      this.programPreviewUrl = `https://${programPreviewUrl}`
    } else {
      this.programPreviewUrl = programPreviewUrl
    }

    if (buildings.length === 0) {
      throw new Error('')
    }
    this.buildings = buildings
    this.baseVAT = baseVAT
    this.status = status
    this.lotsSalesStatus = lotsSalesStatus
    this.vatAvailable = vatAvailable.sort((a, b) => b - a)
  }

  updateBuildingsLot(lot: LotObject) {
    this.buildings.forEach(oneBuilding => oneBuilding.updateFloorsLot(lot))
  }

  updateBuildingsParking(parking: ParkingObject) {
    this.buildings.forEach(oneBuilding =>
      oneBuilding.updateFloorsParking(parking),
    )
  }

  updateBuildingsParkingLotAssociation({
    lotId,
    parkingId,
    label,
    status,
  }: {
    lotId: string,
    parkingId: string,
    label?: string,
    status?: string,
  }) {
    this.buildings.forEach(oneBuilding =>
      oneBuilding.updateFloorsParkingLotAssociation({
        lotId,
        parkingId,
        label,
        status,
      }),
    )
  }

  isStandardPack(): boolean {
    return this.pack === PACK.STANDARD
  }

  isPremiumPack(): boolean {
    return this.pack === PACK.PREMIUM
  }

  isBusinessPack(): boolean {
    return this.pack === PACK.BUSINESS
  }

  getFirstBuilding(): Building {
    return this.buildings[0]
  }

  getBuildings(): Building[] {
    return this.buildings
  }

  getBuildingById(buildingId: string): Building {
    const building = this.buildings.find(oneBuilding =>
      oneBuilding.is(buildingId),
    )
    if (!building) {
      throw new Error(`Unable to find the building width id: "${buildingId}"`)
    }
    return building
  }
}
