// @flow

import styled from 'styled-components'

export const ApplicationContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  /* min-width: max-content; */
`
export const ApplicationMainView = styled.div`
  /* flex-grow: 1; */
  /* position: relative; */
  padding: 16px 24px;
  margin-left: 72px;
  width: calc(100% - 72px);
`
