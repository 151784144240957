// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <g>
      <circle cx="9.5" cy="9.5" r="9.5" fill="#0CF" />
      <path
        fill="#2F2F2F"
        d="M10.457 4.783c2.076 0 3.76 1.684 3.76 3.76 0 2.077-1.684 3.76-3.76 3.76-.702 0-1.374-.193-1.954-.546l-2.2 2.2c-.347.347-.911.347-1.26 0-.315-.317-.344-.812-.085-1.16l.086-.1 2.2-2.2c-.354-.58-.548-1.252-.548-1.954 0-2.076 1.684-3.76 3.76-3.76zm0 1.781c-1.093 0-1.98.886-1.98 1.98 0 .532.21 1.03.58 1.399.37.37.867.58 1.4.58 1.093 0 1.979-.886 1.979-1.98 0-1.093-.886-1.979-1.98-1.979z"
      />
    </g>
  </svg>
)
