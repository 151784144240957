// @flow

import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import ApplicationLayout from '../../ApplicationLayout'
import { ProgramHeader } from '../Guidelines/components/PageHeader'
import ProgramSettings from '../Guidelines/components/Program/Settings'

const ProgramSettingsPage = observer(() => {
  const { programSettingsStore, router } = React.useContext(StoreContext)
  const program = programSettingsStore.program

  if (!program) {
    return <ApplicationLayout />
  }

  return (
    <ApplicationLayout>
      <ProgramHeader
        programLabel={program.label}
        programTotalNumberOfLots={program.totalNumberOfLots}
        programTotalNumberOfParkings={program.totalNumberOfParkings}
        programSoldLot={program.lotsSalesStatus.sold}
        goTo={route =>
          router.goTo(route, {
            programId: program.id,
          })
        }
        currentPath={router.currentRoute.originalPath}
        programDomain={program.programPreviewUrl}
      />
      <ProgramSettings
        disableModification
        website={program.programPreviewUrl}
        onOptionsModificationChange={
          programSettingsStore.putOptionsModification
        }
      />
    </ApplicationLayout>
  )
})

export default ProgramSettingsPage
