// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import History from './History'
import { ModalHeader } from './Helper'

export default ({
  history,
  markAsContacted,
}: {|
  history: {
    programId: string,
    programName: string,
    events: {|
      type: string,
      date: string,
      author: {
        type: string,
        details?: {
          firstName: string,
          lastName: string,
          email?: string,
          phone?: string,
          address?: string,
          zipcode?: string,
          city?: string,
        },
      },
      data: ?{
        prices?: { vat: number, oldPrice: number, newPrice: number }[],
        contactRequestId?: string,
        contactedAt?: string,
        newStatus?: string,
        label?: string,
      },
    |}[],
  },
  markAsContacted: (contactRequestId: string) => Promise<void>,
|}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <ModalHeader
        subLabel={t('programs.program')}
        title={history.programName}
      />
      <History
        events={history.events}
        markAsContacted={markAsContacted}
        isProgramHistory
      />
    </React.Fragment>
  )
}
