// @flow

import * as React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../Colors'
import { RobotoExtraBold, RobotoBold, RobotoMedium } from '../Typography/Fonts'
import { SmallButtonBlue } from './Button'
import DashedSeparator from '../DashedSeparator'
import ArrowDownSvg from '../icons/ArrowDownSvg'
import ArrowUpSvg from '../icons/ArrowUpSvg'
import Taxes from '../Typography/Taxes'
import Price from '../Typography/Price'
import EvolveSvg from '../icons/EvolveSvg'
import ResponseSvg from '../icons/ResponseSvg'

const Wrapper = styled.div`
  margin-top: ${({ isProgramHistory }) => (isProgramHistory ? 0 : '-20px')};
  overflow: auto;
  padding-right: 15px;
  margin-right: -15px;
  height: 100%;
`

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Text = css`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
`

const ArrowIcon = styled.div`
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 10px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  path {
    transition: fill-opacity 0.25s ease-in-out;
    fill-opacity: 0.3;
  }
  &:hover path {
    fill-opacity: 1;
  }
`

const EventContainer = styled.div`
  position: relative;
  padding-top: 10px;
  &:hover ${ArrowIcon} {
    opacity: 1;
  }
`

const InformationsContainer = styled.div`
  width: 100%;
  height: 100%;
`

const StickyDate = styled.div`
  position: sticky;
  top: 10px;
  text-align: center;
`

const DateContainer = styled.div`
  margin-right: 10px;
  margin-top: 10px;
`

const MonthStyle = styled.div`
  ${Text};
  color: ${Colors.white30};
  margin-top: -2px;
`

const DayStyle = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 18px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 16px;
  color: ${Colors.white50};
`

const UserStyle = styled.div`
  ${Text};
  color: ${({ type }) => (type === 'seller' ? Colors.blue : Colors.white100)};
`

const TypeStyle = styled.div`
  ${Text};
  color: ${Colors.white50};
`

const Separator = styled(DashedSeparator)`
  margin-top: 10px;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${({ isOpen }) => (isOpen ? '400px' : '0')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: max-height 0.25s ease-in-out, opacity 0.15s ease-in-out;
`

const Email = styled.div`
  ${Text};
  color: ${Colors.white50};
  margin-bottom: 4px;
  margin-top: 10px;
`

const Phone = styled.div`
  ${Text};
  color: ${Colors.white50};
  margin-bottom: 4px;
`

const Address = styled.div`
  ${Text};
  color: ${Colors.white30};
  margin-bottom: 4px;
`

const City = styled.div`
  ${Text};
  color: ${Colors.white30};
  margin-bottom: 10px;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: ${({ isLast }) => (isLast ? '10px' : '4px')};

  &:first-of-type {
    margin-top: 10px;
  }

  svg {
    margin: 0 10px;
  }
`

const OldPrice = styled.div`
  ${Text};
  color: ${Colors.white30};
  span {
    color: ${Colors.white50};
  }
`
const NewPrice = styled.div`
  ${Text};
  color: ${Colors.white30};
  span {
    color: ${Colors.white100};
  }
`

/* const ActionsContainer = styled.div`
  display: flex;
  width: min-content;
  button {
    margin-right: 4px;
  }
` */

const EventResume = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 8px;
  }
`

const MarkAsContactedContainer = styled.div`
  width: max-content;
  margin-top: 10px;
`

const ContactedContainer = styled.div`
  width: max-content;
  margin-top: 10px;
  font-family: ${RobotoExtraBold.fontFamily};
  font-size: 8px;
  font-weight: ${RobotoExtraBold.fontWeight};
  line-height: 11px;
  text-transform: uppercase;
  color: ${Colors.white20};
  padding: 2px 4px;
  border-radius: 3px;
  border: 1px solid ${Colors.white20};
`

const getMonth = (date: Date, dateFormat: string) => {
  const formatter = new Intl.DateTimeFormat(dateFormat, { month: 'short' })
  return formatter.format(new Date(date))
}

const getDay = (date: Date) => {
  return date.getDate()
}

export default ({
  events,
  markAsContacted,
  isProgramHistory,
}: {
  events: {|
    type: string,
    date: string,
    author: {
      type: string,
      details?: {
        firstName: string,
        lastName: string,
        email?: string,
        phone?: string,
        address?: string,
        zipcode?: string,
        city?: string,
      },
    },
    data: ?{
      prices?: { vat: number, oldPrice: number, newPrice: number }[],
      contactRequestId?: string,
      contactedAt?: string,
      newStatus?: string,
      label?: string,
    },
  |}[],
  markAsContacted: (contactRequestId: string) => Promise<void>,
  isProgramHistory?: boolean,
}) => {
  const { t } = useTranslation()

  const [eventIndexOpen, setEventIndexOpen] = React.useState(null)
  const [contactRequestArray, setContactRequestArray] = React.useState([])

  const dateArray = [...events]
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .reduce((acc, event) => {
      const month = getMonth(new Date(event.date), t('localFormat', 'en-GB'))
      const day = getDay(new Date(event.date))
      const element = acc.find(item => item.day === day && item.month === month)
      if (element) {
        element.events.push(event)
      } else {
        acc.push({ day: day, month: month, events: [event] })
      }
      return acc
    }, [])

  const triggerEvent = (eventIndex: number) => {
    if (eventIndex === eventIndexOpen) {
      setEventIndexOpen(null)
    } else {
      setEventIndexOpen(eventIndex)
    }
  }

  const getLabelEvent = (event: Object): string => {
    if (
      event.type === 'lot_contact_request_history' ||
      event.type === 'program_contact_request_history'
    ) {
      return t('history.requestContact')
    } else if (
      event.type === 'lot_price_updated_history' ||
      event.type === 'program_price_updated_history'
    ) {
      return t('history.pricesUpdated')
    } else if (
      event.type === 'program_lot_status_updated_history' ||
      event.type === 'lot_status_updated_history'
    ) {
      return event.data
        ? `${event.data.label} ${t(`status.${event.data.newStatus}`)}`
        : event.type
    } else if (event.type === 'program_created_history') {
      return t('history.programCreated')
    } else if (event.type === 'lot_created_history') {
      return t('history.estateCreated')
    } else {
      return event.type
    }
  }

  return (
    <Wrapper isProgramHistory={isProgramHistory}>
      {dateArray.map((date, index) => (
        <Container key={index}>
          <DateContainer>
            <StickyDate>
              <DayStyle>{date.day}</DayStyle>
              <MonthStyle>{date.month}</MonthStyle>
            </StickyDate>
          </DateContainer>
          <InformationsContainer>
            {date.events.map((event, eventIndex) => {
              const eventIsRequestContact =
                event.type === 'lot_contact_request_history' ||
                event.type === 'program_contact_request_history'
              const eventIsLotPriceUpdate =
                event.type === 'lot_price_updated_history'
              const eventHaveDetail =
                eventIsLotPriceUpdate || eventIsRequestContact
              const userDetails = event.author.details
              const priceDetails = event.data && event.data.prices
              const contactRequestId = event.data && event.data.contactRequestId
              const contacted =
                (event.data && event.data.contactedAt) ||
                (contactRequestId &&
                  contactRequestArray.includes(contactRequestId))

              return (
                <EventContainer key={eventIndex}>
                  {eventHaveDetail && (
                    <ArrowIcon
                      onClick={() =>
                        eventHaveDetail && triggerEvent(eventIndex)
                      }
                      isOpen={eventIndexOpen === eventIndex}
                    >
                      {eventIndexOpen === eventIndex ? (
                        <ArrowUpSvg />
                      ) : (
                        <ArrowDownSvg />
                      )}
                    </ArrowIcon>
                  )}
                  <EventResume>
                    {contacted && <ResponseSvg />}
                    <div>
                      <UserStyle type={event.author.type}>
                        {event.author.type === 'custhome' && 'Custhome'}
                        {userDetails &&
                          `${userDetails.firstName.charAt(0)}. ${
                            userDetails.lastName
                          }`}
                      </UserStyle>
                      <TypeStyle>{getLabelEvent(event)}</TypeStyle>
                    </div>
                  </EventResume>

                  {eventIsRequestContact && userDetails && (
                    <Details isOpen={eventIndexOpen === eventIndex}>
                      {contacted && (
                        <ContactedContainer>
                          {t('history.contacted')}
                        </ContactedContainer>
                      )}
                      {contactRequestId && !contacted && (
                        <MarkAsContactedContainer>
                          <SmallButtonBlue
                            onClick={() => {
                              setContactRequestArray(oldArray => [
                                ...oldArray,
                                contactRequestId,
                              ])
                              markAsContacted(contactRequestId)
                            }}
                          >
                            {t('history.asContacted')}
                          </SmallButtonBlue>
                        </MarkAsContactedContainer>
                      )}
                      <Email>{userDetails.email}</Email>
                      <Phone>{userDetails.phone}</Phone>
                      {userDetails.address && (
                        <Address>{userDetails.address}</Address>
                      )}
                      {userDetails.zipcode && userDetails.city && (
                        <City>
                          {userDetails.zipcode}
                          {userDetails.city}
                        </City>
                      )}
                      {/* <ActionsContainer>
                        {userDetails.actions.map((oneAction, actionIndex) => (
                          <SmallButtonYellow
                            key={actionIndex}
                            onClick={() => oneAction.method()}
                          >
                            {oneAction.label}
                          </SmallButtonYellow>
                        ))}
                      </ActionsContainer> */}
                    </Details>
                  )}
                  {eventIsLotPriceUpdate && priceDetails && (
                    <Details isOpen={eventIndexOpen === eventIndex}>
                      {priceDetails.map((price, priceIndex) => (
                        <PriceContainer
                          key={priceIndex}
                          isLast={priceDetails.length - 1 === priceIndex}
                        >
                          <OldPrice>
                            <Taxes withoutLabel amount={price.vat} />{' '}
                            <Price amount={price.oldPrice} />
                          </OldPrice>
                          <EvolveSvg />
                          <NewPrice>
                            <Taxes withoutLabel amount={price.vat} />{' '}
                            <Price amount={price.newPrice} />
                          </NewPrice>
                        </PriceContainer>
                      ))}
                      {/* <ActionsContainer>
                        {priceDetailsEdition.actions.map(
                          (oneAction, actionIndex) => (
                            <SmallButtonBlue
                              key={actionIndex}
                              onClick={() => oneAction.method()}
                            >
                              {oneAction.label}
                            </SmallButtonBlue>
                          ),
                        )}
                      </ActionsContainer> */}
                    </Details>
                  )}
                  <Separator />
                </EventContainer>
              )
            })}
          </InformationsContainer>
        </Container>
      ))}
    </Wrapper>
  )
}
