// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ArrowLeftSvg from '../../icons/ArrowLeftSvg'
import ArrowRightSvg from '../../icons/ArrowRightSvg'
import { Colors } from '../../Colors'
import { RobotoBold, RobotoMedium } from '../../Typography/Fonts'
import { StepHeader, LeftAction, TextNormal, RightAction } from '../Helper'
import DashedSeparator from '../../DashedSeparator'
import AddNewSvg from '../../icons/AddNewSvg'
import Price from '../../Typography/Price'
import RemoveSvg from '../../icons/RemoveSvg'
import SearchField from '../Form/SearchField'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  overflow: auto;
  height: 100%;
  justify-content: flex-start;
`

const Title = styled(TextNormal)`
  margin-bottom: 10px;
`

const Separator = styled(DashedSeparator)`
  margin-top: 20px;
`

const ParkingsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: max-content;
  max-height: 216px;
`

const Parking = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 88px;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 ${Colors.black50}, inset 0 -2px 0 0 ${Colors.black20};
  background-color: ${Colors.dark2};
  margin: 10px 14px 10px 2px;

  &:nth-child(3n),
  &:last-child {
    margin-right: 2px;
  }
`

const AddParking = styled.div`
  cursor: pointer;
  padding: 20px;

  svg {
    transition: transform 0.25s ease-in-out;
  }

  path {
    fill: ${({ color }) => color};
  }

  &:hover {
    svg {
      transform: scale(1.2) rotate(90deg);
    }
  }
`

const Label = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${Colors.white100};
`

const SubInformations = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 10px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 12px;
  color: ${Colors.white30};
`

const RemoveParkingButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;

  path {
    fill-opacity: 0.3;
    fill: #000;
    transition: fill-opacity 0.15s ease-in-out;
  }

  &:hover {
    path {
      fill-opacity: 1;
    }
  }
`

type ParkingType = {|
  parkingId: string,
  label: string,
  priceExcludingVAT: number,
|}

type Props = {|
  title: string,
  color: string,
  goBackFunding: () => void,
  goToOffer: () => void,
  parkingsLinked: ParkingType[],
  removeParking: (parkingId: string) => void,
  parkingsAvailable: ParkingType[],
  onParkingSelected: ParkingType => void,
|}

const ParkingSelection = ({
  title,
  color,
  goBackFunding,
  goToOffer,
  parkingsLinked,
  removeParking,
  parkingsAvailable,
  onParkingSelected,
}: Props) => {
  const { t } = useTranslation()

  const [searchSelection, setSearchSelection] = React.useState(false)

  return (
    <React.Fragment>
      <StepHeader title={title} subLabel={t('process.step')} subValue="3/4" />
      {searchSelection ? (
        <React.Fragment>
          <SearchField
            color={color}
            valueId="parkingId"
            values={parkingsAvailable}
            searchableFields={['label']}
            placeholder={t('process.search')}
            onSuggestionSelected={suggestion => {
              onParkingSelected(suggestion)
              setSearchSelection(false)
            }}
            renderSuggestion={suggestion => suggestion.label}
            displaySuggestionsInRow
          />
          <LeftAction color={color} onClick={() => setSearchSelection(false)}>
            <ArrowLeftSvg /> {t('process.cancel')}
          </LeftAction>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Container>
            <Title>{t('process.parking')}</Title>
            <ParkingsWrapper>
              {parkingsLinked &&
                parkingsLinked.map(oneParking => (
                  <Parking key={oneParking.parkingId}>
                    <RemoveParkingButton
                      onClick={() => removeParking(oneParking.parkingId)}
                    >
                      <RemoveSvg />
                    </RemoveParkingButton>
                    <Label>{oneParking.label}</Label>
                    <SubInformations>
                      <Price amount={oneParking.priceExcludingVAT} />{' '}
                      {t('process.exclusiveTax')}
                    </SubInformations>
                  </Parking>
                ))}
              <Parking>
                <AddParking
                  color={color}
                  onClick={() => setSearchSelection(true)}
                >
                  <AddNewSvg />
                </AddParking>
              </Parking>
            </ParkingsWrapper>
            <Separator />
          </Container>

          <LeftAction color={color} onClick={goBackFunding}>
            <ArrowLeftSvg /> {t('process.funding')}
          </LeftAction>
          <RightAction color={color} onClick={goToOffer}>
            {t('process.offer')} <ArrowRightSvg />
          </RightAction>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default React.memo<Props>(ParkingSelection)
