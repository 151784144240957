// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../../Colors'
import { RobotoMedium } from '../../Typography/Fonts'

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  transition: opacity 0.25s ease-in-out;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

const SwitchLabel = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 12px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  user-select: none;
  cursor: pointer;
  transition: color 0.25s ease-in-out;
  color: ${({ selected, color }) =>
    selected ? color || Colors.yellow : Colors.white30};

  &:hover {
    color: ${Colors.white75};
  }
`

const SwitchToggle = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${({ color }) => color || Colors.yellow};
  border-radius: 50%;
  transition: transform 0.25s ease-in-out;
  position: absolute;
  left: 0;
`

const SwitchButton = styled.div.attrs({
  role: 'button',
})`
  cursor: pointer;
  padding: 3px;
  width: 28px;
  height: 18px;
  position: relative;
  margin: 0 4px;
  border-radius: 14px;
  border: solid 1px ${Colors.white10};

  ${SwitchToggle} {
    transform: ${props =>
      props.toggled ? 'translateX(3px)' : 'translateX(13px)'};
  }
`

type SwitchProps = {
  leftLabel: React.Node,
  rightLabel: React.Node,
  onChange: () => void,
  toggled: boolean,
  disabled?: boolean,
  color?: string,
}

export default ({
  leftLabel,
  rightLabel,
  onChange,
  toggled,
  disabled = false,
  color,
}: SwitchProps) => (
  <SwitchWrapper className={disabled ? 'disabled' : null}>
    <SwitchLabel color={color} selected={!toggled} onClick={onChange}>
      {leftLabel}
    </SwitchLabel>
    <SwitchButton toggled={!toggled} onClick={onChange}>
      <SwitchToggle color={color} />
    </SwitchButton>
    <SwitchLabel color={color} selected={toggled} onClick={onChange}>
      {rightLabel}
    </SwitchLabel>
  </SwitchWrapper>
)
