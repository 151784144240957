// @flow

import React from 'react'
import { Route } from 'mobx-router'
import ProgramSelectionPage from './Ui/ProgramSelectionPage'
import LoginPage from './Ui/LoginPage'
import PasswordResetPage from './Ui/LoginPage/PasswordReset'
import LotsManagementPage from './Ui/LotsManagementPage'
import { RootStore } from './RootStore'
import ProgramPricesPage from './Ui/ProgramPricesPage'
import ProgramParkingsPage from './Ui/ProgramParkingsPage'
import ProgramSettingsPage from './Ui/ProgramSettingsPage'
import PageNotFound from './Ui/PageNotFound'
import UserEditionPage from './Ui/UserEditionPage'
import i18n from './i18n'

const routes = {
  root_page: new Route({
    path: '/',
    component: React.createElement(() => null),
    beforeEnter: (route, params, store, queryParams) => {
      if (!store.authenticationStore.loggedIn) {
        store.router.goTo(routes.login_page)
        return false
      }
      store.router.goTo(routes.program_selection_page)
      return false
    },
  }),

  page_not_found: new Route({
    path: '/404',
    component: <PageNotFound />,
  }),

  login_page: new Route({
    path: '/login',
    component: <LoginPage />,
    beforeEnter: (route, params, store, queryParams) => {
      if (store.authenticationStore.loggedIn) {
        store.router.goTo(routes.program_selection_page)
        return false
      }
    },
  }),

  password_reset: new Route({
    path: '/login/reset',
    component: <PasswordResetPage />,
  }),

  program_selection_page: new Route({
    path: '/programs',
    component: <ProgramSelectionPage />,
    beforeEnter: async (route, params, store) => {
      if (!store.authenticationStore.loggedIn) {
        store.router.goTo(routes.login_page)
        return false
      }
      await store.programsStore.fetchPrograms()
      await store.programsStore.fetchProspects()
      await store.authenticationStore.fetchMe()
    },
  }),

  lots_management_page: new Route({
    path: '/program/:programId/lots',
    component: <LotsManagementPage />,
    beforeEnter: async (
      route: typeof Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.authenticationStore.loggedIn) {
        store.router.goTo(routes.login_page)
        return false
      }
      const programId = params.programId
      if (!programId) {
        throw new Error('The programId is missing in the route')
      }

      await store.programsStore.fetchPrograms()
      await store.lotsStore.fetchLotsByProgram(programId)
      await store.authenticationStore.fetchMe()
    },

    onParamsChange: (route, params) => {
      console.warn('TODO: fetch program lots again (program changed)', {
        route,
        params,
      })
    },
  }),

  program_prices_page: new Route({
    path: '/program/:programId/settings/lots',
    component: <ProgramPricesPage />,
    beforeEnter: async (
      route: typeof Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.authenticationStore.loggedIn) {
        store.router.goTo(routes.login_page)
        return false
      }
      const programId = params.programId
      if (!programId) {
        throw new Error('The programId is missing in the route')
      }

      await store.programPricesStore.fetchLotsPriceByProgramId(programId)
      await store.authenticationStore.fetchMe()
    },

    beforeExit: (
      route: typeof Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.programPricesStore.someLotHaveChanged) {
        return true
      }

      return window.confirm(i18n.t('unsavedConfirmation'))
    },
    onParamsChange: (route, params) => {
      console.warn('TODO: fetch program lots again (program changed)', {
        route,
        params,
      })
    },
  }),

  program_parkings_page: new Route({
    path: '/program/:programId/settings/parkings',
    component: <ProgramParkingsPage />,
    beforeEnter: async (
      route: typeof Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.authenticationStore.loggedIn) {
        store.router.goTo(routes.login_page)
        return false
      }
      const programId = params.programId
      if (!programId) {
        throw new Error('The programId is missing in the route')
      }

      await store.programParkingsStore.fetchParkingsByProgramId(programId)
      if (store.programParkingsStore.haveNoParkings) {
        store.router.goTo(routes.root_page)
        return false
      }
      await store.programParkingsStore.fetchLotsByProgram(programId)
      await store.authenticationStore.fetchMe()
    },
    beforeExit: (
      route: typeof Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.programParkingsStore.someParkingHaveChanged) {
        return true
      }

      return window.confirm(i18n.t('unsavedConfirmation'))
    },
    onParamsChange: (route, params) => {
      console.warn('TODO: fetch program lots again (program changed)', {
        route,
        params,
      })
    },
  }),

  program_settings_page: new Route({
    path: '/program/:programId/settings/program',
    component: <ProgramSettingsPage />,
    beforeEnter: async (
      route: typeof Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.authenticationStore.loggedIn) {
        store.router.goTo(routes.login_page)
        return false
      }
      const programId = params.programId
      if (!programId) {
        throw new Error('The programId is missing in the route')
      }

      await store.programSettingsStore.fetchProgramByProgramId(programId)
      await store.authenticationStore.fetchMe()
    },
    onParamsChange: (route, params) => {
      console.warn('TODO: fetch program lots again (program changed)', {
        route,
        params,
      })
    },
  }),

  user_edition_page: new Route({
    path: '/user',
    component: <UserEditionPage />,
    beforeEnter: async (
      route: typeof Route,
      params: { [keys: string]: any },
      store: RootStore,
    ) => {
      if (!store.authenticationStore.loggedIn) {
        store.router.goTo(routes.login_page)
        return false
      }
      await store.authenticationStore.fetchMe()
    },
  }),
}

export default routes
