// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 17"
  >
    <g fill="none" fillRule="evenodd">
      <path
        fill="#393939"
        d="M15,14 C15.5522847,14 16,14.4477153 16,15 C16,15.5522847 15.5522847,16 15,16 L1,16 C0.44771525,16 0,15.5522847 0,15 C0,14.4477153 0.44771525,14 1,14 L15,14 Z M15,8 C15.5522847,8 16,8.44771525 16,9 C16,9.55228475 15.5522847,10 15,10 L1,10 C0.44771525,10 0,9.55228475 0,9 C0,8.44771525 0.44771525,8 1,8 L15,8 Z M15,2 C15.5522847,2 16,2.44771525 16,3 C16,3.55228475 15.5522847,4 15,4 L1,4 C0.44771525,4 0,3.55228475 0,3 C0,2.44771525 0.44771525,2 1,2 L15,2 Z"
      />
      <path
        fill="#1E1E1E"
        d="M15,13 C15.5522847,13 16,13.4477153 16,14 C16,14.5522847 15.5522847,15 15,15 L1,15 C0.44771525,15 0,14.5522847 0,14 C0,13.4477153 0.44771525,13 1,13 L15,13 Z M15,7 C15.5522847,7 16,7.44771525 16,8 C16,8.55228475 15.5522847,9 15,9 L1,9 C0.44771525,9 0,8.55228475 0,8 C0,7.44771525 0.44771525,7 1,7 L15,7 Z M15,1 C15.5522847,1 16,1.44771525 16,2 C16,2.55228475 15.5522847,3 15,3 L1,3 C0.44771525,3 0,2.55228475 0,2 C0,1.44771525 0.44771525,1 1,1 L15,1 Z"
      />
    </g>
  </svg>
)
