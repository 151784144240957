// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../Colors'
import { RobotoBold, RobotoMedium } from '../../Typography/Fonts'
import { StepHeader, TextNormal as TextStyle, timeLeft } from '../Helper'
import DashedSeparator from '../../DashedSeparator'
import CustomerSvg from '../../icons/CustomerSmallWhiteSvg'
import StarEmptySvg from '../../icons/SmallStarEmptySvg'
import StarFilledSvg from '../../icons/SmallStarFilledSvg'
import AddNewSvg from '../../icons/AddNewSvg'
import Price from '../../Typography/Price'
import Taxes from '../../Typography/Taxes'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`

const Separator = styled(DashedSeparator)`
  margin: 20px 0;
`

const IconWrapper = styled.div`
  height: 32px;
  margin-bottom: 4px;
`

const Username = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 18px;
  color: ${({ isAction, color }) => (isAction ? color : Colors.white100)};
  cursor: pointer;
  pointer-events: ${({ isAction }) => (isAction ? 'auto' : 'none')};
`

const TextNormal = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${Colors.white50};
  max-width: 256px;
  margin-bottom: 4px;
`

const TextSmall = styled(TextStyle)`
  color: ${Colors.white30};
  max-width: 256px;
  text-align: center;
`

const StarFilled = styled(StarFilledSvg)`
  margin: 2px 1px;
`

const StarEmpty = styled(StarEmptySvg)`
  margin: 2px 1px;
`

const Funding = styled.div`
  cursor: pointer;
  pointer-events: ${({ isAction }) => (isAction ? 'auto' : 'none')};
  display: flex;

  ${StarFilled} path {
    fill: ${({ isAction, color }) => (isAction ? color : Colors.white100)};
  }
`

const AddCustomer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  color: ${Colors.white30};
  transition: color 0.15s ease-in-out;

  svg {
    margin-left: 4px;
  }
  path {
    fill: ${({ color }) => color};
  }

  &:hover {
    color: ${({ color }) => color};
  }
`

const TextWithSpan = styled(TextStyle)`
  span {
    cursor: pointer;
    pointer-events: ${({ isAction }) => (isAction ? 'auto' : 'none')};
    color: ${({ isAction, color }) => (isAction ? color : Colors.white100)};
  }
`

const FinalPrice = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 33px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 39px;
  color: ${({ isAction, color }) => (isAction ? color : Colors.white100)};
  cursor: pointer;
  pointer-events: ${({ isAction }) => (isAction ? 'auto' : 'none')};
  margin-bottom: 1px;
`

const VatContainer = styled(TextStyle)`
  border-radius: 4px;
  border: solid 1px
    ${({ isAction, color }) => (isAction ? color : Colors.white100)};
  padding: 2px 5px;
  color: ${({ isAction, color }) => (isAction ? color : Colors.white100)};
  cursor: pointer;
  pointer-events: ${({ isAction }) => (isAction ? 'auto' : 'none')};
`

/* const OptionsPrice = styled.div`
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${Colors.white50};
  margin-top: 2px;
` */

export default ({
  currentStatus,
  color,
  handleDurationEdition,
  handleCustomerEdition,
  handleFundingEdition,
  handleAddCustomer,
  handleCommercialOfferEdition,
  customer,
  commercialOffer,
}: {|
  currentStatus: ?string,
  color: string,
  handleDurationEdition: () => void,
  handleCustomerEdition: () => void,
  handleFundingEdition: () => void,
  handleAddCustomer: () => void,
  handleCommercialOfferEdition: () => void,
  customer: ?{
    firstName: string,
    lastName: ?string,
    email: string,
    phone: ?string,
    zipCode: ?string,
    street: ?string,
    city: ?string,
  },
  commercialOffer: ?{|
    vat: number,
    loan: 'no_informations' | 'loan_agreed' | 'loan_granted' | 'cash_purchase',
    duration?: number,
    discount?: number,
    parkings: {|
      parkingId: string,
      label: string,
      priceExcludingVAT: number,
    |}[],
    notaryFeeIncluded: boolean,
    totalPriceIncludingVAT: number,
    lastAction: {| author: string, date: string |},
    configuration: ?{|
      configurationId: string,
      configurationOptionPrice: number,
      receiptUrl: string,
    |},
  |},
|}) => {
  const { t } = useTranslation()

  const subLabel =
    currentStatus === 'optioned'
      ? t('process.optionedBy')
      : currentStatus === 'booked'
      ? t('process.bookedBy')
      : t('process.soldBy')
  const subValue =
    commercialOffer && commercialOffer.lastAction
      ? commercialOffer.lastAction.author
      : ''
  const title =
    commercialOffer && commercialOffer.duration && currentStatus === 'optioned'
      ? `${timeLeft(commercialOffer.duration)} ${t('process.timeLeft')}`
      : commercialOffer && commercialOffer.lastAction
      ? new Date(commercialOffer.lastAction.date).toLocaleDateString()
      : ''

  const isSold = currentStatus === 'sold'
  // const configuration = commercialOffer && commercialOffer.configuration

  return (
    <React.Fragment>
      <StepHeader
        onTitleClick={
          currentStatus === 'optioned' ? handleDurationEdition : undefined
        }
        title={title}
        subLabel={subLabel}
        subValue={subValue}
        color={color}
      />
      <Container>
        {commercialOffer && currentStatus !== 'optioned' && (
          <React.Fragment>
            <TextWithSpan color={color} isAction={!isSold}>
              {t('process.commercialOffer')}{' '}
              {commercialOffer.discount && commercialOffer.discount !== 0 ? (
                <span onClick={handleCommercialOfferEdition}>
                  ({commercialOffer.discount < 0 && '+'}
                  <Price amount={commercialOffer.discount * -1} />)
                </span>
              ) : (
                false
              )}
            </TextWithSpan>
            <FinalPrice
              color={color}
              onClick={handleCommercialOfferEdition}
              isAction={!isSold}
            >
              <Price amount={commercialOffer.totalPriceIncludingVAT} />
            </FinalPrice>
            <VatContainer
              color={color}
              onClick={handleCommercialOfferEdition}
              isAction={!isSold}
            >
              <Taxes amount={commercialOffer.vat} />
              {commercialOffer.notaryFeeIncluded &&
                ` • ${t('process.notaryFeeIncluded')}`}
            </VatContainer>
            <Separator />
          </React.Fragment>
        )}

        {/* {configuration && (
          <React.Fragment>
            <TextWithSpan isAction>
              {t('process.optionsPrices')} |{' '}
              <span
                style={{ color: color }}
                onClick={() => window.open(configuration.receiptUrl, '_blank')}
              >
                {t('process.optionsPdf')}
              </span>
            </TextWithSpan>
            <OptionsPrice>
              <Price amount={configuration.configurationOptionPrice} />
            </OptionsPrice>
            <Separator />
          </React.Fragment>
        )} */}

        <IconWrapper>
          <CustomerSvg />
        </IconWrapper>
        {customer && commercialOffer ? (
          <React.Fragment>
            <Username
              color={color}
              onClick={handleCustomerEdition}
              isAction={!isSold}
            >
              {customer.firstName} {customer.lastName}
            </Username>

            {commercialOffer.loan === 'no_informations' && (
              <Funding
                color={color}
                onClick={handleFundingEdition}
                isAction={!isSold}
              >
                <StarEmpty />
                <StarEmpty />
                <StarEmpty />
              </Funding>
            )}
            {commercialOffer.loan === 'loan_agreed' && (
              <Funding
                color={color}
                onClick={handleFundingEdition}
                isAction={!isSold}
              >
                <StarFilled />
                <StarEmpty />
                <StarEmpty />
              </Funding>
            )}
            {commercialOffer.loan === 'loan_granted' && (
              <Funding
                color={color}
                onClick={handleFundingEdition}
                isAction={!isSold}
              >
                <StarFilled />
                <StarFilled />
                <StarEmpty />
              </Funding>
            )}
            {commercialOffer.loan === 'cash_purchase' && (
              <Funding
                color={color}
                onClick={handleFundingEdition}
                isAction={!isSold}
              >
                <StarFilled />
                <StarFilled />
                <StarFilled />
              </Funding>
            )}

            {customer.email && <TextNormal>{customer.email}</TextNormal>}
            {customer.phone && <TextNormal>{customer.phone}</TextNormal>}
            {customer.street && <TextSmall>{customer.street}</TextSmall>}
            {(customer.zipCode || customer.city) && (
              <TextSmall>
                {customer.zipCode} {customer.city}
              </TextSmall>
            )}
          </React.Fragment>
        ) : (
          <AddCustomer color={color} onClick={handleAddCustomer}>
            {t('process.add')}
            <AddNewSvg />
          </AddCustomer>
        )}
        {!commercialOffer && <Separator />}
      </Container>
    </React.Fragment>
  )
}
