import React from 'react'
import styled from 'styled-components'
import { DragLayer } from 'react-dnd'
import ItemTypes from './ItemTypes'
import Card from './Card'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
}

const Layer = styled.div.attrs(({ currentOffset }) => ({
  style: currentOffset
    ? {
        transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
      }
    : {
        display: 'none',
      },
}))``

const CustomDragLayer = props => {
  const { item, itemType, isDragging, currentOffset } = props

  if (!isDragging) {
    return null
  }

  const renderItem = () => {
    switch (itemType) {
      case ItemTypes.LOT_CARD:
        return (
          <Card
            label={item.label}
            status={item.status}
            pricesIncludingVAT={item.pricesIncludingVAT}
            priceDetails={item.priceDetails}
            typology={item.typology}
            area={item.area}
            balconyArea={item.balconyArea}
            gardenArea={item.gardenArea}
            orientation={item.orientation}
            floorLabel={item.floorLabel}
            buildingLabel={item.buildingLabel}
            displayedVat={item.displayedVat}
            commercialOffer={item.commercialOffer}
            duplex={item.duplex}
            parkings={item.parkings}
            isDragging
          />
        )
      default:
        return null
    }
  }

  return (
    <div style={layerStyles}>
      <Layer currentOffset={currentOffset}>{renderItem()}</Layer>
    </div>
  )
}
export default DragLayer(monitor => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
}))(CustomDragLayer)
