// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M20 7c7.18 0 13 5.82 13 13s-5.82 13-13 13S7 27.18 7 20 12.82 7 20 7zm0 3c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm.451 5c1.807 0 2.826.568 3.397 1.514.23.35.177.621-.19.797l-.53.27c-.38.203-.557.149-.788-.19-.095-.175-.557-.81-1.889-.81-1.277 0-2.147.581-2.147 1.622v.243h3.424c.326 0 .408.095.326.392l-.136.432c-.095.298-.23.392-.557.392h-3.125v.811h2.894c.313 0 .408.095.313.392l-.136.432c-.095.298-.23.392-.557.392h-2.446v.162c.068 1.054.897 1.568 2.147 1.568 1.345 0 1.82-.649 1.902-.838.231-.338.408-.392.788-.19l.53.271c.367.19.421.446.19.811-.57.946-1.576 1.527-3.41 1.527-2.35 0-3.764-1.054-3.859-3.149v-.162h-1.25c-.312 0-.394-.108-.312-.405l.135-.433c.082-.297.231-.378.544-.378h.883v-.81h-.666c-.312 0-.394-.109-.312-.406l.136-.433c.095-.297.23-.378.557-.378h.272v-.19c0-1.986 1.48-3.256 3.872-3.256z"
    />
  </svg>
)
