// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M30.286 18.286c.937 0 1.714-.777 1.714-1.715V9.714C32 8.777 31.223 8 30.286 8h-6.857c-.938 0-1.715.777-1.715 1.714v6.857c0 .938.777 1.715 1.715 1.715h6.857zm-13.715 0c.938 0 1.715-.777 1.715-1.715V9.714c0-.937-.777-1.714-1.715-1.714H9.714C8.777 8 8 8.777 8 9.714v6.857c0 .938.777 1.715 1.714 1.715h6.857zM30.286 32c.937 0 1.714-.777 1.714-1.714v-6.857c0-.938-.777-1.715-1.714-1.715h-6.857c-.938 0-1.715.777-1.715 1.715v6.857c0 .937.777 1.714 1.715 1.714h6.857zM16.57 32c.938 0 1.715-.777 1.715-1.714v-6.857c0-.938-.777-1.715-1.715-1.715H9.714c-.937 0-1.714.777-1.714 1.715v6.857C8 31.223 8.777 32 9.714 32h6.857z"
    />
  </svg>
)
