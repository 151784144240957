// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      d="M20.41 32v-3.862c-3.387-.125-6.153-.627-7.782-.953-.233-1.354-.595-3.988-.595-7.298 0-3.235.362-5.793.595-7.097 1.603-.326 4.395-.828 7.782-.953V8c-5.17.2-9.85 1.279-10.057 1.33-.75.175-1.345.726-1.526 1.429-.025.15-.827 3.435-.827 9.128s.776 9.154.802 9.304c.18.727.801 1.28 1.55 1.455l.04.01c.509.117 5.035 1.127 10.018 1.344zm3.336-4.715c.207 0 .388-.05.543-.15 3.826-2.508 7.368-6.37 7.498-6.546.284-.35.284-.827 0-1.153-.13-.176-3.672-4.063-7.498-6.546-.285-.2-.673-.2-.983-.05-.31.176-.413.477-.413.828v3.16c-2.793.275-6.723 1.053-7.214 1.329-.62.326-1.06.928-1.06 1.856 0 .927.44 1.504 1.06 1.855.491.276 4.421 1.029 7.214 1.33v3.16c0 .325.103.651.413.827.13.05.285.1.44.1z"
    />
  </svg>
)
