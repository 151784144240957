// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ArrowLeftSvg from '../../icons/ArrowLeftSvg'
import { Colors } from '../../Colors'
import { RobotoBold } from '../../Typography/Fonts'
import { StepHeader, LeftAction, TextNormal, getPriceWithVat } from '../Helper'
import DashedSeparator from '../../DashedSeparator'
import { ButtonValideStatus } from '../Button'
import Price from '../../Typography/Price'
import { CurrencyInput } from '../Form/index'
import SwitchInput from '../Form/SwitchInput'
import Taxes from '../../Typography/Taxes'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`

const TaxesContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled(TextNormal)`
  margin-bottom: 10px;
`

const NotaryTitle = styled(TextNormal)`
  margin-bottom: 4px;
`

const Taxe = styled.div`
  cursor: pointer;
  width: 72px;
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${({ isActive, color }) => (isActive ? color : Colors.white30)};
  margin: 0 5px;
  transition: color 0.25s ease-in-out;
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'all')};
  text-align: center;

  &:hover {
    color: ${Colors.white100};
  }
`

const Separator = styled(DashedSeparator)`
  margin: 20px 0;
`

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
`

const Discount = styled.span`
  color: ${({ color }) => color};
`

const FinalInputPrice = styled(CurrencyInput)`
  font-family: ${RobotoBold.fontFamily};
  font-size: 33px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 39px;
  color: ${({ color }) => color};
  text-align: center;
`

const DetailsPriceContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
`

const DetailPrice = styled(TextNormal)`
  text-align: center;
  width: 100px;
`

const LabelPrice = styled.div`
  color: ${Colors.white30};
`

const SubPrice = styled.div`
  color: ${Colors.white50};
`

export default ({
  title,
  color,
  colorHover,
  goBackParking,
  currentVat,
  currentDiscount,
  currentNotaryFeeIncluded,
  vatList,
  onAction,
  pricesIncludingVAT,
  parkingsPriceExcludingVAT,
}: {|
  title: string,
  color: string,
  colorHover: string,
  goBackParking: () => void,
  currentVat: number,
  currentDiscount: number,
  currentNotaryFeeIncluded: boolean,
  vatList: number[],
  onAction: (
    vat: number,
    discount: number,
    notaryFeeIncluded: boolean,
    totalPriceIncludingVAT: number,
  ) => void,
  pricesIncludingVAT: {
    amountIncludingVAT: number,
    vatValueInPercent: number,
  }[],
  parkingsPriceExcludingVAT: ?number,
|}) => {
  const { t } = useTranslation()

  const [vat, setVat] = React.useState(currentVat)
  const [discount, setDiscount] = React.useState(currentDiscount)
  const [notaryFeeIncluded, setNotaryFeeIncluded] = React.useState(
    currentNotaryFeeIncluded,
  )

  const estatePriceIncludingVAT = getPriceWithVat(pricesIncludingVAT, vat)
  const parkingIncludingVAT = parkingsPriceExcludingVAT
    ? parkingsPriceExcludingVAT * (1 + vat / 100)
    : 0

  const totalPriceIncludingVAT = estatePriceIncludingVAT + parkingIncludingVAT

  const [commercialOfferPrice, setCommercialOfferPrice] = React.useState(
    totalPriceIncludingVAT,
  )

  React.useEffect(() => {
    setCommercialOfferPrice(totalPriceIncludingVAT - currentDiscount)
  }, [totalPriceIncludingVAT, currentDiscount])

  return (
    <React.Fragment>
      <StepHeader title={title} subLabel={t('process.step')} subValue="4/4" />
      <Container>
        <Title>{t('process.taxRate')}</Title>
        <TaxesContainer>
          {vatList.map(oneVat => (
            <Taxe
              key={oneVat}
              color={color}
              isActive={vat === oneVat}
              onClick={() => setVat(oneVat)}
            >
              <Taxes amount={oneVat} withoutLabel />
            </Taxe>
          ))}
        </TaxesContainer>
        <Separator />

        <DetailsPriceContainer>
          <DetailPrice>
            <LabelPrice>{t('process.apartment')}</LabelPrice>
            <SubPrice>
              <Price amount={estatePriceIncludingVAT} />
            </SubPrice>
          </DetailPrice>
          {parkingIncludingVAT ? (
            <DetailPrice>
              <LabelPrice>{t('process.parking')}</LabelPrice>
              <SubPrice>
                <Price amount={parkingIncludingVAT} />
              </SubPrice>
            </DetailPrice>
          ) : (
            false
          )}
          <DetailPrice>
            <LabelPrice>{t('process.total')}</LabelPrice>
            <SubPrice>
              <Price amount={totalPriceIncludingVAT} />
            </SubPrice>
          </DetailPrice>
        </DetailsPriceContainer>

        <TextNormal>
          {t('process.commercialOffer')}{' '}
          {discount ? (
            <Discount color={color}>
              ({discount < 0 && '+'}
              <Price amount={discount * -1} />)
            </Discount>
          ) : null}
        </TextNormal>
        <FinalInputPrice
          color={color}
          value={commercialOfferPrice}
          onValueChange={({ floatValue }) => {
            setCommercialOfferPrice(floatValue)
            setDiscount(Math.round(totalPriceIncludingVAT - floatValue))
          }}
        />
        <Separator />
        <NotaryTitle>{t('process.notaryFee')}</NotaryTitle>
        <SwitchInput
          color={color}
          leftLabel={t('process.excluded')}
          rightLabel={t('process.included')}
          onChange={() => setNotaryFeeIncluded(!notaryFeeIncluded)}
          toggled={notaryFeeIncluded}
        />
      </Container>

      <ButtonContainer>
        <ButtonValideStatus
          color={color}
          colorHover={colorHover}
          onClick={() =>
            onAction(vat, discount, notaryFeeIncluded, totalPriceIncludingVAT)
          }
        >
          {title}
        </ButtonValideStatus>
      </ButtonContainer>
      <LeftAction color={color} onClick={goBackParking}>
        <ArrowLeftSvg /> {t('process.parking')}
      </LeftAction>
    </React.Fragment>
  )
}
