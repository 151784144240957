// @flow

import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { StoreContext } from '../../StoreContext'
import NewPasswordForm from '../Guidelines/components/Login/NewPasswordForm'
import LoginScreen from '../Guidelines/components/Login/LoginScreen'

export default observer(() => {
  const { authenticationStore, router } = React.useContext(StoreContext)

  return (
    <LoginScreen>
      <NewPasswordForm
        onApplyNewPassword={newPassword =>
          authenticationStore.resetPassword(
            router.queryParams.token,
            newPassword,
          )
        }
      />
    </LoginScreen>
  )
})
