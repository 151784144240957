// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      fillRule="evenodd"
      d="M20 33c.406 0 .84-.027 1.245-.054.596-.081 1.083-.542 1.164-1.137l.27-2.166c.136-.677.705-1.192 1.408-1.192.298 0 .596.081.813.244l1.759 1.354c.217.19.514.27.785.27.298 0 .568-.08.812-.297.65-.515 1.245-1.11 1.76-1.76.379-.46.406-1.138.027-1.598-.46-.596-1.354-1.76-1.354-1.787-.162-.216-.243-.487-.243-.785 0-.677.487-1.273 1.137-1.408 0 0 1.488-.19 2.22-.27.595-.082 1.055-.57 1.136-1.165.081-.84.081-1.679 0-2.491-.081-.596-.541-1.083-1.137-1.164-.73-.082-2.22-.271-2.22-.271-.65-.163-1.136-.731-1.136-1.435 0-.271.08-.542.243-.758 0-.027 1.354-1.787 1.354-1.787.379-.46.352-1.138-.027-1.598-.515-.65-1.11-1.245-1.76-1.76-.46-.379-1.137-.406-1.597-.027-.595.46-1.76 1.354-1.76 1.354-.243.135-.514.244-.812.244-.703 0-1.272-.515-1.407-1.192l-.27-2.166c-.082-.596-.57-1.056-1.165-1.137-.839-.081-1.678-.081-2.49 0-.596.081-1.083.541-1.164 1.137l-.27 2.166c-.11.677-.705 1.192-1.408 1.192-.325 0-.623-.109-.867-.271l-1.705-1.327c-.46-.379-1.137-.352-1.597.027-.65.515-1.245 1.11-1.76 1.76-.406.46-.406 1.137-.027 1.598l1.3 1.705c.19.244.297.542.297.84 0 .73-.541 1.327-1.245 1.435l-2.111.27c-.596.082-1.083.57-1.137 1.165-.081.84-.081 1.679 0 2.49.081.597.541 1.084 1.137 1.165l2.111.27c.704.082 1.245.678 1.245 1.409 0 .325-.108.623-.297.866l-1.3 1.706c-.379.46-.352 1.137.027 1.598.515.65 1.11 1.245 1.76 1.76.244.19.514.297.812.297.27 0 .568-.08.785-.27l1.705-1.327c.244-.163.542-.27.867-.27.703 0 1.299.514 1.407 1.19 0 .028.27 2.167.27 2.167.082.595.57 1.056 1.165 1.137.406.027.839.054 1.245.054zm0-8.583c-2.436 0-4.412-1.977-4.412-4.414 0-2.436 1.976-4.413 4.412-4.413s4.412 1.977 4.412 4.413c0 2.437-1.976 4.414-4.412 4.414z"
    />
  </svg>
)
