// @flow

import * as React from 'react'
import styled from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'
import ArrowDownSvg from '../icons/ArrowDownSmallSvg'
import CheckSvg from '../icons/CheckSvg'
import { StatusDot } from './Program/Dot'
import { getStatusColor } from './Helper'

const Wrapper = styled.div`
  position: relative;
  width: max-content;
  margin: auto;
`

const SelectContainer = styled.div`
  cursor: pointer;
  pointer-events: ${({ isOpen }) => (isOpen ? 'none' : 'all')};
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 18px;
  border-radius: 9px;
  border: solid 1px ${Colors.white10};

  svg {
    margin-left: 2px;
  }
  path {
    transition: fill-opacity 0.25s ease-in-out;
    fill-opacity: ${({ isActive, isOpen }) => (isActive || isOpen ? 1 : 0.3)};
  }

  &:hover {
    path {
      fill-opacity: 1;
    }
  }
`

const ChoicesContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${Colors.black50};
  border: solid 1px ${Colors.black10};
  background-color: ${Colors.carbon};
  padding: 10px 15px;
  min-width: 100%;
  position: absolute;
  z-index: 1;
  left: 0px;
`

const ChoiceRadio = styled.div`
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${Colors.white10};
  transition: background-color 0.25s ease-in-out, opacity 0.15s ease-in-out;

  opacity: ${({ isActive }) => (isActive ? 0 : 1)};
`

const ChoiceChecked = styled.div`
  position: absolute;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;

  svg {
    width: 14px;
    height: 14px;
  }
`

const ChoiceLabel = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  font-size: 12px;
  margin-left: 4px;
  color: ${({ isActive }) => (isActive ? Colors.white100 : Colors.white30)};
  transition: color 0.25s ease-in-out;
`

const Choice = styled.div`
  cursor: pointer;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 5px 0;

  ${ChoiceChecked} {
    path {
      fill: ${({ color }) => color};
    }
  }

  &:hover {
    ${ChoiceRadio} {
      background-color: ${({ color }) => color};
    }
    ${ChoiceLabel} {
      color: ${Colors.white100};
    }
  }
`

const STATUS_LIST = ['unavailable', 'available', 'optioned', 'booked', 'sold']

type Props = {|
  currentStatus: string,
  onStatusChanged: (status: string) => void,
  t: string => string,
|}

type State = {
  isOpen: boolean,
  style: Object,
}

class SelectStatus extends React.Component<Props, State> {
  _wrapperRef: any
  _selectRef: any

  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: false,
      style: null,
    }
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      this.toggleDropdown()
    }
  }

  toggleDropdown = () => {
    let style
    if (!this.state.isOpen) {
      const wrapperDimensions = this._selectRef.getBoundingClientRect()
      if (wrapperDimensions.top < window.innerHeight / 2) {
        style = { top: '22px' }
      } else {
        style = { bottom: '22px' }
      }
    }

    this.setState({ isOpen: !this.state.isOpen, style: style })
  }

  onStatusChanged = (status: string) => {
    const { onStatusChanged, currentStatus } = this.props
    this.toggleDropdown()
    currentStatus !== status && onStatusChanged(status)
  }

  render() {
    const { currentStatus, t } = this.props
    const { isOpen, style } = this.state

    return (
      <Wrapper>
        <SelectContainer
          ref={node => (this._selectRef = node)}
          isOpen={isOpen}
          onClick={() => this.toggleDropdown()}
        >
          <StatusDot value={currentStatus} />
          <ArrowDownSvg />
        </SelectContainer>

        {isOpen ? (
          <ChoicesContainer
            ref={node => (this._wrapperRef = node)}
            style={style}
          >
            {STATUS_LIST.map(oneStatus => (
              <Choice
                onClick={() => this.onStatusChanged(oneStatus)}
                key={oneStatus}
                color={getStatusColor(oneStatus)}
              >
                <ChoiceRadio isActive={oneStatus === currentStatus} />
                <ChoiceChecked isActive={oneStatus === currentStatus}>
                  <CheckSvg />
                </ChoiceChecked>
                <ChoiceLabel isActive={oneStatus === currentStatus}>
                  {t(`status.${oneStatus}`)}
                </ChoiceLabel>
              </Choice>
            ))}
          </ChoicesContainer>
        ) : null}
      </Wrapper>
    )
  }
}

export default withTranslation()(SelectStatus)
