// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="#FFD050"
      fillRule="evenodd"
      d="M7 0c3.866 0 7 3.134 7 7 0 1.235-.32 2.395-.88 3.402l2.317 2.316c.75.751.75 1.968 0 2.719-.75.75-1.968.75-2.719 0l-2.316-2.318C9.395 13.68 8.235 14 7 14c-3.866 0-7-3.134-7-7s3.134-7 7-7zm0 2C4.239 2 2 4.239 2 7s2.239 5 5 5 5-2.239 5-5-2.239-5-5-5z"
    />
  </svg>
)
