// @flow
import { computed, observable } from 'mobx'
import Customer from './Customer'
import Configuration from './Configuration'

export type LotLoan =
  | 'no_informations'
  | 'loan_agreed'
  | 'loan_granted'
  | 'cash_purchase'

export default class CommercialOffer {
  @observable vat: number
  @observable loan: LotLoan = 'no_informations'
  @observable customer: ?Customer = null
  @observable lotPriceIncludingVAT: number = 0
  @observable configuration: ?Configuration = null
  @observable totalPriceIncludingVAT: number = 0
  @observable duration: ?number
  @observable discount: ?number
  @observable notaryFeeIncluded: boolean = false
  @observable lastAction: {| author: string, date: string |}
  @observable priceDetails: ?({| label: string, amount: number |}[]) = null

  constructor(
    vat: number,
    loan: LotLoan,
    customer: ?Customer,
    lotPriceIncludingVAT: number,
    configuration: ?Configuration,
    totalPriceIncludingVAT: number,
    duration: ?number,
    discount: ?number,
    notaryFeeIncluded: boolean,
    lastAction: {| author: string, date: string |},
    priceDetails: ?({| label: string, amount: number |}[]),
  ) {
    this.vat = vat
    this.loan = loan
    this.customer = customer
    this.configuration = configuration
    this.lotPriceIncludingVAT = lotPriceIncludingVAT
    this.totalPriceIncludingVAT = totalPriceIncludingVAT
    this.duration = duration
    this.discount = discount
    this.notaryFeeIncluded = notaryFeeIncluded
    this.lastAction = lastAction
    this.priceDetails = priceDetails
  }

  update(
    commercialOffer: {|
      vat: number,
      loan: LotLoan,
      duration?: number,
      discount?: number,
      parkings: {|
        parkingId: string,
        label: string,
        priceExcludingVAT: number,
      |}[],
      notaryFeeIncluded: boolean,
      totalPriceIncludingVAT: number,
      lastAction: {| author: string, date: string |},
      configuration: ?{|
        configurationId: string,
        configurationOptionPrice: number,
        receiptUrl: string,
      |},
    |},
    customer: ?Customer,
    updatedDuration?: boolean,
  ): void {
    this.vat = commercialOffer.vat
    this.loan = commercialOffer.loan
    if (
      commercialOffer.duration &&
      this.duration !== commercialOffer.duration
    ) {
      this.duration = commercialOffer.duration * (updatedDuration ? 1 : 24)
    }
    this.discount = commercialOffer.discount
    this.notaryFeeIncluded = commercialOffer.notaryFeeIncluded
    this.totalPriceIncludingVAT = commercialOffer.totalPriceIncludingVAT
    this.lastAction = commercialOffer.lastAction
    this.customer = customer
  }

  updateCustomer(customer: Customer): void {
    this.customer = customer
  }

  /* updateConfiguration(
    configurationId: string,
    configurationOptionPrice: number,
    receiptUrl: string,
  ): void {
    this.configuration = new Configuration(
      configurationId,
      configurationOptionPrice,
      receiptUrl,
    )
  } */

  @computed get cardInformation(): {
    firstName: ?string,
    lastName: ?string,
    funding: string,
    duration: ?number,
    price: number,
    priceDetails: ?({| label: string, amount: number |}[]),
    vat: number,
  } {
    return {
      firstName: this.customer ? this.customer.firstName : null,
      lastName: this.customer ? this.customer.lastName : null,
      funding: this.loan,
      duration: this.duration,
      price: this.totalPriceIncludingVAT,
      priceDetails: this.priceDetails,
      vat: this.vat,
    }
  }

  @computed get clientConfiguration(): ?{|
    configurationId: string,
    configurationOptionPrice: number,
    receiptUrl: string,
  |} {
    return this.configuration
      ? {
          configurationId: this.configuration.configurationId,
          configurationOptionPrice: this.configuration.configurationOptionPrice,
          receiptUrl: this.configuration.receiptUrl,
        }
      : null
  }
}
