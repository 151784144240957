// @flow

import * as React from 'react'
import styled, { keyframes } from 'styled-components'
import { Colors } from '../Colors'
import CusthomePictoSvg from '../icons/CusthomePictoSvg'

const pulse = keyframes`
  from {
    opacity: 0;
    transform: scale(1);
    filter: drop-shadow(0px 0px 10px ${Colors.blue});
  }

  to {
    opacity: 0.5;
    transform: scale(1.1);
    filter: drop-shadow(5px 5px 150px ${Colors.blue});
  }
`

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`

const LogoResizer = styled.div`
  width: 128px;
  height: 128px;
`

const AnimatedCusthomePictoLogo = styled(CusthomePictoSvg)`
  animation: ${pulse} 1s ease-in-out alternate infinite;
`

export default () => (
  <LoaderContainer>
    <LogoResizer>
      <AnimatedCusthomePictoLogo color={Colors.blue} />
    </LogoResizer>
  </LoaderContainer>
)
