// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g fill="none">
      <path
        fill="#323232"
        d="M16.5 23.885c1.171-1.152 2.47-2.102 3.851-2.853 7.7-4.176 17.572-1.956 22.522 5.431l-5.99 5.893c-1.717-3.856-5.982-6.05-10.225-5.116-1.597.352-3.114 1.137-4.354 2.357L10.825 40.9c-3.485 3.43-3.485 9.011 0 12.442 3.486 3.43 9.156 3.43 12.641 0l3.54-3.483c3.218 1.257 6.669 1.759 10.077 1.51l-7.811 7.688c-6.696 6.59-17.553 6.59-24.25 0-6.696-6.592-6.696-17.278 0-23.869L16.5 23.885zM34.731 5.941L26.92 13.63c3.405-.252 6.859.252 10.078 1.506l3.536-3.48c3.486-3.43 9.155-3.43 12.64 0 3.487 3.43 3.487 9.011 0 12.442l-11.48 11.3c-3.496 3.442-9.174 3.413-12.64 0-.809-.795-1.505-1.785-1.94-2.759l-5.99 5.893c.63.94 1.284 1.751 2.124 2.578 2.165 2.131 4.923 3.72 8.096 4.473 4.112.974 8.518.436 12.302-1.62 1.382-.75 2.68-1.7 3.851-2.853l11.478-11.3c6.7-6.591 6.7-17.277.003-23.869-6.696-6.588-17.55-6.588-24.247 0z"
      />
      <path
        fill="#181818"
        d="M16.5 22.885c1.171-1.152 2.47-2.102 3.851-2.853 7.7-4.176 17.572-1.956 22.522 5.431l-5.99 5.893c-1.717-3.856-5.982-6.05-10.225-5.116-1.597.352-3.114 1.137-4.354 2.357L10.825 39.9c-3.485 3.43-3.485 9.011 0 12.442 3.486 3.43 9.156 3.43 12.641 0l3.54-3.483c3.218 1.257 6.669 1.759 10.077 1.51l-7.811 7.688c-6.696 6.59-17.553 6.59-24.25 0-6.696-6.592-6.696-17.278 0-23.869L16.5 22.885zM34.731 4.941L26.92 12.63c3.405-.252 6.859.252 10.078 1.506l3.536-3.48c3.486-3.43 9.155-3.43 12.64 0 3.487 3.43 3.487 9.011 0 12.442l-11.48 11.3c-3.496 3.442-9.174 3.413-12.64 0-.809-.795-1.505-1.785-1.94-2.759l-5.99 5.893c.63.94 1.284 1.751 2.124 2.578 2.165 2.131 4.923 3.72 8.096 4.473 4.112.974 8.518.436 12.302-1.62 1.382-.75 2.68-1.7 3.851-2.853l11.478-11.3c6.7-6.591 6.7-17.277.003-23.869-6.696-6.588-17.55-6.588-24.247 0z"
      />
    </g>
  </svg>
)
