// @flow

import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import ArrowLeftSvg from '../../icons/ArrowLeftSvg'
import { Colors } from '../../Colors'
import { RobotoBold } from '../../Typography/Fonts'
import { StepHeader, LeftAction, TextNormal } from '../Helper'
import DashedSeparator from '../../DashedSeparator'
import { Input } from '../Form/index'
import { ButtonValideStatus } from '../Button'
import Taxes from '../../Typography/Taxes'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
`

const TaxesContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Title = styled(TextNormal)`
  margin-bottom: 10px;
`

const Taxe = styled.div`
  cursor: pointer;
  width: 72px;
  font-family: ${RobotoBold.fontFamily};
  font-size: 24px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 25px;
  color: ${({ isActive }) => (isActive ? Colors.pink : Colors.white30)};
  margin: 0 5px;
  transition: color 0.25s ease-in-out;
  pointer-events: ${({ isActive }) => (isActive ? 'none' : 'all')};
  text-align: center;

  &:hover {
    color: ${Colors.white100};
  }
`

const Separator = styled(DashedSeparator)`
  margin: 20px 0;
`

const Duration = styled(Input)`
  text-align: center;
  font-family: ${RobotoBold.fontFamily};
  font-size: 33px;
  font-weight: ${RobotoBold.fontWeight};
  line-height: 40px;
  color: ${Colors.pink};

  &::placeholder {
    color: ${Colors.pink};
  }

  &:focus {
    &::placeholder {
      color: ${Colors.white30};
    }
  }
`

const Days = styled(TextNormal)`
  color: ${Colors.white30};
`

const ButtonContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
`

export default ({
  goBackFunding,
  currentVat,
  currentDuration,
  vatList,
  onOption,
}: {
  goBackFunding: () => void,
  currentVat: number,
  currentDuration?: number,
  vatList: number[],
  onOption: (vat: number, duration: number) => void,
}) => {
  const { t } = useTranslation()

  const [vat, setVat] = React.useState(currentVat)
  const [duration, setDuration] = React.useState(currentDuration || '')

  return (
    <React.Fragment>
      <StepHeader
        title={t('process.optioned')}
        subLabel={t('process.step')}
        subValue="3/3"
      />
      <Container>
        <Title>{t('process.taxRate')}</Title>
        <TaxesContainer>
          {vatList.map(oneVat => (
            <Taxe
              key={oneVat}
              isActive={vat === oneVat}
              onClick={() => setVat(oneVat)}
            >
              <Taxes amount={oneVat} withoutLabel />
            </Taxe>
          ))}
        </TaxesContainer>
        <Separator />
        <Title>{t('process.optionDuration')}</Title>
        <Duration
          type="text"
          placeholder="?"
          value={duration}
          onChange={e => setDuration(parseInt(e.target.value, 10) || '')}
        />
        <Days>{t('process.days')}</Days>
      </Container>

      <ButtonContainer>
        <ButtonValideStatus
          color={Colors.pink}
          colorHover={Colors.pinkLight}
          disabled={!duration || duration === 0}
          onClick={() => duration && onOption(vat, duration)}
        >
          {t('process.optioned')}
        </ButtonValideStatus>
      </ButtonContainer>
      <LeftAction color={Colors.pink} onClick={goBackFunding}>
        <ArrowLeftSvg /> {t('process.funding')}
      </LeftAction>
    </React.Fragment>
  )
}
