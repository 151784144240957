// @flow
import * as React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import CrossSvg from '../icons/CrossSvg'
import { Colors } from '../Colors'

const Backdrop = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 99;
  overflow: auto;
`

const ModalContainer = styled.div`
  display: flex;
  position: relative;
  width: min-content;
  height: 484px;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 ${Colors.black50};
  overflow: auto;
  flex-direction: row-reverse;
`

const ModalContentLeft = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 360px;
  padding: 30px;
  background-color: ${Colors.brown1};
`

const ModalContentRight = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 360px;
  padding: 30px;
  box-shadow: inset 1px 0 0 0 ${Colors.carbon};
  background-color: ${Colors.brown2};
  margin-left: ${({ rightContentIsOpen }) =>
    rightContentIsOpen ? '0' : '-360px'};
  transition: margin-left 0.25s ease-in-out;
`

const CloseButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  z-index: 10;
  padding: 30px;

  path {
    transition: fill-opacity 0.25s ease-in-out;
  }

  &:hover path {
    fill-opacity: 1;
  }
`

type ModalProps = {|
  onClose: () => void,
  children: React.Node,
  rightContent?: React.Element<any>,
  rightContentIsOpen?: boolean,
|}

export default class DialogModal extends React.PureComponent<ModalProps> {
  _container: HTMLDivElement
  _wrapperRef: any

  constructor() {
    super()

    this._container = window.document.createElement('div')
    this._wrapperRef = React.createRef()
    window.document.body.appendChild(this._container)
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside)
    window.document.addEventListener('keydown', this.handleEscapeKey)
  }

  componentWillUnmount() {
    window.document.body.removeChild(this._container)
    window.document.removeEventListener('mousedown', this.handleClickOutside)
    window.document.removeEventListener('keydown', this.handleEscapeKey)
  }

  onClose = () => {
    this.props.onClose()
  }

  handleEscapeKey = (event: SyntheticKeyboardEvent<*>) => {
    if (event.key === 'Escape') {
      this.onClose()
    }
  }

  handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      this.onClose()
    }
  }

  render() {
    const { rightContent, rightContentIsOpen } = this.props
    return ReactDOM.createPortal(
      <Backdrop>
        <ModalContainer ref={node => (this._wrapperRef = node)}>
          <CloseButton onClick={() => this.onClose()}>
            <CrossSvg />
          </CloseButton>
          <ModalContentRight rightContentIsOpen={rightContentIsOpen}>
            {rightContent && React.cloneElement(rightContent)}
          </ModalContentRight>
          <ModalContentLeft>{this.props.children}</ModalContentLeft>
        </ModalContainer>
      </Backdrop>,
      this._container,
    )
  }
}
