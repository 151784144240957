// @flow

import { type FilterValueComparator } from './FilterDefinition'

export default class FilterValues {
  +id: string;
  +valueComparator: FilterValueComparator;

  +values: Array<any> = []

  constructor(id: string, valueComparator: FilterValueComparator) {
    this.id = id
    this.valueComparator = valueComparator

    Object.freeze(this)
  }

  toogleValue(value: any) {
    const index = this.values.findIndex(a => this.valueComparator(a, value))

    if (index > -1) {
      this.values.splice(index, 1)
    } else {
      this.values.push(value)
    }
  }

  haveValues(): boolean {
    return this.values.length !== 0
  }
}
