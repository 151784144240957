// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'

/**
 * The node ICU data are diferent on macOs and on Bitbucket Pipelines.
 * We normalize the macOs one to match the basic HTML non-breaking space char (@nbsp;)
 */
const normalizeNonBreakingSpace = str => str.replace(' ', ' ')

export default ({
  amount,
  withDecimal = false,
}: {
  amount: number,
  withDecimal?: boolean,
}) => {
  const { t } = useTranslation()

  return (
    <span>
      {normalizeNonBreakingSpace(
        amount.toLocaleString(t('localFormat', 'en-GB'), {
          style: 'currency',
          currency: 'EUR',
          currencyDisplay: 'symbol',
          useGrouping: true,
          minimumFractionDigits: 0,
          maximumFractionDigits: withDecimal ? 2 : 0,
        }),
      )}
    </span>
  )
}
