// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'
import ArrowDownSvg from '../icons/ArrowDownSmallSvg'

const Wrapper = styled.div`
  position: relative;
  margin: 2px 2px;
  width: max-content;
`

const Label = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 12px;
  line-height: 14px;
  color: ${Colors.white30};
  margin-right: 3px;
  transition: color 0.25s ease-in-out;
`

const SelectContainer = styled.div`
  cursor: pointer;
  pointer-events: ${({ isOpen }) => (isOpen ? 'none' : 'all')};
  border-radius: 3px;
  padding: 5px 6px;
  display: flex;
  align-items: center;
  width: min-content;
  background-color: ${Colors.carbon};

  svg {
    margin-top: 1px;
  }
  path {
    transition: fill-opacity 0.25s ease-in-out, fill 0.25s ease-in-out;
    fill-opacity: ${({ isActive, isOpen }) => (isActive || isOpen ? 1 : 0.3)};
    fill: ${({ isActive }) => (isActive ? Colors.blue : Colors.white100)};
  }
  ${Label} {
    ${({ isOpen }) => isOpen && { color: Colors.white100 }};
  }
  &:hover {
    path {
      fill-opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
    }
    ${Label} {
      color: ${Colors.white50};
    }
  }
`

const ChoicesContainer = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 4px 0 ${Colors.black50};
  border: solid 1px ${Colors.black10};
  background-color: ${Colors.carbon};
  padding: 10px 15px;
  min-width: 100%;
  position: absolute;
  z-index: 1;
  top: 28px;
  right: 0px;
`

const ChoiceRadio = styled.div`
  width: 8px;
  height: 8px;
  margin: 3px;
  border-radius: 50%;
  background-color: ${({ isActive }) =>
    isActive ? Colors.blue : Colors.white10};
  transition: background-color 0.25s ease-in-out, width 0.25s ease-in-out,
    height 0.25s ease-in-out, margin 0.25s ease-in-out;
`

const ChoiceLabel = styled.div`
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 14px;
  font-size: 12px;
  margin-left: 4px;
  color: ${({ isActive }) => (isActive ? Colors.white50 : Colors.white30)};
  transition: color 0.25s ease-in-out;
`

const Choice = styled.div`
  cursor: pointer;
  width: max-content;
  display: flex;
  align-items: center;
  padding: 5px 0;
  &:hover {
    ${ChoiceRadio} {
      margin: 1px;
      width: 12px;
      height: 12px;
    }
    ${ChoiceLabel} {
      color: ${Colors.white50};
    }
  }
`

type Value = string | { from: number, to: number } | number

type Props = {|
  id: string,
  label: string,
  comparator: (a: any, b: any) => boolean,
  choices: Array<{
    label: string,
    value: Value,
  }>,
  onChoiceClick: (id: string, value: Value) => void,
  values: Array<any>,
|}

type State = {
  isOpen: boolean,
}

export default class SelectFilter extends React.Component<Props, State> {
  _wrapperRef: any

  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  componentDidMount() {
    window.document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    window.document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: SyntheticEvent<HTMLElement>) => {
    if (this._wrapperRef && !this._wrapperRef.contains(event.target)) {
      this.toggleDropdown()
    }
  }

  toggleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  onChoiceClick = (value: Value) => {
    const { id, onChoiceClick } = this.props
    onChoiceClick(id, value)
  }

  render() {
    const { label, choices, comparator, values } = this.props
    const { isOpen } = this.state

    return (
      <Wrapper>
        <SelectContainer
          isActive={values.length > 0}
          isOpen={isOpen}
          onClick={() => this.toggleDropdown()}
        >
          <Label>{label}</Label>
          <ArrowDownSvg />
        </SelectContainer>

        {isOpen ? (
          <ChoicesContainer ref={node => (this._wrapperRef = node)}>
            {choices.map(oneChoice => {
              const isActive =
                values.find(oneValue =>
                  comparator(oneChoice.value, oneValue),
                ) !== undefined

              return (
                <Choice
                  onClick={() => this.onChoiceClick(oneChoice.value)}
                  key={oneChoice.value.toString() + oneChoice.label}
                >
                  <ChoiceRadio isActive={isActive} />
                  <ChoiceLabel isActive={isActive}>
                    {oneChoice.label}
                  </ChoiceLabel>
                </Choice>
              )
            })}
          </ChoicesContainer>
        ) : null}
      </Wrapper>
    )
  }
}
