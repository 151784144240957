// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="16"
    height="16"
    viewBox="0 0 16 16"
  >
    <path
      fill="rgba(255,255,255,0.5)"
      d="M13 0c1.657 0 3 1.343 3 3v10c0 1.657-1.343 3-3 3H3c-1.657 0-3-1.343-3-3V3c0-1.657 1.343-3 3-3h10zm0 1H3c-1.054 0-1.918.816-1.995 1.85L1 3v10c0 1.054.816 1.918 1.85 1.995L3 15h10c1.054 0 1.918-.816 1.995-1.85L15 13V3c0-1.054-.816-1.918-1.85-1.995L13 1zm-1.285 3c.156 0 .285.117.285.267l-.004.507C11.956 6.62 11.54 10.4 7.21 10.4c-.285 0-.587 0-.897-.033l-.236.346c-.187.278-.345.526-.445.71l-.053.102c-.13.3-.44.475-.75.475-.113 0-.234-.017-.337-.067-.415-.175-.596-.633-.432-1.025.742-1.533 2.33-3.258 4.695-4.4.086-.05.13-.15.095-.241-.035-.1-.155-.15-.259-.117-2.132.733-3.418 1.767-4.22 2.542-.216-1.184-.052-2.134.492-2.867C6.063 4.217 9.005 4 11.715 4z"
    />
  </svg>
)
