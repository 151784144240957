// @flow

import styled from 'styled-components'
import { Colors } from '../../Colors'
import FormattedInputBase from 'react-number-format'
import { RobotoMedium } from '../../Typography/Fonts'

export const Input = styled.input`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  line-height: 16px;
  background-color: transparent;
  border: 0;
  color: ${Colors.white100};

  &::placeholder {
    transition: color 0.15s ease-in-out;
    color: ${Colors.white30};
  }

  &:first-child {
    margin-top: 0;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 4px 1000px ${Colors.brown2} inset;
    -webkit-text-fill-color: ${Colors.white50} !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${Colors.white10};
  }
`

export const CurrencyInput = styled(FormattedInputBase).attrs({
  thousandSeparator: ' ',
  decimalScale: 0,
  allowNegative: false,
  placeholder: ' €',
  suffix: ' €',
  customInput: Input,
})`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 22px;
  font-weight: ${RobotoMedium.fontWeight};
`

export const ErrorMessage = styled.div`
  margin-top: 20px;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.fontWeight};
  font-size: 14px;
  color: ${Colors.blue};
`
