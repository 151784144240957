// @flow

import * as React from 'react'

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="10"
    height="10"
    viewBox="0 0 10 10"
  >
    <path
      fill="#FFF"
      fillOpacity=".3"
      fillRule="evenodd"
      d="M1.473 2.647c.243-.205.598-.192.826.019l.054.057 2.682 3.186 2.616-3.045c.207-.242.56-.286.818-.115l.063.047c.242.208.286.56.115.82l-.048.062-3.094 3.604c-.234.273-.645.29-.9.05l-.052-.055-3.156-3.75c-.222-.264-.189-.658.076-.88z"
    />
  </svg>
)
