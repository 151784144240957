// @flow

import { action, observable, runInAction, computed } from 'mobx'
import { RootStore } from '../RootStore'
import Program from '../Domain/Program'
import Building from '../Domain/Building'
import Floor from '../Domain/Floor'
import Parking, { type ParkingObject } from '../Domain/Parking'

export default class ProgramParkingsStore {
  +rootStore: RootStore

  @observable programId: ?string = null
  @observable program: Program
  @observable updatedParkings: ParkingObject[] = []
  @observable currentBuilding: Building
  @observable lotsForAssociation: {
    lotId: string,
    label: string,
    status: string,
  }[]

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action async fetchParkingsByProgramId(programId: string) {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/parkings`,
    )

    if (this.program) {
      runInAction(() => {
        // $FlowFixMe
        this.program = null
      })
    }

    runInAction(() => {
      this.programId = programId
      this.program = new Program(
        response.data.programId,
        response.data.label,
        response.data.location,
        response.data.totalNumberOfLots,
        response.data.totalNumberOfParkings,
        response.data.pack,
        response.data.domain,
        response.data.buildings.map(
          oneBuildingPayload =>
            new Building(
              oneBuildingPayload.buildingId,
              oneBuildingPayload.label,
              oneBuildingPayload.floors.map(
                oneFloorPayload =>
                  new Floor(
                    oneFloorPayload.floorId,
                    oneFloorPayload.label,
                    oneFloorPayload.order,
                    null,
                    oneFloorPayload.parkings.map(
                      oneParkingPayload =>
                        new Parking(
                          oneParkingPayload.parkingId,
                          oneParkingPayload.label,
                          oneParkingPayload.status,
                          oneParkingPayload.priceExcludingVAT,
                          oneParkingPayload.lot,
                        ),
                    ),
                  ),
              ),
            ),
        ),
        response.data.baseVAT,
        response.data.status,
        response.data.lotsSalesStatus,
        response.data.vatAvailable,
      )

      this.currentBuilding = this.program.getFirstBuilding()
      this.updatedParkings = []
    })
  }

  @action async fetchLotsByProgram(programId: string) {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots`,
    )

    runInAction(() => {
      this.lotsForAssociation = response.data.map(oneLotPayload => {
        return {
          lotId: oneLotPayload.lotId,
          label: oneLotPayload.label,
          status: oneLotPayload.status,
        }
      })
    })
  }

  @action async saveParkings() {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${programId}/settings/parking`,
      {
        parkings: this.updatedParkings.map(oneParking => ({
          parkingId: oneParking.id,
          status: oneParking.status,
          priceExcludingVAT: oneParking.priceExcludingVAT,
          lotId: oneParking.estateLinked ? oneParking.estateLinked.lotId : null,
        })),
      },
    )

    runInAction(() => {
      this.updatedParkings.forEach(oneUpdatedParking => {
        this.program.updateBuildingsParking(oneUpdatedParking)
      })
      this.updatedParkings = []
    })
  }

  @action async addLot(
    lotId: string,
    parkingId: string,
    lotLabel: string,
    lotStatus: string,
    parking: ParkingObject,
  ) {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${programId}/link_parking_to_lot`,
      {
        lotId: lotId,
        parkingId: parkingId,
      },
    )

    runInAction(() => {
      const parkingEditedIndex = this.updatedParkings.findIndex(
        oneParking => oneParking.id === parking.id,
      )
      if (parkingEditedIndex > -1) {
        const updateParkingArray = [...this.updatedParkings]
        if (
          parking.priceExcludingVAT ===
          updateParkingArray[parkingEditedIndex].priceExcludingVAT
        ) {
          updateParkingArray.splice(parkingEditedIndex, 1)
        } else {
          updateParkingArray[parkingEditedIndex].status = parking.status
        }
        this.updatedParkings = updateParkingArray
      }

      this.program.updateBuildingsParkingLotAssociation({
        lotId: lotId,
        parkingId: parkingId,
        label: lotLabel,
        status: lotStatus,
      })
    })
  }

  @action async removeLot(lotId: string, parkingId: string) {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${programId}/unlink_parking_to_lot`,
      {
        lotId: lotId,
        parkingId: parkingId,
      },
    )

    runInAction(() => {
      this.program.updateBuildingsParkingLotAssociation({
        lotId: lotId,
        parkingId: parkingId,
      })
    })
  }

  @action onParkingPriceEdition(parking: ParkingObject, value: number) {
    const parkingEditedIndex = this.updatedParkings.findIndex(
      oneParking => oneParking.id === parking.id,
    )
    if (parkingEditedIndex > -1) {
      const updateParkingArray = [...this.updatedParkings]
      if (
        parking.priceExcludingVAT === value &&
        parking.status === updateParkingArray[parkingEditedIndex].status
      ) {
        updateParkingArray.splice(parkingEditedIndex, 1)
      } else {
        updateParkingArray[parkingEditedIndex].priceExcludingVAT = value
      }

      this.updatedParkings = updateParkingArray
    } else {
      this.updatedParkings = [
        ...this.updatedParkings,
        { ...parking, priceExcludingVAT: value },
      ]
    }
  }

  @action onParkingStatusEdition(parking: ParkingObject, status: string) {
    const parkingEditedIndex = this.updatedParkings.findIndex(
      oneParking => oneParking.id === parking.id,
    )
    if (parkingEditedIndex > -1) {
      const updateParkingArray = [...this.updatedParkings]
      if (
        parking.status === status &&
        parking.priceExcludingVAT ===
          updateParkingArray[parkingEditedIndex].priceExcludingVAT
      ) {
        updateParkingArray.splice(parkingEditedIndex, 1)
      } else {
        updateParkingArray[parkingEditedIndex].status = status
      }

      this.updatedParkings = updateParkingArray
    } else {
      this.updatedParkings = [
        ...this.updatedParkings,
        { ...parking, status: status },
      ]
    }
  }

  @action setCurrentBuilding(buildingId: string) {
    this.currentBuilding = this.program.getBuildingById(buildingId)
  }

  @action async importParkings(xls: string) {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    const response = { success: true, error: null }

    try {
      await await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${programId}/xls/parkings`,
        {
          body: xls,
        },
      )
    } catch (err) {
      if (err.response && err.response.status === 400) {
        response.success = false
        response.error = err.response.data
      }
    }

    if (response.success) {
      await this.fetchParkingsByProgramId(programId)
    }

    return response
  }

  @action async exportParkings() {
    const programId = this.programId
    if (!programId) {
      throw new Error(`No program selected`)
    }

    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/parkings.xls`,
      { responseType: 'blob' },
    )

    return response.data
  }

  @computed get haveNoParkings(): boolean {
    return this.program.totalNumberOfParkings === 0
  }

  @computed get currentBuildingOrdered(): Object {
    return {
      ...this.currentBuilding,
      floors: this.currentBuilding.floors
        .map(oneFloor => {
          return {
            ...oneFloor,
            parkings:
              oneFloor.parkings &&
              oneFloor.parkings.map(oneParking => {
                return {
                  ...oneParking,
                }
              }),
          }
        })
        .sort((a, b) => b.order - a.order),
    }
  }

  @computed get buildingsWithoutFloors(): Object {
    return this.program.buildings.map(oneBuilding => {
      return {
        id: oneBuilding.id,
        label: oneBuilding.label,
      }
    })
  }

  @computed get someParkingHaveChanged(): boolean {
    return this.updatedParkings.length > 0
  }
}
